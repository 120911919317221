import { ApiError } from "../model/Errors";

export const handleResponse = (response: Response) => {
    return response.text()
        .then((text) => {
            let data;
            try {
                data = JSON.parse(text);
            } catch {
                if (response.status !== 200
                    && response.status !== 201
                    && response.status !== 204) {
                    throw new ApiError(response.statusText, response.status);
                }
            }
            if (data && data.error) {
                throw data.error;
            }
            return data;
        });
}