import React from 'react';
import ReactDOM from 'react-dom';
import './google-icons.css';
import './google-fonts.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {StylesProvider} from "@mui/styles";
import UscoTheme from "./theme/UscoTheme";
import {AuthServiceProvider} from "./provider/AuthServiceProvider";
import {ThemeProvider} from "@mui/material/styles";

ReactDOM.render(
    <StylesProvider injectFirst>
      <ThemeProvider theme={UscoTheme}>
        <AuthServiceProvider>
          <App/>
        </AuthServiceProvider>
      </ThemeProvider>
    </StylesProvider>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
