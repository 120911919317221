import React, {PropsWithChildren, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {TextField} from "@mui/material";
import DialogButton from "../DialogButton";

const useStyles = makeStyles(() => createStyles({
    addSectionContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        boxSizing: "border-box",
        padding: "5px 28px",
    },
    addSectionButtonContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        boxSizing: "border-box",
        border: "1px dashed lightgrey",
    }
}));

interface AddSectionProps extends PropsWithChildren<any> {
    onAddSection(sectionName: string): void,
}

export default function AddSection(props: AddSectionProps) {
    const {onAddSection} = props;
    const [sectionName, setSectionName] = useState<string>('');

    const classes = useStyles();

    const handleChange = (event: any) => {
        setSectionName(event.target.value);
    };

    const handleClickSave = () => {
        onAddSection(sectionName);
        setSectionName('');
    }

    return (
        <div className={`editableSiteAddSectionContainer ${classes.addSectionContainer}`}>
            <div className={classes.addSectionButtonContainer}>
                <DialogButton label="Neuer Bereich"
                              title="Bereich hinzufügen"
                              actionLabel="Hinzufügen"
                              onActionClick={handleClickSave}
                              details="Vergeben Sie einen Namen für den neuen Bereich."
                              style={{color: "rgba(0,0,0,0.6)", padding: "5px"}}>
                    <TextField required
                               autoFocus
                               id="section-name"
                               label="Bereichsname"
                               variant="standard"
                               value={sectionName}
                               onChange={handleChange}
                               fullWidth/>
                </DialogButton>
            </div>
        </div>
    );
}
