import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import IAssociationWorking from "../../../model/association_working/IAssociationWorking";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import {toLink} from "../../../model/association_working/ILocation";
import {AssociationWorkingServiceApi} from "../../../api/AssociationWorkingServiceApi";
import IParticipant from "../../../model/association_working/IParticipant";
import {Icon, Theme} from "@mui/material";
import MemberContact from "../../../components/contact/MemberContact";
import Contact from "../../../components/contact/Contact";
import DialogButton from "../../../components/DialogButton";
import ContentCheckbox from "../../../components/ContentCheckbox";
import {convert} from "../../../components/contact/CurrentUser";

const useStyles = makeStyles((theme: Theme) => createStyles({
    associationWorkingBackgroundContainer: {
        backgroundColor: "#ffffff",
        margin: "0 20px 20px 20px",
        minWidth: "calc(100% - 40px)",
        [theme.breakpoints.down(500)]: {
            margin: "0 0 20px",
            minWidth: "100%"
        }
    },
    associationWorkingContainer: {
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    associationWorkingContentContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    associationWorkingContent: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#363E4C",
        margin: "0 20px 15px",
        display: "flex",
        flexDirection: "column"
    },
    associationWorkingTitleContainer: {
        display: "flex",
        flexDirection: "row",
        lineHeight: "19px",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column"
        }
    },
    associationWorkingTitle: {
        fontSize: "20px",
        fontWeight: 500,
        color: "#02B8B7",
        padding: "15px 20px",
        flexGrow: 100,
        [theme.breakpoints.down(950)]: {
            padding: "15px 20px 0 20px"
        }
    },
    associationWorkingTitleInfo: {
        color: "#363E4C",
        textAlign: "right",
        fontSize: "14px",
        padding: "15px 20px",
        justifyItems: "center",
        display: "flex",
        flexDirection: "row",
        "&.empty": {
            padding: "15px 0"
        },
        [theme.breakpoints.down(950)]: {
            padding: "0 20px 15px 20px",
            textAlign: "left"
        }
    },
    associationWorkingTitleInfoIcon: {
        marginRight: "5px",
        height: "19px",
        width: "19px",
        color: "#ffffff",
        backgroundColor: "#02A1A0",
        borderRadius: "9px",
        "&.material-icons": {
            fontSize: "18px"
        }
    },
    associationWorkingTitleUnderline: {
        backgroundColor: "#363E4C",
        height: "1px",
        width: "calc(100% - 40px)",
        margin: "0 20px",
        position: "absolute",
        top: "49px",
        [theme.breakpoints.down(950)]: {
            top: "54px"
        }
    },
    associationWorkingSeparator: {
        margin: "0 20px 15px",
        height: "1px",
        backgroundColor: "rgba(54, 62, 76, 0.2)",
    },
    associationWorkingContactHeadline: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#363E4C",
        marginBottom: "10px"
    },
    associationWorkingContactContainer: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(560)]: {
            flexDirection: "column",
        }
    },
    associationWorkingContactListContainer: {
        marginBottom: "10px",
        marginLeft: "20px"
    },
    associationWorkingContactButtonContainer: {
        margin: "auto auto 20px 50px",
        [theme.breakpoints.down(950)]: {
            marginBottom: "34px",
        },
        [theme.breakpoints.down(560)]: {
            marginLeft: "20px",
            marginBottom: "20px",
        },
        [theme.breakpoints.down(415)]: {
            width: "calc(100% - 40px)"
        }
    },
    associationWorkingAddContactButton: {
        margin: "auto 20px 20px auto",
        [theme.breakpoints.down(850)]: {
            margin: "20px auto 20px 20px",
        },
        [theme.breakpoints.down(415)]: {
            width: "calc(100% - 40px)"
        }
    },
    detailGroup: {
        display: "flex",
        marginBottom: "10px",
        flexDirection: "column",
    },
    detailRow: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(850)]: {
            flexDirection: "column",
        },
    },
    detailFirstCell: {
        height: "24px",
        width: "160px",
        [theme.breakpoints.down(850)]: {
            width: "100%",
            textDecoration: "underline"
        },
        "&.empty": {
            [theme.breakpoints.down(850)]: {
                display: "none",
            }
        }
    },
    detailSecondCell: {
        height: "24px",
        marginLeft: "20px",
        display: "flex",
        [theme.breakpoints.down(850)]: {
            height: "unset",
            marginLeft: "0",
            marginBottom: "10px",
            "&.trailerCouplingFollows": {
                marginBottom: "0"
            }
        },
    }
}));

interface AssociationWorkingProps extends PropsWithChildren<any> {
    associationWorking: IAssociationWorking;

    onUpdate(updatedAssociationWorking: IAssociationWorking): void;
}

export default function AssociationWorking(props: AssociationWorkingProps) {
    const {associationWorking, onUpdate} = props;

    const [participants, setParticipants] = useState<IParticipant[]>([]);
    // model for current trailer coupling supported
    const [userSupportedTrailerCoupling, setUserSupportedTrailerCoupling] = useState<boolean>(false);

    const {user, getToken} = useContext(AuthServiceContext);

    const classes = useStyles();

    useEffect(() => {
        const participants: IParticipant[] = associationWorking.participants;
        const indexOfCurrentUser: number =
            participants.findIndex((participantIdentifier: IParticipant) => participantIdentifier.identifier === user.userId);
        if (indexOfCurrentUser !== -1) {
            // make shure current user is last item of array
            const currentUser: IParticipant = participants[indexOfCurrentUser];
            participants.splice(indexOfCurrentUser, 1);
            participants.push(currentUser);
        }
        setParticipants(participants);
    }, [associationWorking, user]);

    const handleUpdate = () => {
        onUpdate({
            ...associationWorking,
            participants: participants
        });
    }

    const handleAddContact = (): void => {
        const participantToAdd: IParticipant = {
            identifier: user.userId,
            trailerCouplingSupport: userSupportedTrailerCoupling
        }
        AssociationWorkingServiceApi
            .addMemberToAssociationWorking(associationWorking, participantToAdd, getToken())
            .then((participant: IParticipant) => {
                participants.push(participant);
                setParticipants(participants);
                setUserSupportedTrailerCoupling(false);
                handleUpdate();
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            });

    }

    const handleRemoveContact = (): void => {
        AssociationWorkingServiceApi
            .removeMemberToAssociationWorking(associationWorking, user.userId, getToken())
            .then(() => {
                const index =
                    participants.findIndex((participant: IParticipant) => participant.identifier === user.userId);
                participants.splice(index, 1);
                setParticipants(participants);
                setUserSupportedTrailerCoupling(false);
                handleUpdate();
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            });
    }

    const isCurrentUserParticipant = (): boolean => {
        return participants.filter((participant: IParticipant) => participant.identifier === user.userId).length > 0;
    }

    const askForTrailerCouplings = (): boolean => {
        return associationWorking.neededTrailerCouplings > 0;
    }

    const trailerCouplingRequestOpened = (): boolean => {
        return askForTrailerCouplings() && calculateAvailableTrailerCouplings() > 0
    }

    const trailerCouplingRequired = (): boolean => {
        return !(calculateAvailablePositions() > calculateAvailableTrailerCouplings());
    }

    const calculateAvailablePositions = (): number => {
        return associationWorking.neededPersons - participants.length;
    }

    const calculateAvailableTrailerCouplings = (): number => {
        const participantsWithTrailerCouplingSupport =
            participants.filter(participant => participant.trailerCouplingSupport);
        return associationWorking.neededTrailerCouplings - participantsWithTrailerCouplingSupport.length;
    }

    return (
        <div className={classes.associationWorkingBackgroundContainer}>
            <div className={classes.associationWorkingContainer}>
                <div className={classes.associationWorkingContentContainer}>
                    <div className={classes.associationWorkingTitleContainer}>
                        <div className={classes.associationWorkingTitle}>{associationWorking.title}</div>
                        <div
                            className={`${classes.associationWorkingTitleInfo}${!isCurrentUserParticipant() ? " empty" : ""}`}>
                            {isCurrentUserParticipant() && (<>
                                <Icon className={classes.associationWorkingTitleInfoIcon}>check</Icon>
                                <strong>Hier bist du gemeldet</strong>
                            </>)}
                        </div>
                        <div className={classes.associationWorkingTitleUnderline}/>
                    </div>
                    <div id={"association-working-content-" + associationWorking.number}
                         className={classes.associationWorkingContent}
                         style={{marginTop: "15px"}}
                         dangerouslySetInnerHTML={{__html: associationWorking.description}}/>
                    <div className={classes.associationWorkingSeparator}/>
                    <div className={classes.associationWorkingContent} style={{marginBottom: "0"}}>
                        <div className={classes.detailGroup}>
                            <div className={classes.detailRow}>
                                <div className={classes.detailFirstCell}>Datum:</div>
                                <div className={classes.detailSecondCell}>{associationWorking.meetingDate}</div>
                            </div>
                            <div className={classes.detailRow}>
                                <div className={classes.detailFirstCell}>Uhrzeit:</div>
                                <div className={classes.detailSecondCell}>{associationWorking.meetingTime}</div>
                            </div>
                        </div>
                        <div className={classes.detailGroup}>
                            <div className={classes.detailRow}>
                                <div className={classes.detailFirstCell}>Treffpunkt:</div>
                                <div className={classes.detailSecondCell}>
                                    {associationWorking.meetingPlace.name}
                                    {toLink(associationWorking.meetingPlace)}
                                </div>
                            </div>
                            <div className={classes.detailRow}>
                                <div className={classes.detailFirstCell}>Verfügbare Plätze:</div>
                                <div className={`${classes.detailSecondCell}${
                                    askForTrailerCouplings() ? " trailerCouplingFollows" : ""
                                }`}>
                                    <p style={{margin: "0"}}>
                                        <strong>{calculateAvailablePositions()}</strong>&nbsp;von&nbsp;
                                        {associationWorking.neededPersons}
                                        {askForTrailerCouplings() ? " (davon mit Anhängerkupplung "
                                            + associationWorking.neededTrailerCouplings + ")" : ""}
                                    </p>
                                </div>
                            </div>
                            {askForTrailerCouplings() && (
                                <div className={classes.detailRow}>
                                    <div className={`${classes.detailFirstCell} empty`}/>
                                    <div className={classes.detailSecondCell}>
                                        <p style={{margin: "0"}}>Gesucht werden noch&nbsp;
                                            <strong>{calculateAvailableTrailerCouplings()}</strong>
                                            &nbsp;Teilnehmer mit Anhängerkupplung</p>
                                    </div>
                                </div>)}
                        </div>
                        <div className={classes.detailRow}>
                            <div className={classes.detailFirstCell}>Ansprechpartner:</div>
                            <div className={classes.detailSecondCell} style={{height: "48px"}}>
                                <Contact style={{marginTop: "3px"}}
                                         contactIdentifier={associationWorking.personInCharge}
                                         currentUser={convert(user)}/>
                            </div>
                        </div>
                    </div>
                    <div className={classes.associationWorkingAddContactButton}>
                        {(!isCurrentUserParticipant() && calculateAvailablePositions() > 0) && (
                            <DialogButton onActionClick={handleAddContact}
                                          label="Zur Arbeit anmelden"
                                          title="Ja, an der Vereinsarbeit teilnehmen!"
                                          details="Wir nehmen an, du hast nicht versehentlich geklickt.
                                           Um auf Nummer sicher zu gehen, bestätige bitte deine Anmeldung."
                                          actionLabel="Bestätigen"
                                          onValidateAction={() => {
                                              return !trailerCouplingRequired() || userSupportedTrailerCoupling;
                                          }}>
                                {trailerCouplingRequestOpened() && (
                                    <>
                                        <p>Es werden noch Teilnehmer mit Anhängerkupplung gesucht.&nbsp;
                                            {trailerCouplingRequired()
                                                ? <strong>Du kannst nur teilnehmen wenn du unterstützen kannst!</strong>
                                                : "Kannst du unterstützen?"}
                                        </p>
                                        <ContentCheckbox checked={userSupportedTrailerCoupling}
                                                         label="Ich komme mit einer Anhängerkupplung."
                                                         onChange={(event) => {
                                                             setUserSupportedTrailerCoupling(event.target.checked);
                                                         }}/>
                                    </>
                                )}
                            </DialogButton>)}
                    </div>
                    <div className={classes.associationWorkingSeparator}/>
                    <div className={classes.associationWorkingContactContainer}>
                        <div className={classes.associationWorkingContactListContainer}>
                            <div className={classes.associationWorkingContactHeadline}>Teilnehmer:</div>
                            {participants.map((participant: IParticipant) => (
                                <MemberContact key={participant.identifier}
                                               style={{marginBottom: "10px"}}
                                               contactIdentifier={participant.identifier}
                                               currentUser={convert(user)}/>
                            ))}
                            {participants.length <= 0 && (
                                <div className={classes.associationWorkingContent} style={{marginLeft: "0"}}>
                                    Noch keine angemeldeten Teilnehmer
                                </div>
                            )}
                        </div>
                        <div className={classes.associationWorkingContactButtonContainer}>
                            {isCurrentUserParticipant() && (
                                <DialogButton onActionClick={handleRemoveContact}
                                              variant="secondary"
                                              label="Einsatz stornieren"
                                              title="Ja, von der Vereinsarbeit abmelden!"
                                              details="Wir nehmen an, du hast nicht versehentlich geklickt.
                                               Um auf Nummer sicher zu gehen, bestätige bitte deine Abmeldung."
                                              actionLabel="Bestätigen"/>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}
