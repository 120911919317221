import IMember, {Gender, Membership} from "../../model/member/IMember";
import IUser from "../../model/IUser";

export class CurrentUser {
    private readonly identifier: string;
    private readonly principleIdentifier: string | undefined;
    private readonly membership: Membership;
    private readonly otherSubordinated: string[];

    constructor(member?: IMember) {
        this.identifier = member ? member.identifier : "";
        this.principleIdentifier = member ? member.principleIdentifier : "";
        this.membership = member ? member.membership : Membership.NONE;
        this.otherSubordinated = [];
    }

    getIdentifier(): string {
        return this.identifier;
    }

    getPrincipleIdentifier(): string {
        return this.principleIdentifier ? this.principleIdentifier : "";
    }

    isSubordinated(): boolean {
        return this.principleIdentifier ? this.principleIdentifier.length > 0 : false;
    }

    isFamily(): boolean {
        return Membership.FAMILY === this.membership;
    }

    isFamilyMember(identifier: string): boolean {
        return identifier === this.principleIdentifier || this.otherSubordinated.includes(identifier);
    }

    addOtherSubordinated(subordinatedIdentifier: string): void {
        this.otherSubordinated.push(subordinatedIdentifier);
    }
}

export const convert = (user: IUser): CurrentUser => {
    return new CurrentUser({
        identifier: user.userId,
        username: "",
        membership: Membership.NONE,
        mailAddress: "",
        firstName: "",
        lastName: "",
        gender: Gender.NONE,
        dateOfBirth: "",
        contact: {
            address: {
                streetLine: "",
                city: "",
                zipCode: ""
            },
            mobileNumber: "",
            phoneNumber: ""
        },
        bankAccount: {
            accountHolder: "",
            bankName: "",
            iban: "",
            bic: ""
        }
    });
}
