import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import HomeOffering from "./HomeOffering";
import HomeHeadline from "./HomeHeadline";
import HomeAboutUs from "./HomeAboutUs";
import HomeLogin from "./HomeLogin";
import HomeActual from "./HomeActual";
import HomeSaw from "./HomeSaw";
import HomeWeather from "./HomeWeather";

const useStyles = makeStyles((theme) => createStyles({
    contentContainer: {
        display: "flex",
        flexDirection: "column"
    }
}));

export default function Homepage() {
    const classes = useStyles();

    return (
        <div className={classes.contentContainer}>
            <HomeHeadline/>
            <HomeAboutUs/>
            <HomeOffering/>
            <HomeWeather/>
            <HomeLogin/>
            <HomeSaw/>
            <HomeActual/>
        </div>
    );
}