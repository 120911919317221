import ISection from "../model/site/ISection";
import ISite from "../model/site/ISite";
import {handleResponse} from "./ServiceApi";
import IMessage, {MessagePriority} from "../model/news/IMessage";
import {ContactForm} from "../model/form/ContactForm";
import {CourseRegisterForm} from "../model/form/CourseRegisterForm";

const { REACT_APP_CONTENT_SERVICE_URL } = window._env_;

const SITES_API = `${REACT_APP_CONTENT_SERVICE_URL}/contentmanagement/api/sites`;
const SECTION_API = `${REACT_APP_CONTENT_SERVICE_URL}/contentmanagement/api/sections`;
const MESSAGES_API = `${REACT_APP_CONTENT_SERVICE_URL}/contentmanagement/api/messages`;
const NEWSLETTERS_API = `${REACT_APP_CONTENT_SERVICE_URL}/contentmanagement/api/newsletters`;
const FORMS_API = `${REACT_APP_CONTENT_SERVICE_URL}/contentmanagement/api/forms`;

async function buildRequestOptions(method:string , authHeader?:string) {
    return {
        method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader ? (" Bearer " + authHeader) : ""
        }
    };
}

export const ContentServiceApi = {
    createSite: async (site: ISite, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(SITES_API, {
            ...requestOptions,
            body: JSON.stringify(site)
        }).then(handleResponse);
    },
    getSite: async (type: string, token?: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${SITES_API}/${type}`, requestOptions).then(handleResponse);
    },
    createSection: async (siteType: string, section: ISection, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(`${SITES_API}/${siteType}/sections`, {
            ...requestOptions,
            body: JSON.stringify(section)
        }).then(handleResponse);
    },
    updateSection: async (section: ISection, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${SECTION_API}/${encodeURIComponent(section.type)}`, {
            ...requestOptions,
            body: JSON.stringify(section)
        }).then(handleResponse);
    },
    deleteSection: async (section: ISection, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${SECTION_API}/${encodeURIComponent(section.type)}`, requestOptions).then(handleResponse);
    }
}

export const MessageServiceApi = {
    getActualMessages: async () => {
        const requestOptions = await buildRequestOptions("GET");
        return fetch(`${MESSAGES_API}?quantity=3`, requestOptions)
            .then(handleResponse);
    },
    getMessages: async () => {
        const requestOptions = await buildRequestOptions("GET");
        return fetch(`${MESSAGES_API}`, requestOptions)
            .then(handleResponse);
    },
    getActiveNewsletters: async (token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${NEWSLETTERS_API}`, requestOptions).then(handleResponse);
    }
}

export const FormServiceApi = {
    sendContactForm: async (contactForm: ContactForm) => {
        const requestOptions = await buildRequestOptions("POST")
        return fetch(`${FORMS_API}/contact`, {
            ...requestOptions,
            body: JSON.stringify(contactForm)
        }).then(handleResponse);
    },
    sendCourseRegisterForm: async (courseRegisterForm: CourseRegisterForm) => {
        const requestOptions = await buildRequestOptions("POST")
        return fetch(`${FORMS_API}/course-register`, {
            ...requestOptions,
            body: JSON.stringify(courseRegisterForm)
        }).then(handleResponse);
    }
}

export const MessageSort = (a: IMessage, b: IMessage) => {
    if (a.priority === MessagePriority.HERO && b.priority === MessagePriority.NONE) {
        return -1;
    } else if (a.priority === MessagePriority.NONE && b.priority === MessagePriority.HERO) {
        return 1;
    }
    return 0;
}

export default ContentServiceApi;
