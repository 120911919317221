import React, {PropsWithChildren, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {format, isBefore} from "date-fns";
import {ISlot} from "../../model/booking/ISlot";
import DialogButton from "../DialogButton";
import MemberContact from "../contact/MemberContact";
import {Theme} from "@mui/material";
import {CurrentUser} from "../contact/CurrentUser";

const useStyles = makeStyles((theme: Theme) => createStyles({
    timeSlotContainer: {
        display: "flex",
        flexDirection: "row",
        height: "64px",
        justifyItems: "center",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            height: "150px"
        }
    },
    timeSlot: {
        margin: "auto 0",
        width: "120px",
        fontSize: "16px",
        fontWeight: 500,
        [theme.breakpoints.down(950)]: {
            margin: "0"
        }
    },
    timeSlotDetail: {
        display: "flex",
        flexDirection: "row",
        justifyItems: "center",
        width: "calc(100% - 120px)",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            width: "100%",
            height: "100px",
            margin: "15px 0 auto 0",
            borderBottom: "1px solid #F6F7F9"
        }
    },
    timeSlotUserContainer: {
        margin: "auto 0 auto 20px",
        [theme.breakpoints.down(950)]: {
            marginTop: "0",
            marginLeft: "0"
        }
    },
    timeSlotBookingButton: {
        marginLeft: "20px",
        [theme.breakpoints.down(950)]: {
            margin: "0 auto auto 0"
        }
    },
    timeSlotCancelButton: {
        marginRight: "50px",
        [theme.breakpoints.down(950)]: {
            height: "42px",
            marginRight: "auto",
            marginLeft: "0"
        }
    }
}));

interface SlotProps extends PropsWithChildren<any> {
    start: Date,
    end: Date,
    bookingMember?: string,
    allowedToBook: boolean,
    currentUser: CurrentUser,

    onBooking(start: Date, end: Date): Promise<ISlot>,

    onCancel(start: Date, end: Date): Promise<void>,
}

export default function Slot(props: SlotProps) {

    const [start, setStart] = useState<Date>();
    const [end, setEnd] = useState<Date>();
    const [bookingMemberIdentifier, setBookingMemberIdentifier] = useState<string | undefined>();
    const [allowedToBook, setAllowedToBook] = useState<boolean>(true);
    const [currentUser, setCurrentUser] = useState<CurrentUser>(new CurrentUser());

    const classes = useStyles();

    useEffect(() => {
        setStart(props.start);
        setEnd(props.end);
        setBookingMemberIdentifier(props.bookingMember);
        setAllowedToBook(props.allowedToBook);
        setCurrentUser(props.currentUser);
    }, [props]);

    const handleIsCurrentUser = (): boolean => {
        const contactIdentifier = bookingMemberIdentifier ? bookingMemberIdentifier : "";
        return currentUser.getIdentifier() === contactIdentifier;
    }

    const handleSlotInPast = (): boolean => {
        return end ? isBefore(end, new Date()) : false;
    }

    const handleBooking = (): void => {
        if (!!start && !!end) {
            props.onBooking(start, end)
                .then((slot: ISlot) => {
                    setStart(slot.start);
                    setEnd(slot.end);
                    setBookingMemberIdentifier(slot.bookingMember)
                })
                .catch((error) => {
                    console.error("unexpected error: " + error.message);
                });
        }
    }

    const handleCancel = (): void => {
        if (!!start && !!end) {
            props.onCancel(start, end)
                .then(() => {
                    setBookingMemberIdentifier(undefined)
                })
                .catch((error) => {
                    console.error("unexpected error: " + error.message);
                });
        }
    }

    const handleDateFormat = (date: Date | undefined): string => {
        return !!date ? format(date, 'HH:mm') : "";
    }

    const handleBookingDisabled = (): boolean => {
        return !allowedToBook || handleSlotInPast();
    }

    return (
        <div className={classes.timeSlotContainer}>
            <div className={classes.timeSlot}>{handleDateFormat(start)} - {handleDateFormat(end)}</div>
            {bookingMemberIdentifier && (<div className={classes.timeSlotDetail}>
                <MemberContact className={classes.timeSlotUserContainer}
                               contactIdentifier={bookingMemberIdentifier}
                               currentUser={currentUser}/>
                {(handleIsCurrentUser() && !handleSlotInPast()) && (
                    <DialogButton className={classes.timeSlotCancelButton}
                                  onActionClick={handleCancel}
                                  variant="secondary"
                                  label="Zeitslot stornieren"
                                  title="Zeitslot wirklich stornieren"
                                  details="Möchtest Du das ausgewählte Boot wirklich stornieren?"
                                  actionLabel="Stornieren"/>
                )}
            </div>)}
            {!bookingMemberIdentifier && (<div className={classes.timeSlotDetail}>
                <DialogButton className={classes.timeSlotBookingButton}
                              onActionClick={handleBooking}
                              disabled={handleBookingDisabled()}
                              variant="secondary"
                              label="Zeitslot buchen"
                              title="Zeitslot wirklich buchen"
                              details="Möchtest Du das ausgewählte Boot wirklich buchen?"
                              actionLabel="Buchen"/>
            </div>)}
        </div>
    );
}
