import React, {CSSProperties, PropsWithChildren} from "react";
import IBoat, {Location} from "../../../model/boat/IBoat";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import Icon from "@mui/material/Icon";
import ContentButton from "../../../components/ContentButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        width: "415px",
        height: "240px",
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(415)]: {
            width: "100%",
            height: "480px",
            flexDirection: "column"
        }
    },
    cardImage: {
        width: "177px",
        height: "240px",
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down(415)]: {
            width: "100%"
        }
    },
    cardPlaceholderImage: {
        width: "calc(177px - 5px)",
        height: "230px",
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
        backgroundImage: "url(../static/images/boat-placeholder.jpg)",
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderWidth: "5px",
        borderRight: "none",
        [theme.breakpoints.down(415)]: {
            width: "calc(100% - 5px)"
        }
    },
    cardContent: {
        width: "238px",
        height: "240px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffffff",
        [theme.breakpoints.down(415)]: {
            width: "100%",
        }
    },
    cardContentContainer: {
        margin: "auto 20px",
        color: "#023553"
    },
    cardContentHeadline: {
        color: "#02B8B7",
        fontSize: "16px",
        lineHeight: "19px",
        textTransform: "uppercase",
        marginBottom: "12px",
        display: "flex",
        justifyItems: "center"
    },
    cardContentOutOfOrder: {
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#02B8B7",
        borderRadius: "5px",
        margin: "auto auto auto 10px",
        padding: "2px",
        color: "#023553",
        backgroundColor: "#F6F7F9",
        fontSize: "10px",
        lineHeight: "13px",
        fontWeight: 400,
        textTransform: "lowercase"
    },
    cardContentText: {
        marginBottom: "12px"
    },
    cardContentIcon: {
        fontSize: "16px !important",
        lineHeight: "20px !important"
    },
    cardContentMoreInfoButton: {
        width: "170px",
        marginLeft: "0",
        marginTop: "10px",
        [theme.breakpoints.down(415)]: {
            width: "100%",
        }
    }
}));

interface BoatCardProps extends PropsWithChildren<any> {
    boat: IBoat;

    onClick(event: React.MouseEvent<HTMLButtonElement>): void;
}

export default function BoatsCard(props: BoatCardProps) {
    const {boat, onClick} = props;

    const classes = useStyles();
    const theme: Theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down(415));

    const handlePicture = (): CSSProperties => {
        let url = "../static/images/boat-placeholder.jpg";
        let border: CSSProperties = {
            width: `${matchesMobile ? "calc(100% - 10px)" : "calc(177px - 5px)"}`,
            height: "230px",
            borderStyle: "solid",
            borderWidth: "5px",
            borderColor: "#FFFFFF",
            borderRight: `${matchesMobile ? "5px solid #FFFFFF" : "none"}`
        };
        if (boat && boat.picture) {
            border = {};
            if (boat.picture.startsWith("data:image")) {
                url = boat.picture;
            } else {
                url = "../" + boat.picture;
            }
        }
        return {
            ...border,
            backgroundImage: `url(${url})`
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(event);
        }
    }

    const handleLocation = () => {
        switch (boat.location) {
            case Location.BORNHORST:
                return "Bornhorst";
            case Location.BUENTING:
                return "Bünting";
            case Location.EYHAUSEN:
                return "Eyhausen";
            case Location.OELTJEN:
                return "Oeltjen";
            default:
                return "";
        }
    }

    return (
        <div className={classes.card}>
            <div className={classes.cardImage} style={handlePicture()}/>
            <div className={classes.cardContent}>
                <div className={classes.cardContentContainer}>
                    <div className={classes.cardContentHeadline}>
                        {boat.name}
                        {(!!boat.outOfOrder && boat.outOfOrder.broken) && (
                            <span className={classes.cardContentOutOfOrder}>gesperrt</span>
                        )}
                    </div>
                    <div className={classes.cardContentText}>
                        <Icon className={classes.cardContentIcon}>sailing</Icon>Typ: {boat.type}</div>
                    <div className={classes.cardContentText} title="Maximale Personenanzahl">
                        <Icon className={classes.cardContentIcon}>person_pin_circle</Icon>Max. Anzahl: {boat.maxPerson}
                    </div>
                    <div className={classes.cardContentText}>
                        <Icon className={classes.cardContentIcon}>place</Icon>Liegeplatz: {handleLocation()}</div>
                    <ContentButton id={boat.number}
                                   className={classes.cardContentMoreInfoButton} onClick={handleClick}>
                        Mehr Erfahren<Icon style={{fontSize: "14px", marginLeft: "5px"}}>arrow_forward</Icon>
                    </ContentButton>
                </div>
            </div>
        </div>
    );
}