import React from "react";
import * as TYPES from "../../constants/siteTypes";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import EditableSite from "../../components/site/EditableSite";
import {BeforeSectionExtension, BehindSectionExtension} from "../../components/site/EditableSection";
import TeaserCarousel, {TeaserData} from "../../components/teaser/TeaserCarousel";
import {useNavigate} from "react-router";
import {createStyles, makeStyles} from "@mui/styles";
import * as ROUTES from "../../constants/routes";
import Typography from "@mui/material/Typography";
import ImageWithTextContainer from "../../components/ImageWithTextContainer";
import {Theme} from "@mui/material";
import SiteImage from "../../components/site/SiteImage";

const useStyles = makeStyles((theme: Theme) => createStyles({
    boatsAndTerritoriesContainer: {
        paddingTop: "20px"
    },
    boatsAndTerritoriesTeaserContainer: {
        margin: "auto",
        maxWidth: "1280px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "18px 43px 40px 43px",
        [theme.breakpoints.down(1280)]: {
            width: "100%",
            padding: "18px 0 5px 0"
        }
    },
    boatsAndTerritoriesTeaserHeadline: {
        margin: "20px auto 0",
        color: "#023553",
        textAlign: "center"
    }
}));

export default function BoatsAndTerritories() {

    const navigate = useNavigate();
    const classes = useStyles();

    const teaserData: TeaserData[] = [
        {
            image: "teaser/pexels-cottonbro-5851827.jpg",
            title: "Anheuern im Segelclub - so geht's",
            content: "Erfahrene Segler und Interessierte können als Clubmitglied aktiv Segeln und Boote nutzen oder ihr Handwerk erlernen – in bester Gesellschaft.",
            actionTitle: "Zur Mitgliedschaft",
            action: () => {
                navigate(ROUTES.MEMBERSHIP);
            }
        },
        {
            image: "teaser/home_teaser_adult.jpeg",
            title: "Die Lizenz, um in See zu stechen",
            content: "Unsere günstigen Kurse bereiten fundiert auf die Prüfungen zum Sportbootführerschein Binnen oder See vor.",
            actionTitle: "Zu den Kursen",
            action: () => {
                navigate(ROUTES.ADULT_SAILING_COURSES);
            }
        },
        {
            image: "teaser/home_teaser_radio.jpeg",
            title: "Ausbildung fürs SRC und UBI",
            content: "In entspanntem Rahmen und kleinen Gruppen gibt es für Mitglieder das erforderliche Know-how zum Erwerb der Sprechfunkzeugnisse.",
            actionTitle: "Gleich anmelden",
            action: () => {
                navigate(ROUTES.RADIO_COURSES);
            }
        },
    ];

    return (
        <>
            <PageHeader headerImage="header/boats-and-territories.jpg"
                        headerImagePosition={{positionY: "42%", mirrorX: true}}>
                Flotte und Reviere
            </PageHeader>
            <PageContent className={classes.boatsAndTerritoriesContainer}>
                <EditableSite siteType={TYPES.BOATS_AND_TERRITORIES}>
                    <BeforeSectionExtension position={1}>
                        <SiteImage image="site-icon/lighttower_with_light_blue.svg"/>
                    </BeforeSectionExtension>
                    <BehindSectionExtension position={2}>
                        <ImageWithTextContainer
                            style={{marginTop: "50px"}}
                            image="breaker/pexels-clem-onojeghuo-175709.jpg"
                            headline="All hands on deck, ..."
                            content="... denn das Gros anfallender Arbeiten an Flotte und Gelände erledigt unsere Vereinsmannschaft selbst. Und die Heuer für die Mühe reduziert den Mitgliedsbeitrag."
                            contentStyle={{marginTop: "-50px"}}
                        />
                    </BehindSectionExtension>
                </EditableSite>
            </PageContent>
            <div className={classes.boatsAndTerritoriesTeaserContainer}>
                <Typography className={classes.boatsAndTerritoriesTeaserHeadline}
                            variant={"h1"}
                            component={"h1"}>Unsere Kurse und Freizeitangebote</Typography>
                <TeaserCarousel data={teaserData}/>
            </div>
        </>
    );
}
