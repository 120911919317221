import React, {useState} from "react";
import {DropdownMenu, DropdownMenuItem} from "../../../components/menu/DropdownMenu";
import {DrawerMenu, DrawerMenuItem} from "../../../components/menu/DrawerMenu";
import {MenuTarget} from "./MenuTarget";
import * as ROUTES from "../../../constants/routes";
import '../HeaderMenu.css';

export const entries: MenuTarget[] = [
    { target: ROUTES.CHILD_SAILING_COURSES, title: "Kindersegeln" },
    { target: ROUTES.ADULT_SAILING_COURSES, title: "Segel-/Motorbootausbildung" },
    { target: ROUTES.RADIO_COURSES, title: "Funkscheine" },
    { target: ROUTES.LEISURE_PROGRAMS, title: "Ferien- und Freizeitangebote" },
    { target: ROUTES.SAILING_COURSE_REGISTER, title: "Kursanmeldung" }
];

export function TrainingMenu() {
    const [open, setOpen] = useState(false);

    return (
        <DropdownMenu title="Ausbildung" state={open} className={"menuLink"} onStateChange={setOpen}>
            {entries.map((entry, index) => (
                <DropdownMenuItem key={index}
                                  target={entry.target}
                                  title={entry.title}
                                  className="subMenuLink"
                                  onStateChange={setOpen}/>
            ))}
        </DropdownMenu>
    );
}

export function TrainingDrawerMenu() {

    return (
        <DrawerMenu title="Ausbildung" divider={true}>
            {entries.map((entry, index) => (
                <DrawerMenuItem key={index}
                                className="subMenuLink"
                                target={entry.target}
                                title={entry.title} />
            ))}
        </DrawerMenu>
    );
}
