import React, {CSSProperties, PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {ImagePosition} from "./ImagePosition";
import {Theme} from "@mui/material";
import SiteImage from "./site/SiteImage";

const useStyles = makeStyles((theme: Theme) => createStyles({
    imageWithTextContainer: {
        margin: "auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down(850)]: {
            minHeight: "574px",
            margin: "0",
            backgroundColor: "#023553",
            flexDirection: "column",
        }
    },
    imageWithTextBackground: {
        margin: "0 auto",
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(850)]: {
            margin: "0"
        }
    },
    imageWithTextImage: {
        width: "521px",
        height: "355px",
        borderColor: "#023553",
        borderStyle: "solid",
        borderWidth: "10px",
        boxSizing: "border-box",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down(522)]: {
            width: "100%"
        }
    },
    imageWithTextContentContainer: {
        width: "419px",
        minHeight: "219px",
        margin: "auto 0 auto -90px",
        backgroundColor: "#023553",
        color: "#ffffff",
        display: "flex",
        flexDirection: "column",
        zIndex: 2,
        [theme.breakpoints.down(850)]: {
            marginLeft: "0",
            width: "521px"
        },
        [theme.breakpoints.down(522)]: {
            width: "100%",
        },
    },
    imageWithTextHeadlineContainer: {
        margin: "auto 32px auto 20px"
    },
    imageWithTextHeadline: {
        margin: "0 auto 14px auto",
        fontSize: "34px",
        fontWeight: 500,
        lineHeight: "40px",
        [theme.breakpoints.down(500)]: {
            fontSize: "24px",
            lineHeight: "30px"
        },
    },
    imageWithTextContent: {
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "40px",
        [theme.breakpoints.down(500)]: {

        }
    }
}));

interface ImageWithTextContainerProps extends PropsWithChildren<any> {
    image: string;
    imagePosition?: ImagePosition;
    contentStyle?: CSSProperties;
    headline: string;
    content: string;
    style?: CSSProperties;
    className?: string;
}

export default function ImageWithTextContainer(props: ImageWithTextContainerProps) {

    const { image, imagePosition, headline, content, contentStyle, style, className } = props;

    const classes = useStyles();

    const imageStyle = {
        backgroundImage: `url(/static/images/${image})`,
        backgroundSize: "cover",
        backgroundPositionX: `${imagePosition ? imagePosition.positionX : "50%"}`,
        backgroundPositionY: `${imagePosition ? imagePosition.positionY : "50%"}`,
        transform: `${imagePosition ? "scale(" + (imagePosition.mirrorX ? "-1" : "1") + ","
            + (imagePosition.mirrorY ? "-1" : "1") + ")" : null}`,
    }

    return (
        <div style={style} className={`${className ? className + " " : ""}${classes.imageWithTextContainer}`}>
            <div className={classes.imageWithTextBackground}>
                <div style={imageStyle} className={classes.imageWithTextImage}/>
            </div>
            <div style={contentStyle} className={classes.imageWithTextContentContainer}>
                <div className={classes.imageWithTextHeadlineContainer}>
                    <SiteImage image="site-icon/wave_white.svg"
                               width="100px"
                               height="10px"
                               style={{ margin: "40px auto 14px 0" }}/>
                    <div className={classes.imageWithTextHeadline}>
                        {headline}
                    </div>
                    <div className={classes.imageWithTextContent}>
                        {content}
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}
