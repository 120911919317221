import React, {CSSProperties, PropsWithChildren} from "react";
import {useNavigate} from "react-router";
import {createStyles, makeStyles} from "@mui/styles";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";


const useStyles = makeStyles(() => createStyles({
    divider: {
        backgroundColor: "darkgrey"
    }
}));

interface DrawerMenuProps extends PropsWithChildren<any> {
    title?: string;
    divider?: boolean;
}

export function DrawerMenu(props: DrawerMenuProps) {
    const {title, divider, children} = props;
    const classes = useStyles();

    return (
        <>
            <List>
                {title && (<ListSubheader>{title}</ListSubheader>)}
                {children}
            </List>
            {divider && (<Divider className={classes.divider} title={"test"} />)}
        </>
    );
}

interface DrawerMenuItemProps extends PropsWithChildren<any> {
    target: string | any;
    title: string;
    className?: string;
    itemStyle?: CSSProperties;
}

export function DrawerMenuItem(props: DrawerMenuItemProps) {
    const {target, title, className, itemStyle} = props;
    const navigate = useNavigate();

    const handleClick = (target: string | any) => {
        if (typeof target === 'function') {
            target();
        } else if (typeof target === 'string') {
            navigate(target);
        }
    }

    return (
        <ListItem style={itemStyle} button onClick={() => handleClick(target)}>
            <div className={className}>{title}</div>
        </ListItem>
    );

}
