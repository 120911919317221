import React, {PropsWithChildren} from "react";
import {makeStyles} from "@mui/styles";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles(() => ({
    textField: {
        marginBottom: "20px",
        borderRadius: "4px",
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "#808080",
        },
        "& input": {
            color: "#000000",
        },
        "& input:disabled": {
            color: "#000000",
            "-webkit-text-fill-color": "#000000"
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#808080",
            opacity: "0.8"
        }
    }
}));

interface TextProps extends PropsWithChildren<any> {
    label: string;
    value?: string;
    className?: string
}

export default function Text(props: TextProps) {
    const {label, value, className} = props;

    const classes = useStyles();

    return (
        <TextField className={`${classes.textField}${className ? " " + className : ""}`}
                   id={label}
                   variant="outlined"
                   label={label}
                   value={value ? value : " "}
                   disabled
        />
    );

}