import React, {ChangeEvent, CSSProperties, PropsWithChildren, useEffect, useState} from "react";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import IDay from "../../model/weather/IDay";
import ICurrent from "../../model/weather/ICurrent";
import WeatherServiceApi from "../../api/WeatherServiceApi";
import {ApiError} from "../../model/Errors";
import WeatherIcon from "./WeatherIcon";
import WeatherWind from "./WeatherWind";
import WeatherTemp from "./WeatherTemp";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Checkbox, Theme} from "@mui/material";
import IWeatherResponse from "../../model/weather/IWeatherResponse";

const useStyles = makeStyles((theme: Theme) => createStyles({
    weatherContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    weatherHeadline: {
        textTransform: "uppercase",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "14px"
    },
    weatherScaleSwitch: {
        fontSize: "10px",
        lineHeight: "14px",
        position: "absolute",
        top: "10px",
        right: "10px",
        "& .MuiCheckbox-root": {
            padding: "0",
            marginRight: "3px"
        },
        "& .MuiSvgIcon-root": {
            width: "14px",
            height: "14px"
        }
    },
    weatherContentContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column"
        }
    },
    weatherCurrent: {
        position: "relative",
        backgroundColor: "#023553",
        color: "#ffffff",
        minWidth: "380px",
        padding: "10px",
        marginRight: "20px",
        [theme.breakpoints.down(950)]: {
            minWidth: "unset",
            marginRight: "0"
        }
    },
    weatherCurrentContainer: {
        display: "flex",
        flexDirection: "row",
        margin: "auto",
        height: "calc(100% - 24px)",
        maxWidth: "380px"
    },
    weatherCurrentTemp: {
        fontSize: "30px",
        fontWeight: 400,
        lineHeight: "36px",
        margin: "auto"
    },
    weatherNextDays: {
        backgroundColor: "#023553",
        color: "#ffffff",
        minWidth: "420px",
        display: "flex",
        [theme.breakpoints.down(950)]: {
            minWidth: "unset"
        }
    },
    weatherNextDayContentContainer: {
        margin: "auto",
        display: "flex",
        flexDirection: "row",
    },
    weatherNextDay: {
        border: "1px solid #023553",
        padding: "10px",
        minWidth: "50px",
        minHeight: "50px"
    },
    weatherNextDayName: {
        textTransform: "uppercase",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "14px"
    }
}));

const convertToDay = (date: number) => {
    const day = convertToDate(date).getDay();
    switch (day) {
        case 0:
            return "Sonntag";
        case 1:
            return "Montag";
        case 2:
            return "Dienstag";
        case 3:
            return "Mittwoch";
        case 4:
            return "Donnerstag";
        case 5:
            return "Freitag";
        case 6:
            return "Samstag";
    }
}

const convertToDate = (unixDatetime: number) => {
    return new Date(unixDatetime * 1000);
}

interface WeatherProps extends PropsWithChildren<any> {
    location: string;
    title: string;
    className?: string;
    style?: CSSProperties;
}

export default function Weather(props: WeatherProps) {
    const {location, title, style, className} = props;
    const [currentDay, setCurrentDay] = useState<ICurrent>();
    const [days, setDays] = useState<IDay[]>([]);
    const [bft, setBft] = useState<boolean>(true);

    const classes = useStyles();
    const theme: Theme = useTheme();
    const smallWeather = useMediaQuery(theme.breakpoints.down(600));
    const smallerWeather = useMediaQuery(theme.breakpoints.down(500));
    const smallestWeather = useMediaQuery(theme.breakpoints.down(400));

    useEffect(() => {
        WeatherServiceApi.getWeather(location)
            .then((response: IWeatherResponse) => {
            const current = response.current;
            // only load the next 5 days
            const daily: IDay[] = response.daily.splice(1, 5);

            setCurrentDay(current);
            setDays(daily);
        }).catch((error: ApiError) => {
            if (error.status !== 404) {
                console.error("unexpected error: " + error.message);
            }
        });
    }, [location]);

    return (
        <div style={style} className={`${className ? className + " " : ""}${classes.weatherContainer}`}>
            <div className={classes.weatherContentContainer}>
                <div className={classes.weatherCurrent}>
                    <div className={classes.weatherHeadline}>{title}</div>
                    <div className={classes.weatherScaleSwitch}><Checkbox onChange={(event: ChangeEvent<HTMLInputElement>) => setBft(!event.target.checked)} />in m/s</div>
                    <div className={classes.weatherCurrentContainer}>
                        <WeatherIcon weather={currentDay?.weather[0]}
                                     size="120px"
                                     style={{marginTop: "-14px", marginLeft: "0"}}/>
                        <WeatherTemp temp={currentDay?.temp}/>
                        <WeatherWind bft={bft}
                                     windSpeed={currentDay?.wind_speed}
                                     windDeg={currentDay?.wind_deg}
                                     windGust={currentDay?.wind_gust}/>
                    </div>
                </div>
                <div className={classes.weatherNextDays}>
                    <div className={classes.weatherNextDayContentContainer}>
                        {days.map((day: IDay, index: number) => {
                            if ((smallWeather && index > 3)
                                || (smallerWeather && index > 2)
                                || (smallestWeather && index > 1)) {
                                return null;
                            }
                            return (
                                <div key={index} className={classes.weatherNextDay}>
                                    <div className={classes.weatherNextDayName}>{convertToDay(day.dt)}</div>
                                    <WeatherIcon weather={day.weather[0]}
                                                 size="80px"
                                                 descriptionVisible={false}
                                                 style={{marginTop: "-10px"}}/>
                                    <WeatherTemp min={day.temp.min}
                                                 max={day.temp.max}
                                                 style={{fontSize: "12px", marginBottom: "10px", marginTop: "-10px"}}/>
                                    <WeatherWind bft={bft}
                                                 windSpeed={day.wind_speed}
                                                 windDeg={day.wind_deg}
                                                 style={{textAlign: "center"}}/>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
