import React, {useEffect, useState} from "react";
import PageHeader from "../../../components/PageHeader";
import PageContent from "../../../components/PageContent";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {useParams} from "react-router";
import {RegisterServiceApi} from "../../../api/MemberServiceApi";

const useStyles = makeStyles(() => createStyles({
    registerVerifyMailContainer: {
        display: "flex",
        flexDirection: "column",
        width: "calc(100% - 40px)",
        maxWidth: "700px",
        padding: "20px",
        margin: "auto"
    }
}));

export default function RegisterVerifyMail() {
    const params: any = useParams();

    const [verified, setVerified] = useState<boolean>(false);

    const classes = useStyles();

    useEffect(() => {
        console.info(params);
        RegisterServiceApi.sendVerify(params.verificationLink).then(response => {
            console.info("verified:", response);
            setVerified(true);
        }).catch((error) => {
            console.error("unexpected error: " + error.message);
        });
    }, [params]);


    return (
        <>
            <PageHeader headerImage={"header/register.jpg"} headerImagePosition={{positionY: "38%"}}>
                {!verified ? "Registrierung wird abgeschlossen"  : "Geschafft - Registrierung abgeschlossen!"}
            </PageHeader>
            <PageContent>
                <div className={classes.registerVerifyMailContainer}>
                    {!verified && (<div>Überprüfung läuft...</div>)}
                    {verified && (<>
                        <p>Alles weitere erledigen wir:</p>
                        <ul>
                            <li>Wir ziehen zeitnah den Mitgliedsbeitrag für das laufende Jahr von deiner angegebenen Bankverbindung ein.</li>
                            <li>Sobald wir den Beitrag erhalten haben, bist du offiziell an Bord und deine Vereinsmitgliedschaft im USCO beginnt.</li>
                            <li>Wenn es soweit ist, informieren wir dich automatisch per E-Mail: Dann wirst du für den Mitgliederbereich auf unserer Website freigeschaltet und musst dir initial dein Passwort einrichten. Und schon bist du drin.</li>
                            <li>Wir benötigen von dir noch einen Nachweis über deine Segelfähigkeiten, bspw. den Sportbootführerschein Binnen. Bitte sende ihn uns als vollständige Kopie per E-Mail an vorstand@usco-online.de. Sobald uns der Nachweis vorliegt, kannst du online auch die Boote unseres Vereins segeln und online buchen.</li>
                        </ul>
                    </>)}
                </div>
            </PageContent>
        </>
    );
}
