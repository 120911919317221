export enum Location {
    NONE = "NONE",
    BORNHORST = "BORNHORST",
    EYHAUSEN = "EYHAUSEN",
    BUENTING = "BUENTING",
    OELTJEN = "OELTJEN"
}

export function locationToText(location: Location) {
    switch (location) {
        case Location.BORNHORST:
            return "Bornhorst";
        case Location.BUENTING:
            return "Bünting";
        case Location.EYHAUSEN:
            return "Eyhausen";
        case Location.OELTJEN:
            return "Oeltjen";
        case Location.NONE:
            return "Kein Liegeplatz"
        default:
            return "";
    }
}

export interface IBoatswain {
    identifier: string;
    position: number;
}

export interface ITechnicalData {
    length: string;
    width: string;
    draft: string;
    mastAltitude: string;
    weight: string;
}

export interface IOutOfOrder {
    broken: boolean;
    brokenMessage?: string;
}

export default interface IBoat {
    number: string;
    name: string;
    type: string;
    location: Location;
    picture?: string;
    maxPerson?: string;
    specifics?: string;
    technicalData?: ITechnicalData;
    outOfOrder?: IOutOfOrder;
    boatswains: IBoatswain[];
}
