import React from "react";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import EditableSite from "../../components/site/EditableSite";
import * as TYPES from "../../constants/siteTypes"
import {BeforeSectionExtension} from "../../components/site/EditableSection";
import SiteImage from "../../components/site/SiteImage";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    territoriesContainer: {
        paddingTop: "20px"
    }
}));

export default function Territories() {

    const classes = useStyles();

    return (
        <>
            <PageHeader headerImage="header/territories.jpg"
                        headerImagePosition={{positionY: "42%", mirrorX: true}}>
                Revier- und Vereinsregeln
            </PageHeader>
            <PageContent className={classes.territoriesContainer}>
                <EditableSite siteType={TYPES.TERRITORIES}>
                    <BeforeSectionExtension position={1}>
                        <SiteImage image="site-icon/receipt_long_blue.svg"/>
                    </BeforeSectionExtension>
                </EditableSite>
            </PageContent>
        </>
    );
}
