import React, {useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import * as ROUTES from "../../constants/routes";
import {useNavigate} from "react-router";
import NewsCarousel, {NewsData} from "../../components/news/NewsCarousel";
import IMessage, {MessagePriority} from "../../model/news/IMessage";
import {MessageServiceApi, MessageSort} from "../../api/ContentServiceApi";
import {Theme, Typography} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    homeActualContainer: {
        margin: "auto auto 40px",
        maxWidth: "1280px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "18px 43px 5px 43px",
        [theme.breakpoints.down(1280)]: {
            width: "100%",
            padding: "18px 0 5px 0"
        }
    },
    homeActualHeadline: {
        margin: "20px auto 0",
        color: "#023553",
        textAlign: "center"
    }
}));

export default function HomeActual() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [messages, setMessages] = useState<IMessage[]>([])

    useEffect(() => {
        MessageServiceApi.getActualMessages().then(response => {
            const messages = response.sort(MessageSort);
            setMessages(messages);
        }).catch((error) => {
            console.error("unexpected error: " + error.message);
        });
    }, []);

    const newsData: NewsData[] = messages.map(message => (
        {
            date: message.date,
            title: message.title,
            content: message.content,
            hero: message.priority === MessagePriority.HERO,
            actionTitle: "Mehr erfahren",
            action: () => {
                navigate(ROUTES.MESSAGES);
            }
        }
    ));

    return (
        <div className={classes.homeActualContainer}>
            <Typography className={classes.homeActualHeadline}
                        variant={"h1"}
                        component={"h1"}>Aktuelles</Typography>
            <NewsCarousel data={newsData}/>
        </div>
    );
}
