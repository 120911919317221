export const HOME = "home";
/* USCO e.V. menu */
export const MEMBERSHIP = "membership";
export const CONTACT_PERSONS = "contact-persons";
export const BOATS_AND_TERRITORIES = "boats-and-territories";
export const MESSAGES = "messages";
/* training menu */
export const CHILD_SAILING_COURSES = "child-sailing-courses";
export const ADULT_SAILING_COURSES = "adult-sailing-courses";
export const RADIO_COURSES = "radio-courses";
export const LEISURE_PROGRAMS = "leisure-programs";
/* sailing menu */
export const BOOKING = "booking";
export const BOATS = "boats";
export const TERRITORIES = "territories";
/* sailing club menu */
export const ASSOCIATION_WORKING = "association-working";
export const NEWSLETTER = "news";
export const MEMBERS = "members";
export const APPOINTMENTS = "appointments";
export const CLUB_RULES = "club-rules";
export const TRAILER_USAGES = "trailer-usages";
export const COMBINATION_LOCKS = "combination-locks";
export const INSURANCE_COVER = "insurance-cover";
/* login / user menu */
export const PROFILE = "profile";
/* footer */
export const SERVICE_LINKS = "/service-links";
export const LEGAL_NOTICE = "/legal-notice";
export const DATA_PROTECTION = "/data-protection";
export const CONTACTS = "/contacts";
export const BANK_DETAILS = "/bank-details";