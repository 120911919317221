import {createStyles, makeStyles} from "@mui/styles";
import React, {PropsWithChildren} from "react";
import ContentButton from "../ContentButton";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles(() => createStyles({
    newsContainer: {
        width: "330px",
        minWidth: "330px",
        minHeight: "290px",
        margin: "0 20px",
        backgroundColor: "#ffffff",
    },
    newsInnerContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "transparent",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
    },
    newsDateContainer: {
        height: "34px",
        width: "100%",
        display: "flex",
        flexDirection: "row"
    },
    newsDate: {
        width: "60px",
        height: "14px",
        padding: "10px",
        backgroundColor: "#254659",
        color: "#ffffff",
        fontSize: "12px",
        lineHeight: "14px"
    },
    newsContentContainer: {
        height: "calc(100% - 34px - 80px)",
    },
    newsContent: {
        height: "calc(100% - 80px)",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "center",
        color: "#363E4C",
        margin: "0 20px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": 4,
        "-webkit-box-orient": "vertical"
    },
    newsTitle: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "19px",
        textAlign: "center",
        color: "#363E4C",
        margin: "20px",
    }
}));


interface NewsProps extends PropsWithChildren<any> {
    date?: string;
    title: string;
    content: string;
    hero: boolean;
    buttonTitle: string;

    onClick(): void;
}

export default function News(props: NewsProps) {
    const {date, title, content, hero, buttonTitle, onClick} = props;

    const classes = useStyles();

    const handleOnClick = () => {
        onClick();
    }

    return (
        <div className={classes.newsContainer}
             style={date ? { minHeight: "290px"} : { minHeight: "256px"}}>
            <div className={classes.newsInnerContainer}>
                {date && (<div className={classes.newsDateContainer}>
                    <div className={classes.newsDate}
                         style={hero ? {backgroundColor: "rgb(244,216,75)", color: "#363E4C"} : {}}>{date}</div>
                </div>)}
                <div className={classes.newsContentContainer}>
                    <div className={classes.newsTitle}
                         style={hero ? {textTransform: "uppercase"} : {}}>{title}</div>
                    <div className={classes.newsContent} dangerouslySetInnerHTML={{__html: content}}/>

                </div>
                <ContentButton style={{width: "230px", margin: "20px auto"}}
                               onClick={handleOnClick}>{buttonTitle}<Icon
                    style={{fontSize: "14px", marginLeft: "5px"}}>arrow_forward</Icon></ContentButton>
            </div>
        </div>
    );
}