import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import ImageWithTextContainer from "../../components/ImageWithTextContainer";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    homeSawContainer: {
        margin: "40px auto 20px",
        [theme.breakpoints.down(850)]: {
            display: "flex",
            minHeight: "574px",
            margin: "0",
            backgroundColor: "#023553",
        }
    },
    homeSawBackground: {
        margin: "0 auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down(850)]: {
            flexDirection: "column",
        }
    },
    homeSawImage: {
        width: "521px",
        height: "355px",
        borderColor: "#023553",
        borderStyle: "solid",
        borderWidth: "10px",
        backgroundImage: "url(../static/images/home_saw_background.jpeg)",
        backgroundPositionY: "50%",
        boxSizing: "border-box",
        [theme.breakpoints.down(522)]: {
            width: "100%"
        }
    },
    homeSawContentContainer: {
        width: "419px",
        minHeight: "219px",
        margin: "auto 0 auto -90px",
        backgroundColor: "#023553",
        color: "#ffffff",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(850)]: {
            marginLeft: "0",
            width: "521px"
        },
        [theme.breakpoints.down(522)]: {
            width: "100%",
        },
    },
    homeSawHeadlineContainer: {
        margin: "40px 32px 40px 20px"
    },
    homeSawHeadlineWave: {
        margin: "auto auto 14px 0"
    },
    homeSawHeadline: {
        margin: "0 auto 14px auto",
        fontSize: "34px",
        fontWeight: 500,
        lineHeight: "40px",
        [theme.breakpoints.down(500)]: {
            fontSize: "24px",
            lineHeight: "30px"
        },
    },
    homeSawContent: {
        fontSize: "16px",
        lineHeight: "24px",
        [theme.breakpoints.down(500)]: {

        }
    }
}));

export default function HomeSaw() {

    const classes = useStyles();

    return (
        <ImageWithTextContainer className={classes.homeSawContainer}
                                image="breaker/home_saw_background.jpeg"
                                headline="Um zu Segeln ..."
                                content="... trommle nicht Menschen zusammen um ein Schiff zu bauen,
                                sondern lehre diesen die Sehnsucht nach dem Meer"/>
    );
}
