import {Salutation, salutationToText} from "./Salutation";

export enum Course {
    NONE = "NONE",
    COMBI = "COMBI",
    SEA = "SEA",
    INLAND = "INLAND",
    RADIO = "RADIO"
}

export function courseToText(course: Course) {
    switch (course) {
        case Course.COMBI:
            return "Sportbootführerschein See und Binnen"
        case Course.SEA:
            return "Sportbootführerschein See";
        case Course.INLAND:
            return "Sportbootführerschein Binnen";
        case Course.RADIO:
            return "Funkschein";
        case Course.NONE:
            return "Keine Auswahl getroffen";
        default:
            return "";
    }
}

export class CourseRegisterForm {
    private readonly _salutation: Salutation;
    private readonly _firstName: string;
    private readonly _lastName: string;
    private readonly _mailAddress: string;
    private readonly _course: Course;
    private _phoneNumber: string | undefined;
    private _mobileNumber: string | undefined;

    constructor(salutation: Salutation, firstName: string, lastName: string, mailAddress: string, course: Course) {
        this._salutation = salutation;
        this._firstName = firstName;
        this._lastName = lastName;
        this._mailAddress = mailAddress;
        this._course = course;
    }

    get salutation(): Salutation {
        return this._salutation;
    }

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get mailAddress(): string {
        return this._mailAddress;
    }

    get phoneNumber(): string | undefined {
        return this._phoneNumber;
    }

    get mobileNumber(): string | undefined {
        return this._mobileNumber;
    }

    get course(): Course {
        return this._course;
    }

    set phoneNumber(value: string | undefined) {
        this._phoneNumber = value;
    }

    set mobileNumber(value: string | undefined) {
        this._mobileNumber = value;
    }

    toJSON() {
        return {
            salutation: salutationToText(this.salutation),
            firstName: this.firstName,
            lastName: this.lastName,
            mailAddress: this.mailAddress,
            phoneNumber: this.phoneNumber,
            mobileNumber: this.mobileNumber,
            courseType: this.course,
            courseName: courseToText(this.course)
        }
    }
}
