import React from "react";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import EditableSite from "../../components/site/EditableSite";
import * as TYPES from "../../constants/siteTypes";
import {useNavigate} from "react-router";
import * as ROUTES from "../../constants/routes";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import TeaserCarousel, {TeaserData} from "../../components/teaser/TeaserCarousel";
import {Theme} from "@mui/material";
import SiteImage from "../../components/site/SiteImage";
import {BeforeSectionExtension, BehindSectionExtension} from "../../components/site/EditableSection";
import ImageWithTextContainer from "../../components/ImageWithTextContainer";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme: Theme) => createStyles({
    childSailingCourseContainer: {
        paddingTop: "20px"
    },
    childSailingTeaserContainer: {
        margin: "auto",
        maxWidth: "1280px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "18px 43px 40px 43px",
        [theme.breakpoints.down(1280)]: {
            width: "100%",
            padding: "18px 0 5px 0"
        }
    },
    childSailingCourseTeaserHeadline: {
        margin: "20px auto 0",
        color: "#023553",
        textAlign: "center"
    },
}));

export default function ChildSailingCourses() {

    const theme: Theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(850));

    const navigate = useNavigate();
    const classes = useStyles();

    const teaserData: TeaserData[] = [
        {
            image: "teaser/pexels-cottonbro-5851827.jpg",
            title: "Anheuern im Segelclub - so geht's",
            content: "Erfahrene Segler und Interessierte können als Clubmitglied aktiv Segeln und Boote nutzen oder ihr Handwerk erlernen – in bester Gesellschaft.",
            actionTitle: "Zur Mitgliedschaft",
            action: () => {
                navigate(ROUTES.MEMBERSHIP);
            }
        },
        {
            image: "teaser/default.png",
            title: "Ferien- und Freizeitangebote",
            content: "Erfahrene Segler und Interessierte können als Clubmitglied aktiv Segeln und Boote nutzen oder ihr Handwerk erlernen – in bester Gesellschaft.",
            actionTitle: "Zu den Angeboten",
            action: () => {
                navigate(ROUTES.LEISURE_PROGRAMS);
            }
        },
        {
            image: "teaser/home_teaser_radio.jpeg",
            title: "Ausbildung fürs SRC und UBI",
            content: "In entspanntem Rahmen und kleinen Gruppen gibt es für Mitglieder das erforderliche Know-how zum Erwerb der Sprechfunkzeugnisse.",
            actionTitle: "Gleich anmelden",
            action: () => {
                navigate(ROUTES.RADIO_COURSES);
            }
        }
    ];

    return (
        <>
            <PageHeader headerImage="header/child-sailing-courses.jpg"
                        headerImagePosition={{ positionY: "80%", mirrorX: true }}>
                Kindersegeln
            </PageHeader>
            <PageContent className={classes.childSailingCourseContainer}>
                <EditableSite siteType={TYPES.CHILD_SAILING_COURSES}>
                    <BeforeSectionExtension style={{ height: "48px" }} position={1}>
                        <SiteImage image="site-icon/tsunami_blue.svg"
                                   width="48px"
                                   height="48px"/>
                    </BeforeSectionExtension>
                    <BehindSectionExtension position={2}>
                        <ImageWithTextContainer
                            style={{marginTop: mobile ? "0" : "50px", flexDirection: mobile ? "column" : "row-reverse"}}
                            image="breaker/pexels-yaroslav-shuraev-8917660.jpg"
                            headline="Das Zeug zum Trainer"
                            content="Unsere Betreuer und Trainer unterstützen wir bei ihrer Ausbildung und Qualifizierung. Zum Beispiel zum Erwerb der Trainer-C-Lizenz."
                            contentStyle={{marginTop: mobile ? "0" : "-50px", marginLeft: "0", marginRight: "-90px"}}
                        />
                    </BehindSectionExtension>
                </EditableSite>
            </PageContent>
            <div className={classes.childSailingTeaserContainer}>
                <TeaserCarousel data={teaserData}/>
            </div>
        </>
    );
}
