import React, {CSSProperties, PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    weatherTempContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "auto",
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "12px"
    },
    weatherTemp: {
        fontSize: "30px",
        fontWeight: 400,
        lineHeight: "36px",
        margin: "auto"
    },
    weatherMinMaxContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    weatherMin: {
        marginRight: "5px"
    },
    weatherMax: {
        marginLeft: "5px"
    }
}));

interface WeatherTempProps extends PropsWithChildren<any> {
    temp?: number | undefined;
    min?: number;
    max?: number;
    style?: CSSProperties;
}

export default function WeatherTemp(props: WeatherTempProps) {
    const {temp, min, max, style} = props;

    const classes = useStyles();

    return (
        <div style={style} className={classes.weatherTempContainer}>
            { temp && (<div className={classes.weatherTemp}>{Math.round(temp)}°C</div>)}
            { (min || max) && (<div className={classes.weatherMinMaxContainer}>
                <div className={classes.weatherMin}>{min ? Math.round(min) + "°C" : ""}</div>
                <div style={{ fontSize: "10px" }}>{min && max ? "bis" : ""}</div>
                <div className={classes.weatherMax}>{max ? Math.round(max) + "°C" : ""}</div>
            </div>)}
        </div>
    );
}