import React, {PropsWithChildren} from "react";
import {Button, Icon} from "@mui/material";
import DialogButton from "../DialogButton";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    chapterHeaderContainer: {
        display: "flex",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        zIndex: 100
    },
    chapterHeaderButtonContainer: {
        display: "inline-flex",
        marginLeft: "auto",
        marginRight: "0"
    },
    chapterHeaderButton: {
        minWidth: "unset",
        padding: "0 5px",
        marginLeft: "3px",
        color: "rgb(107,133,165)",
        backgroundColor: "rgba(0,0,0,0.06)",
        zIndex: 1,
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.10)"
        }
    },
    modeLabel: {
        color: "darkgrey",
        padding: "5px 0 0 5px",
        fontSize: "0.1rem"
    },
    positionLabel: {
        backgroundColor: "darkgrey",
        color: "#ffffff",
        position: "absolute",
        top: "-1px",
        left: "-15px",
        width: "14px",
        height: "14px",
        display: "flex",
        justifyContent: "center",
        fontSize: "0.2rem",
        lineHeight: "14px"
    },
    typeLabelContainer: {
        position: "absolute",
        top: "-1px",
        left: "0",
        width: "100%",
        height: "14px",
        display: "flex",
        justifyContent: "center",
        fontSize: "0.2rem",
        lineHeight: "14px"
    },
    typeLabel: {
        backgroundColor: "darkgrey",
        color: "#ffffff",
        width: "150px",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        borderBottomLeftRadius: "15px",
        borderBottomRightRadius: "15px"
    },
    labelEditMode: {
        backgroundColor: "rgb(107,133,165)"
    },
    invisible: {
        display: "none"
    }
}));

interface EditableChapterHeaderProps extends PropsWithChildren<any> {
    position: string | number;
    label: string;
    hovered: boolean;
    editMode: boolean;

    onSaveChapter(): void;

    onRemoveChapter(): void;

    onToggleEditMode(): void;
}

export default function EditableChapterHeader(props: EditableChapterHeaderProps) {
    const {position, label, hovered, editMode, onSaveChapter, onRemoveChapter, onToggleEditMode} = props;

    const classes = useStyles();

    return (
        <div className={classes.chapterHeaderContainer}>
            <div className={`${classes.positionLabel}${editMode ? " " + classes.labelEditMode : ""}`}>{position}</div>
            <div className={classes.typeLabelContainer}>
                <div className={`${classes.typeLabel}${editMode ? " " + classes.labelEditMode : ""}`}>{label}</div>
            </div>
            {editMode && (
                <div className={classes.modeLabel}>Bearbeitungsmodus</div>
            )}
            <div className={`${classes.chapterHeaderButtonContainer}${!hovered ? " " + classes.invisible : ""}`}>
                {editMode ? (
                    <Button className={classes.chapterHeaderButton} onClick={onSaveChapter}>
                        <Icon>done</Icon>
                    </Button>
                ) : (
                    <DialogButton label={<Icon>delete_outline</Icon>}
                                  title="Kapitel wirklich löschen?"
                                  actionLabel="Löschen"
                                  onActionClick={onRemoveChapter}
                                  style={{ backgroundColor: "unset", borderRadius: "unset"}}
                                  buttonStyle={{ border: "none" }}
                                  buttonClassName={classes.chapterHeaderButton}/>
                )}
                <Button className={classes.chapterHeaderButton} onClick={onToggleEditMode}>
                    <Icon>{!editMode ? 'edit' : 'clear'}</Icon>
                </Button>
            </div>
        </div>
    );
}
