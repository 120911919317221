import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import ImageCrossFade from "../../components/ImageCrossFade";
import {Theme} from "@mui/material";
import SiteImage from "../../components/site/SiteImage";

const useStyles = makeStyles((theme: Theme) => createStyles({
    homeHeadlineContainer: {
        width: "100%",
        height: "400px",
        position: "relative",
        [theme.breakpoints.down(950)]: {
            height: "600px"
        }
    },
    homeHeadlineContentContainer: {
        maxWidth: "550px",
        minHeight: "200px",
        backgroundColor: "#023553",
        top: "100px",
        left: "80px",
        position: "absolute",
        color: "#ffffff",
        display: "flex",
        flexDirection: "column",
        padding: "0 25px",
        [theme.breakpoints.down(950)]: {
            position: "relative",
            top: "unset",
            left: "unset",
            maxWidth: "unset",
            padding: "0 42px"
        },
    },
    homeHeadline: {
        margin: "0 auto auto auto",
        fontSize: "34px",
        lineHeight: "40px",
        [theme.breakpoints.down(500)]: {
            fontSize: "24px",
            lineHeight: "30px"
        },
    }
}));

export default function HomeHeadline() {
    const classes = useStyles();

    return (
        <div className={classes.homeHeadlineContainer}>
            <ImageCrossFade/>
            <div className={classes.homeHeadlineContentContainer}>
                <div style={{margin: "auto"}}>
                    <SiteImage image="site-icon/wave_white.svg"
                               width="100px"
                               height="10px"
                               style={{ margin: "auto auto 14px 0" }}/>
                    <div className={classes.homeHeadline}>
                        Willkommen beim USCO &ndash;<br/>dem Universitätssegelclub Oldenburg e.V.
                    </div>
                </div>
            </div>
        </div>
    );
}
