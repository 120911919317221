import {createStyles, makeStyles} from "@mui/styles";
import React, {CSSProperties, PropsWithChildren} from "react";
import {FilterFormControl} from "./StyledComponents";
import {Icon, InputAdornment, FilledInput, InputLabel, Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    filterFilterInputLabel: {
        color: "#023553"
    },
    filterSearchInput: {
        width: "350px",
        backgroundColor: "#F6F7F9",
        borderRadius: "4px",
        [theme.breakpoints.down(950)]: {
            width: "100%",
        }
    },
    filterSearchButton: {
        backgroundColor: "#02B8B7",
        width: "50px",
        height: "56px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        display: "flex"
    },
    filterSearchButtonIcon: {
        color: "#ffffff",
        fontSize: "20px",
        margin: "auto"
    }
}));

interface FilterSearchInputProps extends PropsWithChildren<any> {
    id: string;
    label: string;
    defaultValue: unknown;
    placeholder?: string;
    style?: CSSProperties;
    className?: string;
    labelStyle?: CSSProperties;
    labelClassName?: string;

    onChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
}

export default function FilterSearchInput(props: FilterSearchInputProps) {
    const {id, label, defaultValue, placeholder, style, className, labelStyle, labelClassName, onChange} = props;

    const classes = useStyles();


    return (
        <FilterFormControl variant="filled"
                           style={style}
                           className={`${classes.filterSearchInput}${className ? ' ' + className : ''}`}>
            <InputLabel htmlFor={id}
                        style={labelStyle}
                        className={`${classes.filterFilterInputLabel}${labelClassName ? ' ' + labelClassName : ''}`}>
                {label}
            </InputLabel>
            <FilledInput id={id}
                         defaultValue={defaultValue}
                         placeholder={placeholder}
                         onChange={onChange}
                         endAdornment={
                             <InputAdornment position="end">
                                 <div className={classes.filterSearchButton}>
                                     <Icon className={classes.filterSearchButtonIcon}>search</Icon>
                                 </div>
                             </InputAdornment>
                         }
                         style={{paddingRight: "0"}}
            />
        </FilterFormControl>
    );

}
