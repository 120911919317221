import {Icon, Theme} from "@mui/material";
import React, {CSSProperties, PropsWithChildren, useContext, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {IContact} from "../../model/member/IContact";
import {ContactServiceApi} from "../../api/MemberServiceApi";
import {CurrentUser} from "./CurrentUser";

const useStyles = makeStyles((theme: Theme) => createStyles({
    contact: {
        margin: "auto 0",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(950)]: {
            marginTop: "0",
            height: "56px"
        },
        "&.currentUser": {
            [theme.breakpoints.down(950)]: {
                marginTop: "auto"
            }
        }
    },
    contactUserName: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
        "&.currentUser": {
            [theme.breakpoints.down(950)]: {
                marginTop: "10px",
                marginBottom: "auto",
            }
        }
    },
    contactDetailsContainer: {
        display: "flex",
        flexDirection: "row",

        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            height: "36px"
        }
    },
    contactDetails: {
        fontWeight: 400,
        fontFamily: "Rubik",
        fontSize: "14px",
        lineHeight: "18px",
        display: "flex",
        flexDirection: "row",
        "&.material-icons": {
            lineHeight: "18px",
            fontSize: "14px",
            height: "18px",
            width: "18px",
            fontWeight: 400
        },
        "&.last": {
            marginLeft: "10px",
            [theme.breakpoints.down(950)]: {
                marginLeft: "0"
            }
        }
    }
}));

interface ContactProps extends PropsWithChildren<any> {
    contactIdentifier: string,
    currentUser: CurrentUser,
    style?: CSSProperties,
}

export default function Contact(props: ContactProps) {

    const {contactIdentifier, currentUser, style} = props;

    const [contact, setContact] = useState<IContact>()

    const {getToken} = useContext(AuthServiceContext);

    const classes = useStyles();

    useEffect(() => {
        ContactServiceApi.getContact(contactIdentifier, getToken())
            .then((contact: IContact) => {
                setContact(contact);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            });
    }, [contactIdentifier, getToken]);

    const handleIsCurrentUser = (): boolean => {
        return currentUser.getIdentifier() === contactIdentifier;
    }

    return (
        <div className={`${classes.contact}${handleIsCurrentUser() ? " currentUser" : ""}`}
             style={style}>
            {contact && (
                <div className={`${classes.contactUserName}${handleIsCurrentUser() ? " currentUser" : ""}`}>
                    {contact.firstName + " " + contact.lastName}
                    {handleIsCurrentUser() ? " (Du selbst)"
                        : (currentUser.isFamilyMember(contactIdentifier) ? " (Mitglied deiner Familie)": "")}
                </div>
            )}
            {(!handleIsCurrentUser() && contact) && (
                <div className={classes.contactDetailsContainer}>
                    {(contact.mobileNumber || contact.phoneNumber) && (<div className={classes.contactDetails}>
                        <Icon className={classes.contactDetails}>phone</Icon>
                        &nbsp;{contact.mobileNumber ? contact.mobileNumber : contact.phoneNumber}
                    </div>)}
                    <div className={`${classes.contactDetails}${contact.mobileNumber || contact.phoneNumber ? 
                        " last" : ""}`}>
                        <Icon className={classes.contactDetails}>mail</Icon>
                        &nbsp;{contact.mailAddress}
                    </div>
                </div>
            )}
        </div>
    );
}
