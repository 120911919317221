import {Salutation, salutationToText} from "./Salutation";

export class ContactForm {
    private readonly _salutation: Salutation;
    private readonly _name: string;
    private readonly _mailAddress: string;
    private readonly _message: string;

    constructor(salutation: Salutation, name: string, mailAddress: string, message: string) {
        this._salutation = salutation;
        this._name = name;
        this._mailAddress = mailAddress;
        this._message = message;
    }

    get salutation(): Salutation {
        return this._salutation;
    }

    get name(): string {
        return this._name;
    }

    get mailAddress(): string {
        return this._mailAddress;
    }

    get message(): string {
        return this._message;
    }

    toJSON() {
        return {
            salutation: salutationToText(this.salutation),
            name: this.name,
            mailAddress: this.mailAddress,
            message: this.message
        }
    }

}
