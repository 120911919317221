import React, {ChangeEvent, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {BackgroundTextField} from "../../components/StyledComponents";
import ContentCheckbox from "../../components/ContentCheckbox";
import ContentLoadingButton from "../../components/ContentLoadingButton";
import BackgroundTextArea from "../../components/BackgroundTextArea";
import {Theme} from "@mui/material";
import ContentButton from "../../components/ContentButton";
import {useNavigate} from "react-router";
import * as ROUTES from "../../constants/routes";
import BackgroundDropdown from "../../components/BackgroundDropdown";
import MenuItem from "@mui/material/MenuItem";
import {FormServiceApi} from "../../api/ContentServiceApi";
import {ContactForm} from "../../model/form/ContactForm";
import {Salutation, salutationToText} from "../../model/form/Salutation";

const useStyles = makeStyles((theme: Theme) => createStyles({
    contactsContainer: {
        display: "flex",
        flexDirection: "column",
        width: "calc(100% - 40px)",
        maxWidth: "500px",
        padding: "0 20px 20px",
        margin: "0 auto auto"
    },
    contactsHeadline: {
        width: "100%",
        fontSize: "38px",
        fontWeight: 700,
        lineHeight: "52px",
        textAlign: "center",
        color: "#023553",
        margin: "40px auto",
        [theme.breakpoints.down(950)]: {
            fontSize: "30px",
            lineHeight: "40px"
        },
        [theme.breakpoints.down(500)]: {
            fontSize: "24px",
            lineHeight: "30px"
        }
    },
    contactsCheckbox: {
        marginLeft: "10px"
    },
    contactsButtonContainer: {
        display: "flex",
        flexDirection: "row",
        marginBottom: "20px"
    },
    contactsSendButton: {
        marginLeft: "auto",
        marginRight: "0"
    },
    contactsSuccessMessage: {
        margin: "0 auto 20px"
    },
    contactsBackButton: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    jumpLink: {
        textDecoration: "underline",
        cursor: "pointer",
        color: "blue"
    }
}));

export default function Contacts() {

    // validation
    const [validated, setValidated] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [send, setSend] = useState<boolean>(false);
    // contact section
    const [salutation, setSalutation] = useState<Salutation>(Salutation.NONE);
    const [name, setName] = useState<string>("");
    const [mailAddress, setMailAddress] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [dataProtection, setDataProtection] = useState<boolean>(false);

    const classes = useStyles();
    const navigate = useNavigate();

    const handleSendContactForm = () => {
        if (handleValidation()) {
            setPending(true);
            FormServiceApi.sendContactForm(createContactForm())
                .then(() => {
                    console.info("send");
                    setSend(true);
                })
                .catch((error) => console.error("unexpected error: " + error.message))
                .finally(() => setPending(false))
        }
    }

    const handleValidation = () => {
        setValidated(true);
        if (salutation === Salutation.NONE
            || !name || !mailAddress || !content || !dataProtection
        ) {
            console.info("contact form failed. Required fields not filled.");
            return false;
        } else {
            console.info("contact form valid.");
            return true;
        }
    }

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataProtection(event.target.checked);
    }

    const createContactForm = (): ContactForm => {
        return new ContactForm(salutation, name, mailAddress, content);
    }

    return (
        <>
            {!send && (<div className={classes.contactsContainer}>
                <div className={classes.contactsHeadline}>Kontakt aufnehmen</div>
                <BackgroundDropdown id="contacts-type-label"
                                    label="Anrede"
                                    style={{marginBottom: "17px"}}
                                    value={salutation}
                                    nonValue={Salutation.NONE}
                                    errorMessage="Anrede nicht ausgewählt."
                                    validated={false}
                                    required={true}
                                    onChange={(newValue: any) => {
                                        setSalutation(newValue);
                                    }}>
                    <MenuItem value={Salutation.MALE}>{salutationToText(Salutation.MALE)}</MenuItem>
                    <MenuItem value={Salutation.FEMALE}>{salutationToText(Salutation.FEMALE)}</MenuItem>
                    <MenuItem value={Salutation.DEVICE}>{salutationToText(Salutation.DEVICE)}</MenuItem>
                </BackgroundDropdown>
                <BackgroundTextField required
                                     error={validated && !name}
                                     helperText={validated && !name ? "Der Name fehlt." : null}
                                     variant="outlined"
                                     label="Name"
                                     placeholder="Dein Name"
                                     value={name}
                                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                         setName(event.target.value);
                                     }}/>
                <BackgroundTextField required
                                     error={validated && !mailAddress}
                                     helperText={validated && !mailAddress ? "Die E-Mail-Adresse fehlt." : null}
                                     variant="outlined"
                                     label="E-Mail-Adresse"
                                     placeholder="Deinen E-Mail-Adresse"
                                     type="email"
                                     value={mailAddress}
                                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                         setMailAddress(event.target.value);
                                     }}/>
                <BackgroundTextArea required
                                    error={validated && !content}
                                    helperText={validated && !content ? "Die Nachricht fehlt." : null}
                                    rows={5}
                                    variant="outlined"
                                    label="Nachricht"
                                    placeholder="Deine Nachricht"
                                    value={content}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setContent(event.target.value);
                                    }}/>
                <ContentCheckbox className={classes.contactsCheckbox}
                                 checked={dataProtection}
                                 multiline
                                 error={validated && !dataProtection}
                                 helperText={validated && !dataProtection ? "Die Erklärung zum Datenschutz sowie " +
                                     "die weiterführenden Datenschutzinformationen wurden nicht akzeptiert." : null}
                                 label={<>Ja, ich habe die <a href={"data-protection"}
                                                              target="_blank"
                                                              rel="noreferrer">
                                     Erklärung zum Datenschutz</a>&nbsp;sowie die weiterführenden&nbsp;
                                     <a href={"static/dsgvo/Datenschutz-Merkblatt_USCO_F.pdf"}
                                        target="_blank" rel="noreferrer">
                                         Datenschutzinformationen (PDF)</a> des Universitätssegelclub Oldenburg e.V.
                                     (USCO) gemäß DSGVO zur Kenntnis genommen.</>}
                                 onChange={handleCheck}/>
                <div className={classes.contactsButtonContainer}>
                    <ContentLoadingButton className={classes.contactsSendButton}
                                          pending={pending}
                                          onClick={handleSendContactForm}>Absenden</ContentLoadingButton>
                </div>
            </div>)}
            {send && (
                <div className={classes.contactsContainer}>
                    <div className={classes.contactsHeadline}>Kontakt aufnehmen</div>
                    <div className={classes.contactsSuccessMessage}>
                        <p>Ihre Anfrage hat uns erreicht, vielen Dank dafür.</p>
                        <p>Wir melden uns in naher Zukunft.</p>
                    </div>
                    <ContentButton className={classes.contactsBackButton}
                                   onClick={() => {navigate(ROUTES.HOME)}}>Zur Startseite</ContentButton>
                </div>
            )}
        </>
    );
}
