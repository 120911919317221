export const validateIban = (iban: string): boolean => {
    if (!iban || !iban.startsWith("DE") || iban.length !== 22) {
        console.log("IBAN invalid", iban);
        return false;
    }
    const ibanChecksum = Number.parseInt(iban.slice(2, 4));
    const ibanBlocks = iban.slice(4) + "131400"; // D(13)E(14) as number + 00
    const blockOne = Number.parseInt(ibanBlocks.slice(0, 9)); // length = 9
    const blockOneResult = blockOne - ((blockOne/97|0)*97);
    const blockTwo = Number.parseInt(blockOneResult + ibanBlocks.slice(9, 16)); // length = 7
    const blockTwoResult = blockTwo - ((blockTwo/97|0)*97);
    const blockThree = Number.parseInt(blockTwoResult + ibanBlocks.slice(16, 23)) // length = 7
    const blockThreeResult = blockThree - ((blockThree/97|0)*97);
    const blockFour = Number.parseInt(blockThreeResult + ibanBlocks.slice(23));
    const blockFourResult = blockFour - ((blockFour/97|0)*97);
    const checksum = 98 - blockFourResult;

    return ibanChecksum === checksum;
}

export const validateBic = (bic: string): boolean => {
    if (!!bic) {
        if (bic.length !== 11 || bic.slice(4, 6) !== "DE") {
            console.log("BIC invalid", bic);
            return false;
        }
    }
    return true;
}
