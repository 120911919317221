import React, {ChangeEvent, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import {BackgroundTextField} from "../../components/StyledComponents";
import MenuItem from "@mui/material/MenuItem";
import BackgroundDropdown from "../../components/BackgroundDropdown";
import ContentCheckbox from "../../components/ContentCheckbox";
import ContentLoadingButton from "../../components/ContentLoadingButton";
import ContentButton from "../../components/ContentButton";
import {useNavigate} from "react-router";
import * as ROUTES from "../../constants/routes";
import {Course, CourseRegisterForm, courseToText} from "../../model/form/CourseRegisterForm";
import {Salutation, salutationToText} from "../../model/form/Salutation";
import {FormServiceApi} from "../../api/ContentServiceApi";
import ListSubheader from "@mui/material/ListSubheader";

const useStyles = makeStyles(() => createStyles({
    courseRegisterContainer: {
        display: "flex",
        flexDirection: "column",
        width: "calc(100% - 40px)",
        maxWidth: "500px",
        padding: "20px",
        margin: "0 auto auto"
    },
    registerSection: {
        marginTop: "40px",
        marginBottom: "20px",
        color: "#023553"
    },
    courseRegisterCheckbox: {},
    courseRegisterButtonContainer: {
        display: "flex",
        flexDirection: "row",
        marginBottom: "20px"
    },
    courseRegisterSaveButton: {
        marginLeft: "auto",
        marginRight: "0"
    },
    courseRegisterSuccessMessage: {
        margin: "0 auto 20px"
    },
    courseRegisterBackButton: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    jumpLink: {
        textDecoration: "underline",
        cursor: "pointer",
        color: "blue"
    }
}));

export default function CourseRegister() {

    // validation
    const [validated, setValidated] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [send, setSend] = useState<boolean>(false);
    // contact section
    const [course, setCourse] = useState<Course>(Course.NONE);
    const [salutation, setSalutation] = useState<Salutation>(Salutation.NONE);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [mailAddress, setMailAddress] = useState<string>("");
    const [secondMailAddress, setSecondMailAddress] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
    const [mobileNumber, setMobileNumber] = useState<string | undefined>();
    const [dataProtection, setDataProtection] = useState<boolean>(false);

    const classes = useStyles();
    const navigate = useNavigate();

    const handleSendCourseRegisterForm = () => {
        if (handleValidation()) {
            setPending(true);
            FormServiceApi.sendCourseRegisterForm(createCourseRegisterForm())
                .then(() => {
                    console.info("send");
                    setSend(true);
                })
                .catch((error) => console.error("unexpected error: " + error.message))
                .finally(() => setPending(false))
        }
    }

    const handleValidation = () => {
        setValidated(true);
        if (salutation === Salutation.NONE || course === Course.NONE
            || !firstName || !lastName || !mailAddress || !dataProtection
        ) {
            console.info("contact form failed. Required fields not filled.");
            return false;
        } else {
            console.info("contact form valid.");
            return true;
        }
    }

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataProtection(event.target.checked);
    }

    const createCourseRegisterForm = () => {
        const courseRegisterForm: CourseRegisterForm =
            new CourseRegisterForm(salutation, firstName, lastName, mailAddress, course);
        if (phoneNumber) {
            courseRegisterForm.phoneNumber = phoneNumber;
        }
        if (mobileNumber) {
            courseRegisterForm.mobileNumber = mobileNumber;
        }
        return courseRegisterForm;
    }

    return (<>
        <PageHeader headerImage="header/adult-sailing-courses.jpg">
            Eine Ausbildung anfragen
        </PageHeader>
        <PageContent className={classes.courseRegisterContainer}>
            {!send && (<>
                <div className={classes.registerSection}>Für welche Ausbildung wollen Sie sich anmelden?</div>
                <BackgroundDropdown id="course-register-type-label"
                                    label="Art der Ausbildung"
                                    style={{marginBottom: "17px"}}
                                    value={course}
                                    nonValue={Course.NONE}
                                    errorMessage="Art der Ausbildung nicht ausgewählt."
                                    validated={validated && course === Course.NONE}
                                    required={true}
                                    onChange={(newValue: any) => {
                                        setCourse(newValue);
                                    }}>
                    <MenuItem value={Course.COMBI} >{courseToText(Course.COMBI)}</MenuItem>
                    <MenuItem value={Course.RADIO}>{courseToText(Course.RADIO)}</MenuItem>
                    <ListSubheader>In Sonderfällen auch möglich</ListSubheader>
                    <MenuItem value={Course.SEA}>{courseToText(Course.SEA)}</MenuItem>
                    <MenuItem value={Course.INLAND}>{courseToText(Course.INLAND)}</MenuItem>
                </BackgroundDropdown>
                {(Course.SEA === course || Course.INLAND === course) && (<div>Bla bla bla darum lieber Combi</div>)}
                <div className={classes.registerSection}>Füllen Sie die folgenden Kontaktdaten aus:</div>
                <BackgroundDropdown id="contacts-type-label"
                                    label="Anrede"
                                    style={{marginBottom: "17px"}}
                                    value={salutation}
                                    nonValue={Salutation.NONE}
                                    errorMessage="Anrede nicht ausgewählt."
                                    validated={validated && salutation === Salutation.NONE}
                                    required={true}
                                    onChange={(newValue: any) => {
                                        setSalutation(newValue);
                                    }}>
                    <MenuItem value={Salutation.MALE}>{salutationToText(Salutation.MALE)}</MenuItem>
                    <MenuItem value={Salutation.FEMALE}>{salutationToText(Salutation.FEMALE)}</MenuItem>
                    <MenuItem value={Salutation.DEVICE}>{salutationToText(Salutation.DEVICE)}</MenuItem>
                </BackgroundDropdown>
                <BackgroundTextField required
                                     error={validated && !firstName}
                                     helperText={validated && !firstName ? "Der Vorname fehlt." : null}
                                     variant="outlined"
                                     label="Vorname"
                                     placeholder="Deinen Vorname"
                                     value={firstName}
                                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                         setFirstName(event.target.value);
                                     }}/>
                <BackgroundTextField required
                                     error={validated && !lastName}
                                     helperText={validated && !lastName ? "Der Nachname fehlt." : null}
                                     variant="outlined"
                                     label="Nachname"
                                     placeholder="Deinen Nachname"
                                     value={lastName}
                                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                         setLastName(event.target.value);
                                     }}/>
                <BackgroundTextField required
                                     error={validated && !mailAddress}
                                     helperText={validated && !mailAddress
                                         ? "Die E-Mail-Adresse fehlt." : null}
                                     variant="outlined"
                                     label="E-Mail-Adresse"
                                     placeholder="Deinen E-Mail-Adresse"
                                     type="email"
                                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                         setMailAddress(event.target.value);
                                     }}/>
                <BackgroundTextField required
                                     error={validated && (!secondMailAddress || mailAddress !== secondMailAddress)}
                                     helperText={validated && !secondMailAddress ? "Die E-Mail-Adresse fehlt." :
                                         validated && mailAddress !== secondMailAddress
                                         ? "Die E-Mail-Adressen sind nicht gleich." : null}
                                     variant="outlined"
                                     label="E-Mail-Adresse wiederholen"
                                     placeholder="Nochmal Deinen E-Mail-Adresse"
                                     type="email"
                                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                         setSecondMailAddress(event.target.value);
                                     }}/>
                <div className={classes.registerSection}>Teile uns optional auch deine telefonische Erreichbarkeit
                    mit:
                </div>
                <BackgroundTextField label="Mobilnummer"
                                     variant="outlined"
                                     placeholder="Deinen Mobilnummer"
                                     value={mobileNumber}
                                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                         setMobileNumber(event.target.value);
                                     }}/>
                <BackgroundTextField label="Festnetznummer"
                                     variant="outlined"
                                     placeholder="Deinen Festnetznummer"
                                     value={phoneNumber}
                                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                         setPhoneNumber(event.target.value);
                                     }}/>
                <ContentCheckbox className={classes.courseRegisterCheckbox}
                                 checked={dataProtection}
                                 multiline
                                 error={validated && !dataProtection}
                                 helperText={validated && !dataProtection ? "Die Erklärung zum Datenschutz sowie " +
                                     "die weiterführenden Datenschutzinformationen wurden nicht akzeptiert." : null}
                                 label={<>Ja, ich habe die <a href={"../data-protection"}
                                                              target="_blank"
                                                              rel="noreferrer">
                                     Erklärung zum Datenschutz</a>&nbsp;sowie die weiterführenden&nbsp;
                                     <a href={"static/dsgvo/Datenschutz-Merkblatt_USCO_F.pdf"}
                                        target="_blank" rel="noreferrer">
                                         Datenschutzinformationen (PDF)</a> des Universitätssegelclub Oldenburg e.V.
                                     (USCO) gemäß DSGVO zur Kenntnis genommen.</>}
                                 onChange={handleCheck}/>
                <div className={classes.courseRegisterButtonContainer}>
                    <ContentLoadingButton className={classes.courseRegisterSaveButton}
                                          pending={pending}
                                          onClick={handleSendCourseRegisterForm}>Anfrage absenden</ContentLoadingButton>
                </div>
            </>)}
            {send && (<>
                <div className={classes.courseRegisterSuccessMessage}>
                    <p>Ihre Anfrage hat uns erreicht und wurde automatisch an die verantwortlichen
                        Ansprechpartner weitergeleitet, vielen Dank dafür.</p>
                    <p>Wir melden uns in naher Zukunft.</p>
                </div>
                <ContentButton className={classes.courseRegisterBackButton}
                               onClick={() => {
                                   navigate(ROUTES.HOME)
                               }}>Zur Startseite</ContentButton>
            </>)}
        </PageContent>
    </>);
}
