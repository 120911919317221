import React, {CSSProperties, PropsWithChildren, ReactElement, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    boatTabGroupContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    boatTabGroupTabContainer: {
        width: "100%",
        height: "47px",
        display: "flex",
        flexDirection: "row"
    },
    boatTabGroupTab: {
        width: "50%",
        backgroundColor: "#E5E5E5",
        color: "#979797",
        display: "flex",
        flexDirection: "column",
        "&:hover": {
            cursor: "pointer"
        }
    },
    boatTabGroupTabActive: {
        backgroundColor: "#023553",
        color: "#FFFFFF"
    },
    boatTabGroupTabTitle: {
        margin: "auto auto auto 20px",
        textTransform: "uppercase",
        fontSize: "16px",
        lineHeight: "19px",
        [theme.breakpoints.down(850)]: {
            marginLeft: "auto"
        }
    },
    boatTabGroupContentContainer: {
        backgroundColor: "#FFFFFF",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "40px"
    },
    boatTabGroupContent: {
        display: "none"
    },
    boatTabGroupContentActive: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }
}));

export type BoatTab = {
    title: string;
    tabKey: "handbook" | "logbook";
    tabContent: ReactElement;
};

export interface BoatTabGroupProps extends PropsWithChildren<any> {
    tabs: BoatTab[];
    style?: CSSProperties;
}

export default function BoatTabGroup(props: BoatTabGroupProps) {
    const {tabs, style} = props;

    const [activeTab, setActiveTab] = useState<"handbook" | "logbook">("handbook");

    const classes = useStyles();

    return (
        <div className={classes.boatTabGroupContainer} style={style}>
            <div className={classes.boatTabGroupTabContainer}>
                {tabs.map(tab => (
                    <div key={tab.tabKey}
                         className={`${classes.boatTabGroupTab}${activeTab === tab.tabKey ? " " + classes.boatTabGroupTabActive : ""}`}
                         onClick={() => setActiveTab(tab.tabKey)}>
                        <div className={classes.boatTabGroupTabTitle}>{tab.title}</div>
                    </div>
                ))}
            </div>
            <div className={classes.boatTabGroupContentContainer}>
                {tabs.map(tab => (
                    <div key={tab.tabKey}
                         className={`${classes.boatTabGroupContent}${activeTab === tab.tabKey ? " " + classes.boatTabGroupContentActive : ""}`}>
                        {tab.tabContent}
                    </div>
                ))}
            </div>
        </div>
    );
}