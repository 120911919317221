import React, {PropsWithChildren} from "react";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import './Editor.css';
import SetOptions from "suneditor-react/dist/types/SetOptions";

interface EditorProps extends PropsWithChildren<any> {
    editMode: boolean;
    content: string;

    onContentChange(content: string): void;
}

function Editor(props: EditorProps) {
    const {editMode, content, onContentChange} = props;

    const editorOptions: SetOptions = {
        mode: "balloon-always",
        popupDisplay: "local",
        resizingBar: false,
        showPathLabel: false,
        height: "auto",
        font: [
            "Rubik"
        ],
        fontSize: [
            14, 16, 18, 20, 22, 24
        ],
        colorList: [
            "#000000", "#FFFFFF", "#023553"
        ],
        fontSizeUnit: "px",
        formats: [
            "p",
            "h1",
            "h2",
            "h3",
            "h4",
            "pre",
            "blockquote"
        ],
        imageFileInput: true,
        buttonList: [
            ["font", "fontSize", "formatBlock"],
            ["bold", "underline", "italic"],
            ["fontColor", "hiliteColor"],
            [":t-More Text-default.more_text", "strike", "subscript", "superscript", "removeFormat"],
            ["align"],
            ["table", "link", "image", "video"],
            ["showBlocks", "codeView"],
        ],
        customPlugins: []
    }

    return (
        <>
            {editMode && (<SunEditor lang="de"
                                     setOptions={editorOptions}
                                     setContents={content}
                                     onChange={onContentChange}/>)}
            {!editMode && (<div className="sun-editor-editable" dangerouslySetInnerHTML={{__html: content}}/>)}
        </>
    );
}


export default (Editor);
