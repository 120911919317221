import React, {useContext, useEffect, useState} from "react";
import Text from "../../components/Text";
import {BackgroundTextField} from "../../components/StyledComponents";
import {IMemberBankAccount} from "../../model/member/IMember";
import ContentButton from "../../components/ContentButton";
import ContentLoadingButton from "../../components/ContentLoadingButton";
import {MemberServiceApi} from "../../api/MemberServiceApi";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {ApiError} from "../../model/Errors";
import {ProfileSectionProps} from "./Profile";

export default function ProfileBankAccount(props: ProfileSectionProps) {
    const {onMemberChange, member, classes} = props;
    const {getToken} = useContext(AuthServiceContext);

    const [edit, setEdit] = useState<boolean>();
    const [validated, setValidated] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);

    const [memberBankAccountAccountHolder, setMemberBankAccountAccountHolder] = useState<string>("");
    const [memberBankAccountIban, setMemberBankAccountIban] = useState<string>("");
    const [memberBankAccountBic, setMemberBankAccountBic] = useState<string | undefined>(undefined);
    const [memberBankAccountBankName, setMemberBankAccountBankName] = useState<string>("");

    // banking account section
    const [accountHolder, setAccountHolder] = useState<string>(memberBankAccountAccountHolder);
    const [iban, setIban] = useState<string>(memberBankAccountIban);
    const [bic, setBic] = useState<string>(memberBankAccountIban);
    const [bankName, setBankName] = useState<string>(memberBankAccountBankName);

    useEffect(() => {
        if (!!member) {
            setMemberBankAccountAccountHolder(member.bankAccount.accountHolder);
            setAccountHolder(member.bankAccount.accountHolder);
            setMemberBankAccountIban(member.bankAccount.iban);
            setIban(member.bankAccount.iban);
            setMemberBankAccountBic(member.bankAccount.bic);
            setBic(member.bankAccount.bic);
            setMemberBankAccountBankName(member.bankAccount.bankName);
            setBankName(member.bankAccount.bankName);
        }
    }, [member]);

    const handleSave = () => {
        if (handleValidation()) {
            setValidated(false);
            if (handleUpdated() && !!member) {
                setPending(true);
                MemberServiceApi.updateBankAccount(member, createBankAccountToUpdate(), getToken()).then(response => {
                    onMemberChange(response);
                    setEdit(false);
                }).catch((error: ApiError) => {
                    if (error.status !== 404) {
                        console.error("unexpected error: " + error.message);
                    }
                }).finally(() => {
                    setPending(false);
                });
            } else {
                setEdit(false);
            }
        }
    }

    const createBankAccountToUpdate = (): IMemberBankAccount => {
        return {
            accountHolder: accountHolder,
            iban: iban,
            bic: bic,
            bankName: bankName
        }
    }

    const handleValidation = () => {
        setValidated(true);
        const valid = !!accountHolder && !!iban && !!bic && !!bankName
        if (valid) {
            console.info("bank account valid. Required fields are filled.");
        } else {
            console.info("bank account not valid. Required fields are not filled.", accountHolder, iban, bankName);
        }
        return valid;
    }

    const handleUpdated = () => {
        const updated = memberBankAccountAccountHolder !== accountHolder
            || memberBankAccountIban !== iban
            || memberBankAccountBic !== bic
            || memberBankAccountBankName !== bankName;
        if (updated) {
            console.info("bank account updated.");
        } else {
            console.info("bank account not updated.");
        }
        return updated;
    }

    return (
        <div className={classes.profileSection}>
            <div className={classes.profileSectionHeader}>Bankverbindung</div>
            {!edit && (<div className={classes.profileSectionContent}>
                <Text label="Kontoinhaber" value={memberBankAccountAccountHolder}/>
                <Text label="IBAN" value={memberBankAccountIban}/>
                <Text label="BIC" value={memberBankAccountBic}/>
                <Text label="Bankinstitut" value={memberBankAccountBankName}/>
            </div>)}
            {edit && (<div className={classes.profileSectionContent}>
                <BackgroundTextField required
                                     error={validated && !accountHolder}
                                     helperText={validated && !accountHolder ? "Der Kontoinhaber fehlt." : null}
                                     label="Kontoinhaber"
                                     variant="outlined"
                                     defaultValue={memberBankAccountAccountHolder}
                                     placeholder="Wer ist der Kontoinhaber"
                                     onChange={(event: any) => {
                                         setAccountHolder(event.target.value)
                                     }}/>
                <BackgroundTextField required
                                     error={validated && !iban}
                                     helperText={validated && !iban ? "Die IBAN fehlt." : null}
                                     label="IBAN"
                                     variant="outlined"
                                     defaultValue={memberBankAccountIban}
                                     placeholder="Deinen IBAN Nummer"
                                     onChange={(event: any) => {
                                         setIban(event.target.value)
                                     }}/>
                <BackgroundTextField required
                                     error={validated && !bic}
                                     helperText={validated && !bic ? "Die BIC fehlt." : null}
                                     label="BIC"
                                     variant="outlined"
                                     defaultValue={memberBankAccountBic}
                                     placeholder="Deinen BIC Nummer"
                                     onChange={(event: any) => {
                                         setBic(event.target.value)
                                     }}/>
                <BackgroundTextField required
                                     error={validated && !bankName}
                                     helperText={validated && !bankName ? "Das Bankinstitut fehlt." : null}
                                     label="Bank"
                                     variant="outlined"
                                     defaultValue={memberBankAccountBankName}
                                     placeholder="Dein Bankinstitut"
                                     onChange={(event: any) => {
                                         setBankName(event.target.value)
                                     }}/>
                {edit && (<div className={classes.profileSectionButtonContainer}>
                    <ContentButton variant="secondary"
                                   className={classes.profileSectionAbortButton}
                                   onClick={() => setEdit(false)}>Abbrechen</ContentButton>
                    <ContentLoadingButton className={classes.profileSectionSaveButton}
                                          pending={pending}
                                          onClick={handleSave}>Speichern</ContentLoadingButton>
                </div>)}
            </div>)}
            <div className={classes.profileSectionActions}>
                {/*{!edit && (<Button className={classes.profileSectionEditAction} onClick={() => setEdit(!edit)}>
                    <Icon style={{fontSize: "20px"}}>edit</Icon>
                </Button>)}*/}
            </div>
        </div>
    );

}
