import React, {PropsWithChildren, useState} from "react";
import Button from "@mui/material/Button";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    noLogbook: {
        textAlign: "center",
        fontSize: "16px",
        lineHeight: "19px",
        margin: "20px auto"
    },
    button: {
        margin: "0 auto 20px auto",
        minWidth: "unset",
        padding: "5px 10px"
    },
}));

interface BoatLogbookProps extends PropsWithChildren<any> {
    boatName: string;

    onAllowedToEdit(): boolean;
}

export default function BoatLogbook(props: BoatLogbookProps) {
    const {boatName, onAllowedToEdit} = props;

    const [logbook] = useState<undefined>(undefined);

    const classes = useStyles();

    /*useEffect(() => {
        if (boatName) {
            const handbookName = boatName.toLocaleLowerCase().replaceAll(" ", "_") + "_handbook"
            HandbookServiceApi.getHandbook(handbookName).then(response => {
                setHandbook(response);
            }).catch((error: ApiError) => {
                if (error.status === 404) {
                    setHandbook(undefined);
                    setDefaultEditMode(undefined);
                } else {
                    console.warn("unexpected error: " + error.message);
                }
            });
        }
    }, [boatName]);*/

    const handleCreateLogbook = () => {
        const logbookName = boatName.toLocaleLowerCase().replaceAll(" ", "_") + "_logbook"
        const introChapterName = logbookName + "_intro";
        console.debug("create logbook", introChapterName)
        /*HandbookServiceApi.createHandbook({
            chapters: [{
                type: introChapterName,
                position: 1,
                content: '<h1 style="text-align: center;">Handbuch ' + boatName + "</h1>"
            }],
            type: handbookName
        }, getToken()).then(response => {
            setLogbook(response);
            setDefaultEditMode(true);
        }).catch((error) => {
            console.error("unexpected error: " + error.message);
        });
        */
    }

    return (
        <>
            <div className={classes.noLogbook}>
                Kein Logbuch vorhanden
            </div>
            {(!logbook && onAllowedToEdit()) && (
                <Button className={classes.button}
                        variant={"outlined"}
                        onClick={handleCreateLogbook}>
                    Logbuch anlegen</Button>
            )}
        </>
    );
};
