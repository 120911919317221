import React, {ChangeEvent, PropsWithChildren} from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import ContentCheckbox from "../../../components/ContentCheckbox";

const useStyles = makeStyles(() => createStyles({
    registerInfoText: {
        fontSize: "14px",
        marginLeft: "30px",
        marginBottom: "20px"
    },
}));

export interface RegisterDirectDebitMandateProps extends PropsWithChildren<any> {
    accepted: boolean;

    onChangeAccepted(accepted: boolean): void;
}

export default function RegisterDirectDebitMandate(props: RegisterDirectDebitMandateProps) {
    const {accepted, onChangeAccepted} = props;
    const classes = useStyles();

    const handleClickAccepted = (event: ChangeEvent<HTMLInputElement>) => {
        onChangeAccepted(event.target.checked);
    }

    return (
        <>
            <div>
                <ContentCheckbox checked={accepted}
                                 label={<>Ja, ich erteile widerruflich ein SEPA-Lastschriftmandat.</>}
                                 onChange={handleClickAccepted} />
                <div className={classes.registerInfoText}>
                    Hiermit ermächtige ich den Universitätssegelclub Oldenburg e.V.,
                    Rauhehorst 197, 26127 Oldenburg (Gläubiger-Identifikationsnummer:
                    DE29ZZZ00000528000), fällige Beträge von meinem Konto wiederkehrend mittels
                    Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut
                    an, die von dem Universitätssegelclub Oldenburg e.V. auf mein Konto
                    gezogenen Lastschriften einzulösen. Den Nachweis des
                    SEPA-Lastschriftmandates gegenüber der Bank erbringt der
                    Universitätssegelclub Oldenburg e.V. Dieses Mandat gilt für die
                    bestehende Vereinsmitgliedschaft, die unter den oben angegebenen
                    persönlichen Mitgliedsdaten geführt wird.<br/><br/>
                    <strong>Hinweis:</strong> Innerhalb von acht Wochen, beginnend mit dem
                    Belastungsdatum, kann die Erstattung des Lastschriftbetrages
                    vom Kontoinhaber verlangt werden. Es gelten dabei die mit dem
                    Kreditinstitut vereinbarten Bedingungen.
                </div>
            </div>
        </>
    );
}
