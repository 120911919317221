import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {useNavigate} from "react-router";
import * as ROUTES from "../../constants/routes";
import {Link, Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    footerLinkContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "auto 84.5px auto auto",
        padding: "10px",
        [theme.breakpoints.down(900)]: {
            marginRight: "10px",
            transition: "margin 0.2s ease"
        },
        [theme.breakpoints.up(900)]: {
            transition: "margin 0.2s ease"
        },
        [theme.breakpoints.down(750)]: {
            flexDirection: "column",
            alignItems: "flex-start",
            marginRight: "auto",
            marginLeft: "10px"
        },
    },
    footerItem: {
        margin: "0 32px 0 0",
        [theme.breakpoints.down(750)]: {
            marginRight: "0",
            marginBottom: "10px"
        },
    },
    last: {
        marginRight: "0"
    },
    spacingRight: {
        marginRight: "10px"
    }
}));

export default function FooterLinks() {

    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <>
            <div className={classes.footerLinkContainer}>
                {/*<Link className={classes.footerItem}
                      component="button"
                      variant="body2"
                      onClick={() => {
                          navigate(ROUTES.SERVICE_LINKS);
                      }}>Service-Link</Link>*/}
                <Link className={classes.footerItem}
                      component="button"
                      variant="body2"
                      onClick={() => {
                          navigate(ROUTES.CONTACTS);
                      }}>Kontakt</Link>
                {/*<Link className={classes.footerItem}
                      component="button"
                      variant="body2"
                      onClick={() => {
                          navigate(ROUTES.BANK_DETAILS);
                      }}>Bankverbindung</Link>*/}
                <Link className={classes.footerItem}
                      component="button"
                      variant="body2"
                      onClick={() => {
                          navigate(ROUTES.LEGAL_NOTICE);
                      }}>Impressum</Link>
                <Link className={`${classes.footerItem} ${classes.last}`}
                      component="button"
                      variant="body2"
                      onClick={() => {
                          navigate(ROUTES.DATA_PROTECTION);
                      }}>Datenschutz</Link>
            </div>
        </>
    );
}
