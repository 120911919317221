import React, {CSSProperties, PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import IWeather from "../../model/weather/IWeather";

const useStyles = makeStyles(() => createStyles({
    weatherIconContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "auto",
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "12px",
        position: "relative"
    },
    weatherIconDescription: {
        position: "absolute",
        bottom: "5px",
        textAlign: "center",
        width: "100%"
    }
}));

interface WeatherIconProps extends PropsWithChildren<any> {
    weather: IWeather | undefined;
    size?: string;
    descriptionVisible?: boolean;
    style?: CSSProperties;
}

export default function WeatherIcon(props: WeatherIconProps) {
    const {weather, size, descriptionVisible = true, style} = props;
    const classes = useStyles();

    const iconStyle = {
        backgroundImage: `${weather ? `url(../static/images/weather/${weather.icon}@2x.png)` : "unset"}`,
        backgroundSize: "cover",
        width: `${size ? size : "100px"}`,
        height: `${size ? size : "100px"}`,
        margin: "auto"
    }

    return (
        <div style={style} className={classes.weatherIconContainer}>
            {weather && (<div style={iconStyle} />)}
            {(weather && descriptionVisible) && (
                <div className={classes.weatherIconDescription}>{weather.description}</div>
            )}
        </div>
    );
}
