import React, {useContext, useEffect, useState} from "react";
import {BoatServiceApi} from "../../../api/BoatServiceApi";
import IBoat from "../../../model/boat/IBoat";
import {useNavigate} from "react-router";
import * as ROUTES from "../../../constants/routes";
import {createStyles, makeStyles} from "@mui/styles";
import BoatsCard from "./BoatsCard";
import BoatsFilter, {BoatFilter} from "./BoatsFilter";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import {Theme} from "@mui/material";
import ContentContainer from "../../../components/ContentContainer";

const useStyles = makeStyles((theme: Theme) => createStyles({
    boatsContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "1280px",
        margin: "30px auto auto",
        [theme.breakpoints.down(500)]: {
            marginTop: "0"
        }
    },
    boatsHeadline: {
        width: "100%",
        fontSize: "38px",
        fontWeight: 700,
        lineHeight: "52px",
        textAlign: "center",
        color: "#023553",
        margin: "40px auto",
        [theme.breakpoints.down(950)]: {
            fontSize: "30px",
            lineHeight: "40px"
        },
        [theme.breakpoints.down(500)]: {
            fontSize: "24px",
            lineHeight: "30px"
        }
    },
    boatsViewContainer: {
        width: "calc(10px + 415px + 20px + 415px + 10px)",
        margin: "0 auto 20px",
        [theme.breakpoints.down(950)]: {
            width: "calc(415px)"
        },
        [theme.breakpoints.down(415)]: {
            margin: "0 0 20px 0",
            width: "100%"
        }
    },
    boatsViewRow: {
        display: "flex",
        flexDirection: "row",
        margin: "auto auto 20px",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column"
        },
        [theme.breakpoints.down(415)]: {
            width: "100%",
            margin: "0 0 20px"
        }
    },
    boatsLeftBoat: {
        margin: "auto 10px",
        [theme.breakpoints.down(950)]: {
            margin: "auto 0 20px"
        },
        [theme.breakpoints.down(415)]: {
            margin: "0 0 20px 0",
            width: "100%"
        }
    },
    boatsRightBoat: {
        margin: "auto 10px",
        [theme.breakpoints.down(950)]: {
            margin: "auto 0"
        },
        [theme.breakpoints.down(415)]: {
            margin: "0 0 20px 0",
            width: "100%"
        }
    },
    noBoats: {
        textAlign: "center",
        color: "#023553",
    }
}));

export default function Boats() {
    // loading
    const [loading, setLoading] = useState<boolean>(false);
    // model
    const [boats, setBoats] = useState<IBoat[]>([]);
    const [boatTypes, setBoatTypes] = useState<string[]>([]);
    const [filter, setFilter] = useState<BoatFilter>();

    const {getToken} = useContext(AuthServiceContext);

    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        BoatServiceApi.getBoats(getToken())
            .then(response => {
                const boats = response.sort((a: IBoat, b: IBoat) => {
                    return Number(a.number).valueOf() - Number(b.number).valueOf();
                });
                setBoats(boats);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getToken]);

    useEffect(() => {
        const boatTypes: string[] = [];
        boats.forEach((boat: IBoat) => {
            if (!boatTypes.includes(boat.type)) {
                boatTypes.push(boat.type);
                setBoatTypes(boatTypes);
            }
        });
    }, [boats]);

    const handleCardClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const boatNumber = event.currentTarget.id;
        navigate(ROUTES.BOATS + "/" + boatNumber);
    }

    const handleUpdateFilter = (filter: BoatFilter) => {
        setFilter(filter);
    }

    const handleFilterBoat = (boat: IBoat) => {
        return (filter?.boatType === undefined || boat.type === filter.boatType)
            && (filter?.boatLocation === undefined || boat.location === filter.boatLocation)
            && (filter?.boatName === undefined ||
                (boat.name.toLowerCase().includes(filter.boatName.toLowerCase())));
    }

    let firstRowBoat: IBoat | undefined = undefined;
    let secondRowBoat: IBoat | undefined = undefined;

    const filteredBoats = boats.filter(boat => handleFilterBoat(boat));

    return (
        <ContentContainer process={loading}>
            <div className={classes.boatsContainer}>
                <div className={classes.boatsHeadline}>Alles über unsere Boote</div>
                <BoatsFilter boatTypes={boatTypes} onUpdateFilter={handleUpdateFilter}/>
                <div className={classes.boatsViewContainer}>
                    {filteredBoats.map((filteredBoat: IBoat, index: number) => {
                        if ((index + 1) % 2 !== 0) {
                            firstRowBoat = filteredBoat;
                            // render if last row is odd
                            if ((index + 1) === filteredBoats.length) {
                                return (
                                    <div key={index} className={classes.boatsViewRow}>
                                        <div className={classes.boatsLeftBoat}>
                                            {firstRowBoat && (
                                                <BoatsCard boat={firstRowBoat} onClick={handleCardClick}/>)}
                                        </div>
                                        <div className={classes.boatsRightBoat} style={{width: "415px"}}>
                                        </div>
                                    </div>
                                );
                            }
                        } else {
                            secondRowBoat = filteredBoat;
                            // render if row is completed
                            return (
                                <div key={index} className={classes.boatsViewRow}>
                                    <div className={classes.boatsLeftBoat}>
                                        {firstRowBoat && (<BoatsCard boat={firstRowBoat} onClick={handleCardClick}/>)}
                                    </div>
                                    <div className={classes.boatsRightBoat}>
                                        {secondRowBoat && (<BoatsCard boat={secondRowBoat} onClick={handleCardClick}/>)}
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                    {filteredBoats.length === 0 && (
                        <div className={classes.noBoats}>
                            {`${loading
                                ? "Boote werden geladen"
                                : "Zu dieser Suchanfrage sind keine Boote vorhanden."}`}
                        </div>
                    )}
                </div>
            </div>
        </ContentContainer>
    );
};
