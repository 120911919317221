import React, {useContext, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {AssociationWorkingServiceApi} from "../../../api/AssociationWorkingServiceApi";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import IAssociationWorking from "../../../model/association_working/IAssociationWorking";
import PageHeader from "../../../components/PageHeader";
import PageContent from "../../../components/PageContent";
import AssociationWorking from "./AssociationWorking";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    associationWorkingContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "1280px",
        margin: "20px auto auto",
    },
    associationWorkingViewContainer: {},
    noAssociationWorkings: {
        textAlign: "center",
        color: "#023553",
        marginTop: "40px"
    }
}));

export default function AssociationWorkings() {
    // loading
    const [loading, setLoading] = useState<boolean>(false);
    // model
    const [associationWorkings, setAssociationWorkings] = useState<IAssociationWorking[]>([]);

    const {getToken} = useContext(AuthServiceContext);

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        AssociationWorkingServiceApi.getAssociationWorkings(getToken())
            .then((associationWorkings: IAssociationWorking[]) => {
                setAssociationWorkings(associationWorkings);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getToken]);

    const handleAssociationWorkingsEmpty = (): boolean => {
        return associationWorkings.length === 0
    }

    const handleUpdate = (updatedAssociationWorking: IAssociationWorking): void => {
        const index = associationWorkings.findIndex((associationWorking: IAssociationWorking) => associationWorking.number === updatedAssociationWorking.number);
        const modifiedAssociationWorkings = JSON.parse(JSON.stringify(associationWorkings));
        modifiedAssociationWorkings.splice(index, 1, updatedAssociationWorking);
        setAssociationWorkings(modifiedAssociationWorkings);
    }

    return (
        <>
            <PageHeader headerImage="header/association-working.jpg"
                        headerImagePosition={{positionY: "42%", mirrorX: true}}
                        process={loading}>
                Vereinsarbeiten
            </PageHeader>
            <PageContent className={classes.associationWorkingContainer}>
                {handleAssociationWorkingsEmpty() && (
                    <div className={classes.noAssociationWorkings}>
                        {`${loading ? "Vereinsarbeiten werden geladen" : "Es sind keine Vereinsarbeiten vorhanden."}`}
                    </div>
                )}
                {!handleAssociationWorkingsEmpty() && (<div className={classes.associationWorkingViewContainer}>
                    {associationWorkings.map((associationWorking: IAssociationWorking) => (
                        <AssociationWorking key={associationWorking.number}
                                            associationWorking={associationWorking}
                                            onUpdate={handleUpdate}/>
                    ))}
                </div>)}
            </PageContent>
        </>

    );
}
