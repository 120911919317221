import React, {PropsWithChildren} from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {BackgroundTextField} from "../../../components/StyledComponents";

const useStyles = makeStyles(() => createStyles({
    registerSection: {
        marginTop: "40px",
        marginBottom: "20px",
        color: "#023553"
    },
}));

export interface RegisterContactProps extends PropsWithChildren<any> {
    validated: boolean;
    streetLine: string;
    zipCode: string;
    city: string;
    phoneNumber: string;
    mobileNumber: string;

    onChangeStreetline(streetLine: string): void;

    onChangeZipCode(zipCode: string): void;

    onChangeCity(city: string): void;

    onChangePhoneNumber(phoneNumber: string): void;

    onChangeMobileNumber(mobileNumber: string): void;
}

export default function RegisterContact(props: RegisterContactProps) {
    const {
        validated, streetLine, zipCode, city, phoneNumber, mobileNumber,
        onChangeStreetline, onChangeZipCode, onChangeCity, onChangePhoneNumber, onChangeMobileNumber
    } = props;

    const classes = useStyles();

    return (
        <>
            <div className={classes.registerSection}>Anschrift und Kontakt</div>
            <BackgroundTextField required
                                 error={validated && !streetLine}
                                 helperText={validated && !streetLine ? "Die Straße und Hausnummer fehlt." : null}
                                 label="Straße und Hausnummer"
                                 variant="outlined"
                                 placeholder="Deine Straße und Hausnummer"
                                 onChange={(event: any) => {
                                     onChangeStreetline(event.target.value);
                                 }}/>
            <BackgroundTextField required
                                 error={validated && !zipCode}
                                 helperText={validated && !zipCode ? "Die Postleitzahl fehlt." : null}
                                 label="Postleitzahl"
                                 variant="outlined"
                                 placeholder="Deinen Postleitzahl"
                                 onChange={(event: any) => {
                                     onChangeZipCode(event.target.value);
                                 }}/>
            <BackgroundTextField required
                                 error={validated && !city}
                                 helperText={validated && !city ? "Die Stadt fehlt." : null}
                                 label="Stadt"
                                 variant="outlined"
                                 placeholder="Deinen Stadt"
                                 onChange={(event: any) => {
                                     onChangeCity(event.target.value);
                                 }}/>
            <BackgroundTextField required={(!phoneNumber && !mobileNumber) || (!!phoneNumber && !mobileNumber)}
                                 error={validated && !phoneNumber && !mobileNumber}
                                 helperText={validated && !phoneNumber && !mobileNumber ? "Die Festnetznummer fehlt." : null}
                                 label="Festnetznummer"
                                 variant="outlined"
                                 placeholder="Deinen Festnetznummer"
                                 onChange={(event: any) => {
                                     onChangePhoneNumber(event.target.value);
                                 }}/>
            <BackgroundTextField required={(!mobileNumber && !phoneNumber) || (!!mobileNumber && !phoneNumber)}
                                 error={validated && !mobileNumber && !phoneNumber}
                                 helperText={validated && !mobileNumber && !phoneNumber ? "Die Mobilnummer fehlt." : null}
                                 label="Mobilnummer"
                                 variant="outlined"
                                 placeholder="Deinen Mobilnummer"
                                 onChange={(event: any) => {
                                     onChangeMobileNumber(event.target.value);
                                 }}/>
        </>
    );
}