import React, {useCallback, useContext, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {BoatServiceApi, BoatswainSort, SlotServiceApi} from "../../../api/BoatServiceApi";
import IBoat, {IBoatswain} from "../../../model/boat/IBoat";
import BookingFilter, {BookingBoatFilter} from "./BookingFilter";
import {useLocation, useNavigate} from "react-router";
import * as ROUTES from "../../../constants/routes";
import WeekCarousel, {WeekDay} from "../../../components/date/WeekCarousel";
import {Icon, Theme} from "@mui/material";
import Slot from "../../../components/slot/Slot";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import {ISlot, ISlotDto} from "../../../model/booking/ISlot";
import {format, isEqual, parse} from "date-fns";
import ISlotAvailable from "../../../model/booking/ISlotAvailable";
import IBooking from "../../../model/booking/IBooking";
import {UserRole} from "../../../model/IUser";
import BoatswainContact from "../../../components/contact/BoatswainContact";
import {MemberServiceApi} from "../../../api/MemberServiceApi";
import IMember from "../../../model/member/IMember";
import {CurrentUser} from "../../../components/contact/CurrentUser";
import ContentContainer from "../../../components/ContentContainer";
import DialogButton from "../../../components/DialogButton";
import {handleDateTimeToIsoString, handleIsoStringToDateTime} from "../../../model/DateHandler";

const useStyles = makeStyles((theme: Theme) => createStyles({
    bookingContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "1280px",
        minWidth: "320px",
        margin: "30px auto auto",
        [theme.breakpoints.down(500)]: {
            marginTop: "0"
        }
    },
    bookingHeadline: {
        width: "100%",
        fontSize: "38px",
        fontWeight: 700,
        lineHeight: "52px",
        textAlign: "center",
        color: "#023553",
        margin: "40px auto",
        [theme.breakpoints.down(950)]: {
            fontSize: "30px",
            lineHeight: "40px"
        },
        [theme.breakpoints.down(500)]: {
            fontSize: "24px",
            lineHeight: "30px"
        }
    },
    bookingViewContainer: {
        backgroundColor: "#ffffff",
        margin: "0 auto 40px",
        padding: "0 20px 20px",
        width: "calc(415px + 415px + 20px - 40px)",
        [theme.breakpoints.down(950)]: {
            width: "calc(415px - 40px)",
            padding: "0 0 20px",
        },
        [theme.breakpoints.down(415)]: {
            width: "100%",
        }
    },
    bookingViewSelectedBoat: {},
    bookingViewSelectedBoatDetails: {
        marginBottom: "20px",
        [theme.breakpoints.down(950)]: {
            margin: "0 20px 20px",
        },
    },
    bookingMessages: {
        [theme.breakpoints.down(950)]: {
            margin: "0 20px 20px",
        },
    },
    bookingTimeSlotContainer: {
        [theme.breakpoints.down(950)]: {
            margin: "0 20px 20px",
        },
    },
    sectionHeadline: {
        textTransform: "uppercase",
        marginBottom: "20px",
        "&.withMarginTop": {
            marginTop: "60px",
            [theme.breakpoints.down(950)]: {
                margin: "20px"
            }
        },
        [theme.breakpoints.down(950)]: {
            margin: "20px"
        }
    },
    noBoats: {
        textAlign: "center",
        color: "#023553",
        marginTop: "40px"
    }
}));

const warningIcon = {
    fontSize: "16px",
    lineHeight: "16px",
    color: "#FE6B6B",
    margin: "4px 5px auto 0"
}

type CurrentSlotInfo = {
    slotIndex: number,
    slotsAvailableIndex: number,
    currentSlotAvailable: ISlotAvailable
}

type BookedBoat = {
    booking: IBooking,
    boat: IBoat | undefined
}

export default function Booking() {
    // loading
    const [loading, setLoading] = useState<boolean>(false);
    // model
    const [boats, setBoats] = useState<IBoat[]>([]);
    const [bookableBoats, setBookableBoats] = useState<IBoat[]>([]);
    const [futureBookedBoats, setFutureBookedBoats] = useState<BookedBoat[]>();
    const [boatTypes, setBoatTypes] = useState<string[]>([]);
    const [filter, setFilter] = useState<BookingBoatFilter>();

    const [selectableBoatCount, setSelectableBoatCount] = useState<number>(0);
    const [selectedBoat, setSelectedBoat] = useState<IBoat>();
    const [preSelectedBoatNumber, setPreSelectedBoatNumber] = useState<string>();

    const [selectedWeek, setSelectedWeek] = useState<WeekDay[]>([]);
    const [selectedDayOfWeek, setSelectedDayOfWeek] = useState<Date>(new Date());
    const [slots, setSlots] = useState<ISlot[]>([]);
    const [slotsAvailable, setSlotsAvailable] = useState<ISlotAvailable[]>([]);

    const [currentUser, setCurrentUser] = useState<CurrentUser>(new CurrentUser());

    const {user, getToken} = useContext(AuthServiceContext);

    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();

    const handleQueryParams = useCallback((boats: IBoat[]) => {
        const queryParams = new URLSearchParams(location.search);
        const selectedBoatNumber: string | null = queryParams.get("selectedBoat");
        if (!!selectedBoatNumber) {
            const filteredBoats = boats.filter(boat => boat.number === selectedBoatNumber);
            const selectedBoat = filteredBoats[0];
            setPreSelectedBoatNumber(selectedBoat.number);
            queryParams.delete("selectedBoat");
            navigate(ROUTES.BOOKING, {
                replace: true,
                state: {search: queryParams.toString()}
            });
        }
    }, [navigate, location.search]);

    const handleFutureBookings = useCallback((boats: IBoat[]) => {
        const fetchData = async (): Promise<void> => {
            const currentMember: IMember = await MemberServiceApi.getMember(user, getToken());
            const currentUser = new CurrentUser(currentMember);
            setCurrentUser(currentUser);
            // read bookings from current member
            let futureBookings: IBooking[] =
                await SlotServiceApi.getFutureBookings(currentUser.getIdentifier(), getToken());
            // if current user is part of a family read also all other future bookings
            if (currentUser.isFamily()) {
                if (currentUser.isSubordinated()) {
                    // read bookings from principle member
                    const futurePrincipleUserBookings: IBooking[] =
                        await SlotServiceApi.getFutureBookings(currentUser.getPrincipleIdentifier(), getToken());
                    futureBookings = [...futureBookings, ...futurePrincipleUserBookings];
                    const subordinatedMembers: IMember[] =
                        await MemberServiceApi.getFamilyOfMember(currentUser.getPrincipleIdentifier(), getToken());
                    for (const subordinatedMember of subordinatedMembers) {
                        // filter current user, because is already part of the future bookings
                        if (currentUser.getIdentifier() === subordinatedMember.identifier) {
                            console.info("ignore because already added")
                            continue;
                        }
                        console.info("subordinatedMember", subordinatedMember.identifier);
                        // add subordinated member to current user
                        currentUser.addOtherSubordinated(subordinatedMember.identifier);
                        // load future bookings of the other subordinated member
                        const futureSubordinateUserBookings: IBooking[] =
                            await SlotServiceApi.getFutureBookings(subordinatedMember.identifier, getToken());
                        futureBookings = [...futureBookings, ...futureSubordinateUserBookings];
                    }
                } else {
                    // read bookings from all subordinated members
                    const subordinatedMembers: IMember[] =
                        await MemberServiceApi.getFamilyOfMember(currentUser.getIdentifier(), getToken());
                    for (const subordinatedMember of subordinatedMembers) {
                        // add subordinated member to current user
                        currentUser.addOtherSubordinated(subordinatedMember.identifier);
                        // load future bookings of the other subordinated member
                        const futureSubordinateUserBookings: IBooking[] =
                            await SlotServiceApi.getFutureBookings(subordinatedMember.identifier, getToken());
                        futureBookings = [...futureBookings, ...futureSubordinateUserBookings];
                    }
                }
            }
            if (futureBookings.length > 0) {
                const futureBookedBoats: BookedBoat[] = futureBookings.map(futureBooking => {
                    return {
                        booking: futureBooking,
                        boat: boats.find(boat => boat.number === futureBooking.boatNumber)
                    }
                });
                setFutureBookedBoats(futureBookedBoats);
            } else {
                setFutureBookedBoats([]);
            }
        }
        setLoading(true);
        fetchData()
            .catch((error) => console.error("unexpected error: " + error.message))
            .finally(() => setLoading(false));
    }, [user, getToken]);

    useEffect(() => {
        setLoading(true);
        BoatServiceApi.getBoats(getToken())
            .then(response => {
                const boats: IBoat[] = response.sort((a: IBoat, b: IBoat) => {
                    return Number(a.number).valueOf() - Number(b.number).valueOf();
                });
                const bookableBoats: IBoat[] =
                    boats.filter(boat => !boat.outOfOrder || (!!boat.outOfOrder && !boat.outOfOrder.broken));
                setBoats(boats);
                setBookableBoats(bookableBoats);
                handleQueryParams(boats);
                handleFutureBookings(boats);
            })
            .catch((error) => console.error("unexpected error: " + error.message))
            .finally(() => setLoading(false));
    }, [getToken, handleQueryParams, handleFutureBookings]);

    useEffect(() => {
        const boatTypes: string[] = [];
        bookableBoats.forEach((boat: IBoat) => {
            if (!boatTypes.includes(boat.type)) {
                boatTypes.push(boat.type);
            }
        });
        setBoatTypes(boatTypes);
        setSelectableBoatCount(bookableBoats.length);
    }, [bookableBoats]);

    useEffect(() => {
        const currentSelectedBoat = !!filter?.selectedBoatNumber ?
            bookableBoats.filter(boat => boat.number === filter.selectedBoatNumber)[0] : undefined;
        setSelectedBoat(currentSelectedBoat);
        if (!!currentSelectedBoat?.number) {
            console.info("selected boat:", currentSelectedBoat.number);
        }
    }, [filter, bookableBoats]);

    useEffect(() => {
        if (!!selectedBoat && !!selectedDayOfWeek) {
            setLoading(true);
            SlotServiceApi.getSlots(selectedBoat.number, format(selectedDayOfWeek, "yyyyMMdd"), getToken())
                .then((slotList: ISlotDto[]) => {
                    const slots: ISlot[] = slotList.map((slotDto: ISlotDto) => ({
                        location: slotDto.location,
                        start: parse(slotDto.start, "HH:mm", selectedDayOfWeek),
                        end: parse(slotDto.end, "HH:mm", selectedDayOfWeek),
                        bookingMember: slotDto.bookingMember
                    }));
                    setSlots(slots);
                })
                .catch((error) => console.error("unexpected error: " + error.message))
                .finally(() => setLoading(false));
        }
    }, [selectedBoat, selectedDayOfWeek, getToken]);

    const handleSelectedWeek = useCallback(async (week: Date[]): Promise<void> => {
        if (!!selectedBoat && week.length > 0) {
            const response: any[] = await SlotServiceApi.getSlotsAvailable(selectedBoat.number, format(week[0], "yyyyMMdd"), getToken());
            const slotsAvailable: ISlotAvailable[] = response.map((value, index: number) => ({
                dayOfWeek: parse(value.dayOfWeek, "yyyyMMdd", week[index]),
                count: value.count
            }));
            setSlotsAvailable(slotsAvailable);

            const daysOfWeek: WeekDay[] = handleConvertWeek(week, slotsAvailable);
            setSelectedWeek(daysOfWeek);
        }
    }, [selectedBoat, getToken]);

    const handleSelectedDayOfWeek = useCallback((dayOfWeek: Date) => {
        setSelectedDayOfWeek(dayOfWeek);
    }, []);

    const handleUpdateFilter = (filter: BookingBoatFilter) => {
        setFilter(filter);
        setSelectableBoatCount(bookableBoats.filter(boat => handleFilterBoat(boat, filter)).length);
    }

    const handleFilterBoat = (boat: IBoat, filter: BookingBoatFilter) => {
        return (filter?.boatType === undefined || boat.type === filter.boatType)
            && (filter?.boatLocation === undefined || boat.location === filter.boatLocation);
    }

    const handleSlotAvailable = (): boolean => {
        return slots.filter((slot: ISlot) => !slot.bookingMember).length > 0;
    }

    const handleBooking = async (start: Date, end: Date): Promise<ISlot> => {
        const currentSlotInfo = findCurrentSlotInfo(start, end);
        if (!!selectedBoat && !!selectedDayOfWeek) {
            const createdSlot: ISlotDto = await SlotServiceApi.createSlot(
                createBooking(selectedBoat, start, end), getToken());

            console.info("stored slot:", createdSlot);
            const slot: ISlot = updateSlot(createdSlot, currentSlotInfo);

            updateSlotAvailableCount(currentSlotInfo, -1);

            handleFutureBookings(bookableBoats);

            return slot;
        }
        return slots[currentSlotInfo.slotIndex];
    }

    const handleCancel = async (start: Date, end: Date): Promise<void> => {
        const currentSlotInfo: CurrentSlotInfo = findCurrentSlotInfo(start, end);
        if (!!selectedBoat && !!selectedDayOfWeek) {
            const deletedSlot: ISlotDto = await SlotServiceApi.deleteSlot(
                createBooking(selectedBoat, start, end), getToken());

            console.info("canceled slot:", deletedSlot);
            updateSlot(deletedSlot, currentSlotInfo);

            updateSlotAvailableCount(currentSlotInfo, 1);

            handleFutureBookings(bookableBoats);
        }
    }

    const handleCancelBrokenBoat = async (bookedBoat: BookedBoat): Promise<void> => {
        const start: Date = handleIsoStringToDateTime(bookedBoat.booking.start);
        const end: Date = handleIsoStringToDateTime(bookedBoat.booking.end);
        if (bookedBoat.boat) {
            const deletedSlot: ISlotDto = await SlotServiceApi.deleteSlot(
                createBooking(bookedBoat.boat, start, end), getToken());

            console.info("canceled broken boat slot:", deletedSlot);

            handleFutureBookings(boats);
        }
    }

    const handleConvertWeek = (week: Date[], slotsAvailable: ISlotAvailable[]): WeekDay[] => {
        return week.map((dayOfWeek: Date, index: number) => {
            const currentSlotAvailable: ISlotAvailable = slotsAvailable[index];
            return {
                date: dayOfWeek,
                count: currentSlotAvailable && isEqual(currentSlotAvailable.dayOfWeek, dayOfWeek)
                    ? currentSlotAvailable.count : 3
            }
        });
    }

    const handleAllowedToBook = (): boolean => {
        console.log("handleAllowedToBook:", user.roles, futureBookedBoats, user.isInUserRole(UserRole.BOOKING));
        return user.isInUserRole(UserRole.BOOKING)
            && (user.isInUserRole(UserRole.MULTI_BOOKING) || !futureBookedBoats || futureBookedBoats.length === 0);
    }

    const handleBookedBoat = (): BookedBoat | undefined => {
        return futureBookedBoats && futureBookedBoats.length > 0 ? futureBookedBoats[0] : undefined;
    }

    const handleSelectedDayForSlotHeadline = (): string => {
        return !!selectedDayOfWeek ? " am " + format(selectedDayOfWeek, 'dd.MM.yyyy') : ""
    }

    const createBooking = (selectedBoat: IBoat, start: Date, end: Date): IBooking => {
        return {
            boatNumber: selectedBoat.number,
            start: handleDateTimeToIsoString(start),
            end: handleDateTimeToIsoString(end),
            contactIdentifier: currentUser.getIdentifier()
        }
    }

    const updateSlotAvailableCountForWeek = (): void => {
        const daysOfWeek: WeekDay[] = selectedWeek.map((dayOfWeek: WeekDay, index: number) => {
            const currentSlotAvailable = slotsAvailable[index];
            return {
                date: dayOfWeek.date,
                count: currentSlotAvailable && isEqual(currentSlotAvailable.dayOfWeek, dayOfWeek.date)
                    ? currentSlotAvailable.count : 3
            }
        });
        setSelectedWeek(daysOfWeek);
    }

    const updateSlotAvailableCount = (currentSlotInfo: CurrentSlotInfo, count: number): void => {
        const slotAvailable: ISlotAvailable = {
            dayOfWeek: currentSlotInfo.currentSlotAvailable.dayOfWeek,
            count: currentSlotInfo.currentSlotAvailable.count + count
        }
        slotsAvailable.splice(currentSlotInfo.slotsAvailableIndex, 1, slotAvailable);
        setSlotsAvailable(slotsAvailable);
        updateSlotAvailableCountForWeek();
    }

    const updateSlot = (slotDto: ISlotDto, currentSlotInfo: CurrentSlotInfo): ISlot => {
        const slot: ISlot = {
            location: slotDto.location,
            start: parse(slotDto.start, "HH:mm", selectedDayOfWeek),
            end: parse(slotDto.end, "HH:mm", selectedDayOfWeek),
            bookingMember: slotDto.bookingMember
        }
        slots.splice(currentSlotInfo.slotIndex, 1, slot);
        setSlots(slots);
        return slot;
    }

    const findCurrentSlotInfo = (start: Date, end: Date): CurrentSlotInfo => {
        const slotIndex = slots.findIndex((slot: ISlot) => slot.start === start && slot.end === end);
        const slotsAvailableIndex = slotsAvailable
            .findIndex((slotsAvailable: ISlotAvailable) => isEqual(slotsAvailable.dayOfWeek, selectedDayOfWeek));
        return {
            slotIndex: slotIndex,
            slotsAvailableIndex: slotsAvailableIndex,
            currentSlotAvailable: slotsAvailable[slotsAvailableIndex]
        }
    }

    const alreadyBookedMessage = () => {
        const bookedBoat = handleBookedBoat();
        if (bookedBoat) {
            const isOther: boolean =
                currentUser.isFamily() && currentUser.isFamilyMember(bookedBoat.booking.contactIdentifier);
            const isBoatBroken: boolean =
                bookedBoat.boat && bookedBoat.boat.outOfOrder ? bookedBoat.boat.outOfOrder.broken : false;
            const boatName: string = bookedBoat.boat ? bookedBoat.boat.name : "";
            return (
                <>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Icon style={warningIcon}>info</Icon>
                        <p>
                            {isOther ? "Ein Mitglied deiner Familie hat " : "Du hast "}bereits das Boot
                            "{boatName}" gebucht, sodass du derzeit keine weitere Buchung vornehmen kannst.
                            {isBoatBroken ? " Da das Boot aktuell gesperrt ist kannst du es hier stornieren." : ""}
                        </p>
                    </div>
                    {!user.isInUserRole(UserRole.BOOKING) && (
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Icon style={warningIcon}>info</Icon>
                            <p>Du darfst aktuell nicht buchen.</p>
                        </div>
                    )}
                    {isBoatBroken && (
                        <DialogButton
                            style={{width: "190px", marginLeft: "20px", marginRight: "auto", marginBottom: "20px"}}
                            variant="secondary"
                            label="Zeitslot stornieren"
                            title="Zeitslot wirklich stornieren"
                            details="Möchtest Du das ausgewählte Boot wirklich stornieren?"
                            onActionClick={() => {
                                handleCancelBrokenBoat(bookedBoat)
                                    .catch((error) => console.error("unexpected error: " + error.message))
                            }}
                            actionLabel="Stornieren"/>
                    )}
                </>
            )
        } else {
            return (<>{!user.isInUserRole(UserRole.BOOKING) && (
                <div style={{display: "flex", flexDirection: "row"}}>
                    <Icon style={warningIcon}>info</Icon>
                    <p>Du darfst aktuell nicht buchen.</p>
                </div>
            )}</>)
        }
    }

    return (
        <ContentContainer process={loading}>
            <div className={classes.bookingContainer}>
                <div className={classes.bookingHeadline}>Boot buchen</div>
                <BookingFilter boats={bookableBoats}
                               boatTypes={boatTypes}
                               preSelectedBoatNumber={preSelectedBoatNumber}
                               onUpdateFilter={handleUpdateFilter}/>
                {selectableBoatCount === 0 && (<div className={classes.noBoats}>
                    Zu dieser Suchanfrage sind keine Boote vorhanden.</div>)}
                {(!selectedBoat && !handleAllowedToBook()) && (<div className={classes.bookingViewContainer}>
                    <div className={classes.bookingMessages}>
                        {alreadyBookedMessage()}
                    </div>
                </div>)}
                {!!selectedBoat && (<div className={classes.bookingViewContainer}>
                    <div className={classes.bookingViewSelectedBoat}>
                        <div className={classes.sectionHeadline} style={{fontWeight: 500}}>
                            {selectedBoat.name}
                        </div>
                        <div className={classes.bookingViewSelectedBoatDetails}>
                            {selectedBoat.specifics ? selectedBoat.specifics : "Keine Besonderheiten"}
                        </div>
                        {selectedBoat.boatswains.length > 0 && (
                            <div className={classes.bookingViewSelectedBoatDetails}>
                                {selectedBoat.boatswains
                                    .sort(BoatswainSort).map((boatswain: IBoatswain, index: number) => (
                                        <BoatswainContact key={index} boatswain={boatswain}/>
                                    ))}
                            </div>
                        )}
                        <div className={`${classes.sectionHeadline} withMarginTop`}>
                            Tag auswählen
                        </div>
                        <WeekCarousel selectedWeek={selectedWeek}
                                      onSelectedWeek={handleSelectedWeek}
                                      onSelectedDayOfWeek={handleSelectedDayOfWeek}/>
                        <div className={`${classes.sectionHeadline} withMarginTop`}>
                            Zeitslot wählen {handleSelectedDayForSlotHeadline()}
                        </div>
                        {(!handleSlotAvailable() || !handleAllowedToBook()) && (
                            <div className={classes.bookingMessages}>
                                {!handleSlotAvailable() && (<div style={{display: "flex"}}>
                                    <Icon style={warningIcon}>info</Icon>
                                    Leider gibt es an dem ausgewählten Tag keine freien Zeitslots
                                </div>)}
                                {!handleAllowedToBook() && alreadyBookedMessage()}
                            </div>)}
                        <div className={classes.bookingTimeSlotContainer}>
                            {slots.map((slot: ISlot, index: number) => (
                                <Slot key={index}
                                      start={slot.start}
                                      end={slot.end}
                                      bookingMember={slot.bookingMember}
                                      allowedToBook={handleAllowedToBook()}
                                      currentUser={currentUser}
                                      onBooking={handleBooking}
                                      onCancel={handleCancel}
                                />
                            ))}
                        </div>
                    </div>
                </div>)}
            </div>
        </ContentContainer>
    );
}
