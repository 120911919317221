import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import * as TYPES from "../../constants/siteTypes";
import EditableSite from "../../components/site/EditableSite";
import {BeforeSectionExtension} from "../../components/site/EditableSection";
import SiteImage from "../../components/site/SiteImage";

const useStyles = makeStyles((theme) => createStyles({
    homeAboutUsContainer: {
        backgroundColor: "#4C627F",
        padding: "20px 0"
    }
}));

export default function HomeAboutUs() {
    const classes = useStyles();

    return (
        <div className={classes.homeAboutUsContainer}>
            <EditableSite siteType={TYPES.HOME} allowAddSection={false}>
                <BeforeSectionExtension position={1}>
                    <SiteImage image="site-icon/anchor_white.svg" width="38px" height="42px"/>
                </BeforeSectionExtension>
            </EditableSite>
        </div>
    );
}
