import {format, parse} from "date-fns";

export const handleDateToLocaleString = (date: string | Date | null | undefined) => {
    if (typeof date === "string") {
        const dateTimestamp = Date.parse(date);
        date = new Date(dateTimestamp);
    }
    return !!date ?
        date.toLocaleDateString('de-DE', {day: "2-digit", month: "2-digit", year: "numeric"}) : "";
}

export const handleDateToIsoString = (date: string | Date | null) => {
    if (typeof date === "string") {
        const dateTimestamp = Date.parse(date);
        date = new Date(dateTimestamp);
    }
    return !!date ?
        date.toLocaleDateString('en-EN', {month: "2-digit", day: "2-digit", year: "numeric"}) : "";
}

export const handleDateTimeToIsoString = (date: Date): string => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
}

export const handleIsoStringToDateTime = (date: string): Date => {
    return parse(date, "yyyy-MM-dd'T'HH:mm:ss'Z'", new Date());
}

export const handleMaxDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - (12 * 6));
    return date;
}

export const handleIsoStringToDate = (date: string | undefined): Date | null => {
    return !!date ? new Date(Date.parse(date)) : null;
}
