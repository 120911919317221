import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import {BORNHORST, ZWISCHENAHN} from "../../api/WeatherServiceApi";
import Weather from "../../components/weather/Weather";

const useStyles = makeStyles(() => createStyles({
    homeWeatherContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "20px",
        position: "relative"
    },
    homeWeatherHeadline: {
        marginBottom: "20px",
        color: "#023553",
        textAlign: "center"
    }
}));

export default function HomeWeather() {
    const classes = useStyles();

    return (
        <div className={classes.homeWeatherContainer}>
            <Typography className={classes.homeWeatherHeadline}
                        variant={"h1"}
                        component={"h1"}>Wetter</Typography>
            <Weather style={{marginBottom: "20px"}} location={ZWISCHENAHN} title="Bad Zwischenahn"/>
            <Weather location={BORNHORST} title="Bornhorst"/>
        </div>
    );
}
