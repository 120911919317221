import React, {PropsWithChildren} from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import {Gender, genderToText} from "../../../model/member/IMember";
import {BackgroundTextField} from "../../../components/StyledComponents";
import {DatePicker} from "@mui/lab";
import {DateValidationError} from "@mui/lab/internal/pickers/date-utils";
import {ParseableDate} from "@mui/lab/internal/pickers/constants/prop-types";
import BackgroundDropdown from "../../../components/BackgroundDropdown";
import {TextFieldProps} from "@mui/material";

const useStyles = makeStyles(() => createStyles({
    registerSection: {
        marginTop: "40px",
        marginBottom: "20px",
        color: "#023553"
    },
}));

export interface RegisterPersonalDataProps extends PropsWithChildren<any> {
    validated: boolean;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: Date | null;

    onChangeFirstName(firstName: string): void;

    onChangeLastName(lastName: string): void;

    onChangeGender(gender: Gender): void;

    onChangeDateOfBirth(dateOfBirth: Date | null): void;
}

export default function RegisterPersonalData(props: RegisterPersonalDataProps) {
    const {
        validated, firstName, lastName, gender, dateOfBirth,
        onChangeFirstName, onChangeLastName, onChangeGender, onChangeDateOfBirth
    } = props;

    const classes = useStyles();

    const handleMaxDate = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - (12 * 6));
        return date;
    }

    return (
        <>
            <div className={classes.registerSection}>Persönliche Daten</div>
            <BackgroundTextField required
                                 error={validated && !firstName}
                                 helperText={validated && !firstName ? "Der Vorname fehlt." : null}
                                 variant="outlined"
                                 label="Vorname"
                                 placeholder="Deinen Vorname"
                                 onChange={(event: any) => {
                                     onChangeFirstName(event.target.value)
                                 }}/>
            <BackgroundTextField required
                                 error={validated && !lastName}
                                 helperText={validated && !lastName ? "Der Nachname fehlt." : null}
                                 variant="outlined"
                                 label="Nachname"
                                 placeholder="Deinen Nachname"
                                 onChange={(event: any) => {
                                     onChangeLastName(event.target.value)
                                 }}/>
            <BackgroundDropdown id="register-gender-label"
                                label="Geschlecht"
                                style={{marginBottom: "17px"}}
                                value={gender}
                                nonValue={Gender.NONE}
                                errorMessage="Geschlecht nicht ausgewählt."
                                validated={validated}
                                required={true}
                                onChange={(newValue: any) => {
                                    onChangeGender(newValue);
                                }}>
                <MenuItem value={Gender.MALE}>{genderToText(Gender.MALE)}</MenuItem>
                <MenuItem value={Gender.FEMALE}>{genderToText(Gender.FEMALE)}</MenuItem>
                <MenuItem value={Gender.DEVICE}>{genderToText(Gender.DEVICE)}</MenuItem>
            </BackgroundDropdown>
            <DatePicker
                label="Geburtsdatum"
                openTo="year"
                views={["year", "day"]}
                maxDate={handleMaxDate()}
                value={dateOfBirth}
                onChange={onChangeDateOfBirth}
                onError={(reason: DateValidationError, value: ParseableDate<Date>) => {
                    console.info(reason, value);
                }}
                renderInput={(params: TextFieldProps) =>
                    <BackgroundTextField {...params}
                                         required
                                         label="Geburtsdatum"
                                         placeholder="Geburtsdatum"
                                         error={validated && !dateOfBirth}
                                         helperText={validated && !dateOfBirth ? "Dein Geburtsdatum fehlt." : null}
                    />}
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
            />
        </>
    );
}
