import React, {useContext, useEffect, useState} from "react";
import Text from "../../components/Text";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import {BackgroundTextField} from "../../components/StyledComponents";
import ContentButton from "../../components/ContentButton";
import ContentLoadingButton from "../../components/ContentLoadingButton";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {MemberServiceApi} from "../../api/MemberServiceApi";
import {ProfileSectionProps} from "./Profile";
import {ApiError} from "../../model/Errors";
import {IMemberContact} from "../../model/member/IMember";

export default function ProfileContact(props: ProfileSectionProps) {
    const {onMemberChange, member, classes} = props;
    const {getToken} = useContext(AuthServiceContext);

    const [edit, setEdit] = useState<boolean>();
    const [validated, setValidated] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);

    const [memberAddressStreetLine, setMemberAddressStreetLine] = useState<string>("");
    const [memberAddressZipCode, setMemberAddressZipCode] = useState<string>("");
    const [memberAddressCity, setMemberAddressCity] = useState<string>("");
    const [memberPhoneNumber, setMemberPhoneNumber] = useState<string>("");
    const [memberMobileNumber, setMemberMobileNumber] = useState<string>("");

    // contact section
    const [streetLine, setStreetLine] = useState<string>(memberAddressStreetLine);
    const [zipCode, setZipCode] = useState<string>(memberAddressZipCode);
    const [city, setCity] = useState<string>(memberAddressCity);
    const [phoneNumber, setPhoneNumber] = useState<string>(memberPhoneNumber);
    const [mobileNumber, setMobileNumber] = useState<string>(memberMobileNumber);

    useEffect(() => {
        if (!!member) {
            setMemberAddressStreetLine(member.contact.address.streetLine);
            setStreetLine(member.contact.address.streetLine);
            setMemberAddressZipCode(member.contact.address.zipCode);
            setZipCode(member.contact.address.zipCode);
            setMemberAddressCity(member.contact.address.city);
            setCity(member.contact.address.city);
            setMemberPhoneNumber(member.contact.phoneNumber);
            setPhoneNumber(member.contact.phoneNumber);
            setMemberMobileNumber(member.contact.mobileNumber);
            setMobileNumber(member.contact.mobileNumber);
        }
    }, [member]);

    const handleSave = () => {
        if (handleValidation()) {
            setValidated(false);
            if (handleUpdated() && !!member) {
                setPending(true);
                MemberServiceApi.updateContact(member, createContactToUpdate(), getToken()).then(response => {
                    onMemberChange(response);
                    setEdit(false);
                }).catch((error: ApiError) => {
                    if (error.status !== 404) {
                        console.error("unexpected error: " + error.message);
                    }
                }).finally(() => {
                    setPending(false);
                });
            } else {
                setEdit(false);
            }
        }
    }

    const createContactToUpdate = (): IMemberContact => {
        return {
            address: {
                streetLine: streetLine,
                zipCode: zipCode,
                city: city
            },
            phoneNumber: phoneNumber,
            mobileNumber: mobileNumber
        }
    }

    const handleValidation = () => {
        setValidated(true);
        const valid = !!streetLine && !!zipCode && !!city && (!!phoneNumber || !!mobileNumber)
        if (valid) {
            console.info("address valid. Required fields are filled.");
        } else {
            console.info("address not valid. Required fields are not filled.");
        }
        return valid;
    }

    const handleUpdated = () => {
        const updated = memberAddressStreetLine !== streetLine
            || memberAddressZipCode !== zipCode
            || memberAddressCity !== city
            || memberPhoneNumber !== phoneNumber
            || memberMobileNumber !== mobileNumber;
        if (updated) {
            console.info("address updated.");
        } else {
            console.info("address not updated.");
        }
        return updated;
    }

    return (
        <div className={classes.profileSection}>
            <div className={classes.profileSectionHeader}>Anschrift und Kontakt</div>
            {!edit && (<div className={classes.profileSectionContent}>
                <Text label="Straße und Hausnummer" value={memberAddressStreetLine}/>
                <Text label="Postleitzahl" value={memberAddressZipCode}/>
                <Text label="Stadt" value={memberAddressCity}/>
                <Text label="Festnetznummer" value={memberPhoneNumber}/>
                <Text label="Mobilnummer" value={memberMobileNumber}/>
            </div>)}
            {edit && (<div className={classes.profileSectionContent}>
                <BackgroundTextField required
                                     error={validated && !streetLine}
                                     helperText={validated && !streetLine ? "Die Straße und Hausnummer fehlt." : null}
                                     label="Straße und Hausnummer"
                                     variant="outlined"
                                     defaultValue={memberAddressStreetLine}
                                     placeholder="Deine Straße und Hausnummer"
                                     onChange={(event: any) => {
                                         setStreetLine(event.target.value)
                                     }}/>
                <BackgroundTextField required
                                     error={validated && !zipCode}
                                     helperText={validated && !zipCode ? "Die Postleitzahl fehlt." : null}
                                     label="Postleitzahl"
                                     variant="outlined"
                                     defaultValue={memberAddressZipCode}
                                     placeholder="Deinen Postleitzahl"
                                     onChange={(event: any) => {
                                         setZipCode(event.target.value)
                                     }}/>
                <BackgroundTextField required
                                     error={validated && !city}
                                     helperText={validated && !city ? "Die Stadt fehlt." : null}
                                     label="Stadt"
                                     variant="outlined"
                                     defaultValue={memberAddressCity}
                                     placeholder="Deinen Stadt"
                                     onChange={(event: any) => {
                                         setCity(event.target.value)
                                     }}/>
                <BackgroundTextField required={(!phoneNumber && !mobileNumber) || (!!phoneNumber && !mobileNumber)}
                                     error={validated && !phoneNumber}
                                     helperText={validated && !phoneNumber ? "Die Festnetznummer fehlt." : null}
                                     label="Festnetznummer"
                                     variant="outlined"
                                     defaultValue={memberPhoneNumber}
                                     placeholder="Deinen Festnetznummer"
                                     onChange={(event: any) => {
                                         setPhoneNumber(event.target.value)
                                     }}/>
                <BackgroundTextField required={(!phoneNumber && !mobileNumber) || (!phoneNumber && !!mobileNumber)}
                                     error={validated && !mobileNumber}
                                     helperText={validated && !mobileNumber ? "Die Mobilnummer fehlt." : null}
                                     label="Mobilnummer"
                                     variant="outlined"
                                     defaultValue={memberMobileNumber}
                                     placeholder="Deinen Mobilnummer"
                                     onChange={(event: any) => {
                                         setMobileNumber(event.target.value)
                                     }}/>
                {edit && (<div className={classes.profileSectionButtonContainer}>
                    <ContentButton variant="secondary"
                                   className={classes.profileSectionAbortButton}
                                   onClick={() => setEdit(false)}>Abbrechen</ContentButton>
                    <ContentLoadingButton className={classes.profileSectionSaveButton}
                                          pending={pending}
                                          onClick={handleSave}>Speichern</ContentLoadingButton>
                </div>)}
            </div>)}
            <div className={classes.profileSectionActions}>
                {!edit && (<Button className={classes.profileSectionEditAction} onClick={() => setEdit(!edit)}>
                    <Icon style={{fontSize: "20px"}}>edit</Icon>
                </Button>)}
            </div>
        </div>
    );

}