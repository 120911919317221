import React from "react";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import EditableSite from "../../components/site/EditableSite";
import * as TYPES from "../../constants/siteTypes";
import {useNavigate} from "react-router";
import TeaserCarousel, {TeaserData} from "../../components/teaser/TeaserCarousel";
import * as ROUTES from "../../constants/routes";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import SiteImage from "../../components/site/SiteImage";
import {BeforeSectionExtension, BehindSectionExtension} from "../../components/site/EditableSection";
import ImageWithTextContainer from "../../components/ImageWithTextContainer";

const useStyles = makeStyles((theme: Theme) => createStyles({
    leisureProgrammContainer: {
        paddingTop: "20px"
    },
    leisureProgrammTeaserContainer: {
        margin: "auto",
        maxWidth: "1280px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "18px 43px 40px 43px",
        [theme.breakpoints.down(1280)]: {
            width: "100%",
            padding: "18px 0 5px 0"
        }
    },
    leisureProgrammTeaserHeadline: {
        margin: "20px auto 0",
        color: "#023553",
        textAlign: "center"
    },
}));

export default function LeisurePrograms() {

    //const theme: Theme = useTheme();
    //const mobile = useMediaQuery(theme.breakpoints.down(850));

    const navigate = useNavigate();
    const classes = useStyles();

    const teaserData: TeaserData[] = [
        {
            image: "teaser/buoy-5211255.jpg",
            title: "Ablegen vor der Haustür",
            content: "In Oldenburg und Bad Zwischenahn segeln Mitglieder mit der Bootsflotte des Clubs unabhängig von der Tiede.",
            actionTitle: "Zu Flotte und Reviere",
            action: () => {
                navigate(ROUTES.BOATS_AND_TERRITORIES);
            }
        },
        {
            image: "teaser/pexels-cottonbro-5851827.jpg",
            title: "Anheuern im Segelclub - so geht's",
            content: "Erfahrene Segler und Interessierte können als Clubmitglied aktiv Segeln und Boote nutzen oder ihr Handwerk erlernen – in bester Gesellschaft.",
            actionTitle: "Zur Mitgliedschaft",
            action: () => {
                navigate(ROUTES.MEMBERSHIP);
            }
        },
        {
            image: "teaser/home_teaser_children.jpeg",
            title: "Segeln für Kids",
            content: "Ob Landratte oder erfahrener Jungmatrose: Kids und Jugendliche mit Lust aufs Segeln können bei uns Erfahrungen sammeln und in See stechen.",
            actionTitle: "Zum Kindersegeln",
            action: () => {
                navigate(ROUTES.CHILD_SAILING_COURSES);
            }
        }
    ];

    return (
        <>
            <PageHeader headerImage="header/leisure-programs.jpg" headerImagePosition={{positionY: "30%"}}>
                Ferien- und Freizeitangebote
            </PageHeader>
            <PageContent className={classes.leisureProgrammContainer}>
                <EditableSite siteType={TYPES.LEISURE_PROGRAMS}>
                    <BeforeSectionExtension style={{ height: "48px" }} position={1}>
                        <SiteImage image="site-icon/light_mode_blue.svg"
                                   width="48px"
                                   height="48px"/>
                    </BeforeSectionExtension>
                    <BehindSectionExtension position={2}>
                        <ImageWithTextContainer
                            style={{marginTop: "50px"}}
                            image="breaker/pexels-mateusz-dach-1275692.jpg"
                            headline="Hier geht’s nicht um die Wurst, …"
                            content="… sondern um’s gemeinsame Ferienerlebnis und den Spaß. Darum wird auch gemunkelt, dass unsere Aktionen schon mal ungezwungen beim Grillen ausklingen."
                            contentStyle={{marginTop: "-50px"}}
                        />
                    </BehindSectionExtension>
                </EditableSite>
            </PageContent>
            <div className={classes.leisureProgrammTeaserContainer}>
                <TeaserCarousel data={teaserData}/>
            </div>
        </>
    );
}
