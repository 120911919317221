import React, {CSSProperties, useContext, useEffect, useState} from "react";
import IBoat, {IBoatswain} from "../../../model/boat/IBoat";
import {BoatServiceApi} from "../../../api/BoatServiceApi";
import {useNavigate, useParams} from "react-router";
import * as ROUTES from "../../../constants/routes";
import BoatHandbook from "./BoatHandbook";
import {UserRole} from "../../../model/IUser";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import BoatDetails from "./BoatDetails";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import ContentButton from "../../../components/ContentButton";
import BoatTabGroup from "./BoatTabGroup";
import BoatLogbook from "./BoatLogbook";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Icon, Theme} from "@mui/material";
import ContentContainer from "../../../components/ContentContainer";

const useStyles = makeStyles((theme: Theme) => createStyles({
    boatContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "850px",
        margin: "40px auto auto",
        [theme.breakpoints.down(850)]: {
            maxWidth: "425px"
        }
    },
    boatNavigationContainer: {
        marginBottom: "20px"
    },
    boatContentContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    boatContentRowContainer: {
        width: "100%",
        minHeight: "530px",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down(850)]: {
            flexDirection: "column"
        }
    },
    boatImageContainer: {
        width: "50%",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down(850)]: {
            width: "100%"
        }
    },
    boatImage: {
        width: "100%",
        minHeight: "530px",
        marginTop: "0",
        marginBottom: "auto",
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
    },
    boatDetails: {
        width: "50%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.down(850)]: {
            width: "100%"
        }
    },
    boatBackButton: {
        width: "170px",
        marginLeft: "0",
        marginRight: "auto",
        [theme.breakpoints.down(415)]: {
            width: "calc(100% - 40px)",
            marginLeft: "20px"
        }
    },
    boatBackButtonIcon: {
        fontSize: "14px !important",
        marginRight: "5px"
    },
    boatBookingButtonIcon: {
        fontSize: "14px !important",
        marginLeft: "5px"
    }
}));

type BoatParams = {
    boatNumber: string;
}

export default function Boat() {
    // loading
    const [loading, setLoading] = useState<boolean>(false);
    // model
    const {boatNumber} = useParams<BoatParams>();

    const [boat, setBoat] = useState<IBoat | undefined>(undefined);
    const [userIsBoatswain, setUserIsBoatswain] = useState<boolean>(false);

    const {user, editMode, getToken} = useContext(AuthServiceContext);

    const classes = useStyles();
    const navigate = useNavigate();
    const theme: Theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down(850));

    useEffect(() => {
        setLoading(true);
        console.info("selected boat:", boatNumber)
        BoatServiceApi.getBoat(boatNumber ? boatNumber : "NONE", getToken())
            .then((boat: IBoat) => {
                setBoat(boat);
                // check if user is boatswain of the boat
                setUserIsBoatswain(boat.boatswains
                    .filter((boatswain: IBoatswain) => boatswain.identifier === user.userId)
                    .length === 1);
            })
            .catch((error) => console.error("unexpected error: " + error.message))
            .finally(() => setLoading(false));
    }, [boatNumber, user, getToken]);

    const handleAllowedToEdit = () => {
        // allow if edit content or alternatively edit boat and is boatswain of the boat
        return !boat ? false : (user.isInUserRole(UserRole.EDIT_CONTENT) && editMode) ||
            (user.isInUserRole(UserRole.EDIT_BOAT) && userIsBoatswain);
    }

    const handlePicture = (): CSSProperties => {
        let url = "../static/images/boat-placeholder.jpg";
        let border: CSSProperties = {
            width: `${matchesMobile ? "calc(100% - 10px)" : "calc(100% - 5px)"}`,
            borderStyle: "solid",
            borderWidth: "5px",
            borderColor: "#FFFFFF",
            borderRight: "none"
        };
        if (boat && boat.picture) {
            border = {};
            if (boat.picture.startsWith("data:image")) {
                url = boat.picture;
            } else {
                url = "../" + boat.picture;
            }
        }
        return {
            ...border,
            backgroundImage: `url(${url})`
        }
    }

    return (
        <ContentContainer process={loading}>
            <div className={classes.boatContainer}>
                <div className={classes.boatNavigationContainer}>
                    <ContentButton className={classes.boatBackButton}
                                   variant="secondary"
                                   onClick={() => {
                                       navigate(ROUTES.BOATS);
                                   }}>
                        <Icon className={classes.boatBackButtonIcon}>arrow_backward</Icon>Zur Übersicht
                    </ContentButton>
                </div>
                {boat && (
                    <div className={classes.boatContentContainer}>
                        <div className={classes.boatContentRowContainer}>
                            <div className={classes.boatImageContainer}>
                                <div className={classes.boatImage} style={handlePicture()}/>
                            </div>
                            <div className={classes.boatDetails}>
                                <BoatDetails boat={boat}
                                             userIsBoatswain={userIsBoatswain}
                                             onLoading={(loading: boolean) => setLoading(loading)}/>
                            </div>
                        </div>
                        <BoatTabGroup style={{marginTop: "20px"}} tabs={[
                            {
                                title: "Handbuch",
                                tabKey: "handbook",
                                tabContent: <BoatHandbook boatName={boat.name} onAllowedToEdit={handleAllowedToEdit}/>
                            },
                            {
                                title: "Logbuch",
                                tabKey: "logbook",
                                tabContent: <BoatLogbook boatName={boat.name} onAllowedToEdit={handleAllowedToEdit}/>
                            }
                        ]}/>
                    </div>
                )}
            </div>
        </ContentContainer>
    );
};
