import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {useNavigate} from "react-router";
import * as ROUTES from "../../constants/routes";
import TeaserCarousel, {TeaserData} from "../../components/teaser/TeaserCarousel";
import {Theme, Typography} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    homeOfferingContainer: {
        margin: "auto",
        maxWidth: "1280px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "18px 43px 5px 43px",
        [theme.breakpoints.down(1280)]: {
            width: "100%",
            padding: "18px 0 5px 0"
        }
    },
    homeOfferingHeadline: {
        margin: "20px auto 0",
        color: "#023553",
        textAlign: "center"
    }
}));

export default function HomeOffering() {
    const classes = useStyles();
    const navigate = useNavigate();

    const teaserData: TeaserData[] = [
        {
            image: "teaser/home_teaser_children.jpeg",
            title: "Segeln für Kids",
            content: "Ob Landratte oder erfahrener Jungmatrose: Kids und Jugendliche mit Lust aufs Segeln können bei uns Erfahrungen sammeln und in See stechen.",
            actionTitle: "Zum Kindersegeln",
            action: () => {
                navigate(ROUTES.CHILD_SAILING_COURSES);
            }
        },
        {
            image: "teaser/home_teaser_adult.jpeg",
            title: "Unter Segeln und Motor",
            content: "Unsere günstigen Kurse bereiten fundiert auf die Prüfungen zum Sportbootführerschein Binnen oder See vor.",
            actionTitle: "Zu den Kursen",
            action: () => {
                navigate(ROUTES.ADULT_SAILING_COURSES);
            }
        },
        {
            image: "teaser/home_teaser_radio.jpeg",
            imagePosition: {positionX: "-60px"},
            title: "Ausbildung fürs SRC und UBI",
            content: "In entspanntem Rahmen und kleinen Gruppen gibt es für Mitglieder das erforderliche Know-how zum Erwerb der Sprechfunkzeugnisse.",
            actionTitle: "Gleich anmelden",
            action: () => {
                navigate(ROUTES.RADIO_COURSES);
            }
        },
    ];

    return (
        <div className={classes.homeOfferingContainer}>
            <Typography className={classes.homeOfferingHeadline}
                        variant={"h1"}
                        component={"h1"}>Unsere Kurse und Freizeitangebote</Typography>
            <TeaserCarousel data={teaserData}/>
        </div>
    );
}
