import React, {PropsWithChildren, useContext, useState} from "react";
import IBoat, {IBoatswain, locationToText} from "../../../model/boat/IBoat";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import Icon from "@mui/material/Icon";
import * as ROUTES from "../../../constants/routes";
import ContentButton from "../../../components/ContentButton";
import {useNavigate} from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material";
import DialogButton from "../../../components/DialogButton";
import BoatswainContact from "../../../components/contact/BoatswainContact";
import {BoatServiceApi, BoatswainSort} from "../../../api/BoatServiceApi";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import {BackgroundTextField} from "../../../components/StyledComponents";

const useStyles = makeStyles((theme: Theme) => createStyles({
    boatDetailTitle: {
        margin: "20px auto 15px 20px",
        textTransform: "uppercase",
        fontSize: "28px",
        lineHeight: "33px",
        color: "#023553",
        [theme.breakpoints.down(850)]: {
            marginTop: "82px"
        },
        [theme.breakpoints.down(415)]: {
            "&.boatswain": {
                marginTop: "142px"
            }
        }
    },
    boatBookingButton: {
        width: "170px",
        margin: "0 auto 20px 20px",
        [theme.breakpoints.down(850)]: {
            margin: "20px 20px 0 20px"
        },
        [theme.breakpoints.down(415)]: {
            width: "calc(100% - 40px)",
        }
    },
    boatBrokenMessage: {
        color: "#02A1A0",
        fontSize: "16px",
        width: "170px",
        margin: "0 auto 20px 20px",
        lineHeight: "21px",
        [theme.breakpoints.down(850)]: {
            margin: "20px 20px 0 20px"
        },
        [theme.breakpoints.down(415)]: {
            width: "calc(100% - 40px)",
        }
    },
    boatBookingButtonIcon: {
        fontSize: "14px !important",
        marginLeft: "5px"
    },
    boatDetailRow: {
        margin: "auto auto 8px 20px",
        lineHeight: "24px",
        color: "#023553"
    },
    boatDetailRowTitle: {
        margin: "20px auto 8px 20px",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#023553",
        textTransform: "uppercase"
    },
    boatDetailIcon: {
        fontSize: "16px !important",
        lineHeight: "20px !important"
    },
    boatDetailWaves: {
        lineHeight: "0",
        margin: "16px auto 0 20px",
    },
    boatDetailTechnicalData: {
        display: "flex",
        flexDirection: "row"
    },
    boatDetailTechnicalDataColumn: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        [theme.breakpoints.down(850)]: {
            width: "100%"
        }
    },
    boatDetailButtonContainer: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(850)]: {
            width: "100%",
            position: "absolute",
            top: "0"
        },
        [theme.breakpoints.down(415)]: {
            flexDirection: "column"
        }
    }
}));

interface BoatDetailProps extends PropsWithChildren<any> {
    boat: IBoat;
    userIsBoatswain: boolean;

    onLoading(loading: boolean): void;
}

export default function BoatDetails(props: BoatDetailProps) {
    const {boat, userIsBoatswain, onLoading} = props;

    const [broken, setBroken] = useState<boolean>(boat.outOfOrder ? boat.outOfOrder.broken : false);
    const [brokenMessage, setBrokenMessage] = useState<string>(boat.outOfOrder && boat.outOfOrder.brokenMessage ? boat.outOfOrder.brokenMessage : "");

    const {getToken} = useContext(AuthServiceContext);

    const classes = useStyles();
    const navigate = useNavigate();
    const theme: Theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down(850));

    const handleOutOfOrder = (currentBroken: boolean) => {
        onLoading(true);
        const outOfOrder = {broken: currentBroken, brokenMessage: currentBroken ? brokenMessage : undefined}
        BoatServiceApi.updateBoat(boat.number, outOfOrder, getToken())
            .then((updatedBoat: IBoat) => {
                setBroken(updatedBoat.outOfOrder ? updatedBoat.outOfOrder.broken : false);
                setBrokenMessage(updatedBoat.outOfOrder && updatedBoat.outOfOrder.brokenMessage ? updatedBoat.outOfOrder.brokenMessage : "");
            })
            .catch((error) => console.error("unexpected error: " + error.message))
            .finally(() => onLoading(false));
    }

    return (
        <div>
            <div className={`${classes.boatDetailTitle}${userIsBoatswain ? " boatswain" : ""}`}>{boat.name}</div>
            <div className={classes.boatDetailButtonContainer}>
                {!broken && (
                    <ContentButton className={classes.boatBookingButton}
                                   onClick={() => {
                                       navigate(ROUTES.BOOKING + "?selectedBoat=" + boat.number);
                                   }}>
                        Boot Buchen<Icon className={classes.boatBookingButtonIcon}>arrow_forward</Icon>
                    </ContentButton>)}
                {broken && (<div className={classes.boatBrokenMessage}>Das Boot ist<br/>derzeit gesperrt!</div>)}
                {userIsBoatswain && (
                    <DialogButton className={classes.boatBookingButton}
                                  label={broken ? "Entsperren" : "Jetzt sperren"}
                                  variant="primary"
                                  actionLabel={broken ? "Entsperren" : "Sperren"}
                                  title={`Soll das Boot wirklich ${broken ? "ent" : "ge"}sperrt werden?`}
                                  details={`${broken ? "Das Boot wurde aus folgendem Grund gesperrt:"
                                      : "Du musst bitte noch einen Grund angeben."}`}
                                  onActionClick={() => {
                                      handleOutOfOrder(!broken);
                                  }}
                                  onValidateAction={() => {
                                      return broken || (!broken && brokenMessage.length > 0);
                                  }}>
                        <>
                            {!broken && (
                                <BackgroundTextField variant="standard"
                                                     style={{width: "100%"}}
                                                     required={true}
                                                     label="Grund für die Sperrung des Bootes"
                                                     value={brokenMessage}
                                                     placeholder="Dein Grund für die Sperrung des Bootes"
                                                     onChange={(event: any) => {
                                                         setBrokenMessage(event.target.value)
                                                     }}/>)}
                            {broken && (<p>
                                "{brokenMessage}"
                            </p>)}
                            <p style={{fontSize: "14px"}}>
                                <span style={{textDecoration: "underline"}}>Hinweis:</span>
                                <br/>Alle Mitglieder mit einer Buchung in der Zukunft werden
                                über diese Änderung informiert.
                            </p>
                        </>
                    </DialogButton>
                )}
            </div>
            <div className={classes.boatDetailRow}>
                <Icon className={classes.boatDetailIcon}>sailing</Icon>Typ: {boat.type}
            </div>
            <div className={classes.boatDetailRow}>
                <Icon className={classes.boatDetailIcon}>person_pin_circle</Icon>Max.
                Personenzahl: {boat.maxPerson ? boat.maxPerson : "Keine Angabe"}
            </div>
            <div className={classes.boatDetailRow}>
                <Icon className={classes.boatDetailIcon}>place</Icon>Liegeplatz: {locationToText(boat.location)}
            </div>
            <img className={classes.boatDetailWaves} src="../../../static/images/wave_blue.svg" alt="small blue wave"/>
            <div className={classes.boatDetailRowTitle}>Besonderheiten</div>
            <div className={classes.boatDetailRow}>{boat.specifics ? boat.specifics : "Keine Besonderheiten"}</div>
            <img className={classes.boatDetailWaves} src="../../../static/images/wave_blue.svg" alt="small blue wave"/>
            <div className={classes.boatDetailRowTitle}>Bootsleute</div>
            <div className={classes.boatDetailRow}>
                {boat.boatswains.sort(BoatswainSort).map((boatswain: IBoatswain, index: number) => (
                    <BoatswainContact key={index} boatswain={boatswain}/>
                ))}
                {boat.boatswains.length === 0 && (
                    <div>Keine Bootsleute</div>
                )}
            </div>
            <div className={classes.boatDetailRowTitle}>Technische Daten</div>
            <div className={classes.boatDetailTechnicalData}>
                <div className={classes.boatDetailTechnicalDataColumn}>
                    <div
                        className={classes.boatDetailRow}>Länge: {boat.technicalData ? boat.technicalData.length : "-"}</div>
                    <div
                        className={classes.boatDetailRow}>Breite: {boat.technicalData ? boat.technicalData.width : "-"}</div>
                    <div
                        className={classes.boatDetailRow}>Masthöhe: {boat.technicalData ? boat.technicalData.mastAltitude : "-"}</div>
                    {matchesMobile && (<>
                        <div
                            className={classes.boatDetailRow}>Tiefgang: {boat.technicalData ? boat.technicalData.draft : "-"}</div>
                        <div
                            className={classes.boatDetailRow}>Gewicht: {boat.technicalData ? boat.technicalData.weight : "-"}</div>
                    </>)}
                </div>
                {!matchesMobile && (<>
                    <div className={classes.boatDetailTechnicalDataColumn}>
                        <div
                            className={classes.boatDetailRow}>Tiefgang: {boat.technicalData ? boat.technicalData.draft : "-"}</div>
                        <div
                            className={classes.boatDetailRow}>Gewicht: {boat.technicalData ? boat.technicalData.weight : "-"}</div>
                        <div className={classes.boatDetailRow}>&nbsp;</div>
                    </div>
                </>)}
            </div>
        </div>
    );
}
