export enum MessageType {
    NONE = "NONE",
    MESSAGE = "MESSAGE",
    NEWSLETTER = "NEWSLETTER"
}

export function messageTypeToText(type: MessageType) {
    switch (type) {
        case MessageType.NEWSLETTER:
            return "Newsletter";
        case MessageType.MESSAGE:
            return "Nachricht";
        case MessageType.NONE:
            return "Keiner"
        default:
            return "";
    }
}

export enum MessagePriority {
    HERO = "HERO",
    NONE = "NONE"
}

export function messagePriorityToText(priority: MessagePriority) {
    switch (priority) {
        case MessagePriority.HERO:
            return "Hervorgehoben";
        case MessagePriority.NONE:
            return "Keine"
        default:
            return "";
    }
}

export default interface IMessage {
    number: string;
    type: MessageType;
    title: string;
    content: string;
    author: string;
    priority: MessagePriority;
    date: string;
}
