import React, {CSSProperties, PropsWithChildren} from "react";

interface SiteImageProps extends PropsWithChildren<any> {
    image: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
    className?: string;
}

export default function SiteImage(props: SiteImageProps) {

    const { image, width, height, style, className } = props;

    const imageStyle = {
        margin: "auto",
        width: `${width ? width : "48px"}`,
        height: `${height ? height : "48px"}`,
        backgroundImage: `url(/static/images/${image})`,
        backgroundSize: "cover",
        ...style
    }

    return (
        <div style={imageStyle} className={className} />
    );
}
