import {withStyles} from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import {styled} from "@mui/material/styles";

export const FilterFormControl = withStyles({
    root: {
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
            borderRadius: "4px",
        },
        "& .MuiFilledInput-root .MuiSelect-select:focus": {
            backgroundColor: "transparent",
            borderRadius: "4px",
        },
        "& .MuiFilledInput-root .MuiFilledInput-input": {
            color: "#023553"
        },
        "& .MuiFilledInput-underline:before": {
            content: "unset"
        },
        "& .MuiFilledInput-underline:after": {
            content: "unset"
        }
    },
})(FormControl);

export const BackgroundTextField = withStyles({
    root: {
        marginBottom: "20px",
        "& .MuiOutlinedInput-root": {
            "& input": {
                backgroundColor: "#ffffff",
                borderRadius: "4px"
            },
        },
    },
})(TextField);

export const BackgroundSelect = styled(Select)(() => ({
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    "&:focus": {
        backgroundColor: "#ffffff !important"
    }
}));
