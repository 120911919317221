import React from "react";
import FooterHeadline from "./footer/FooterHeadline";
import FooterLinks from "./footer/FooterLinks";

export default function Footer() {

    return (
        <footer className="footerPlaceholder">
            <FooterHeadline/>
            <FooterLinks/>
        </footer>
    );
}