import React, {PropsWithChildren, useState} from "react";
import Editor from "../Editor";
import {createStyles, makeStyles} from "@mui/styles";
import IChapter from "../../model/boat/IChapter";
import EditableChapterHeader from "./EditableChapterHeader";

const useStyles = makeStyles(() => createStyles({
    chapterContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        boxSizing: "border-box",
        padding: "5px 28px",
    },
    chapterEditorContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        minHeight: "49px",
        boxSizing: "border-box",
        border: "1px dashed transparent",
        padding: "13px 15px 0 15px",
    },
    chapterContainerEditable: {
        borderColor: "lightgrey"
    },
    chapterContainerEnabled: {
        borderColor: "rgb(107,133,165)"
    }
}));

interface EditableChapterProps extends PropsWithChildren<any> {
    chapter: IChapter;
    editMode: boolean;

    onSaveChapter(chapter: IChapter): void;

    onRemoveChapter(chapter: IChapter): void;
}

export default function EditableChapter(props: EditableChapterProps) {
    const {chapter, editMode, onSaveChapter, onRemoveChapter} = props;

    const [content, setContent] = useState(chapter.content);
    const [enable, setEnable] = useState(false);
    const [hovered, setHovered] = useState(false);

    const classes = useStyles();

    const handleSaveChapter = () => {
        onSaveChapter({content: content, type: chapter.type, position: chapter.position});
        setEnable(false);
    }

    const handleRemoveChapter = () => {
        onRemoveChapter(chapter);
    }

    const handleMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    const handleContentChange = (content: string) => {
        setContent(content);
    }

    const handleToggleEnable = () => {
        setEnable(!enable);
    }

    return (
        <section id={chapter.type}>
            <div className={classes.chapterContainer}>
                <div className={`${classes.chapterEditorContainer}${editMode ? " " +
                    (enable ? classes.chapterContainerEnabled : classes.chapterContainerEditable) : ""}`}
                     onMouseEnter={handleMouseEnter}
                     onMouseLeave={handleMouseLeave}>
                    {editMode && (
                        <EditableChapterHeader position={chapter.position}
                                               label={chapter.type}
                                               hovered={hovered}
                                               editMode={enable}
                                               onToggleEditMode={handleToggleEnable}
                                               onSaveChapter={handleSaveChapter}
                                               onRemoveChapter={handleRemoveChapter}/>
                    )}
                    <Editor editMode={enable}
                            content={content}
                            onContentChange={handleContentChange}
                    />
                </div>
            </div>
        </section>
    );
}
