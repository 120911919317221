import React, {CSSProperties, PropsWithChildren, useContext, useEffect, useState} from "react";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {IContact} from "../../model/member/IContact";
import {ContactServiceApi} from "../../api/MemberServiceApi";
import {IBoatswain} from "../../model/boat/IBoat";

const useStyles = makeStyles((theme: Theme) => createStyles({
    contact: {
        margin: "auto 0",
        [theme.breakpoints.down(950)]: {
            marginTop: "0"
        },
        "&.currentUser": {
            [theme.breakpoints.down(950)]: {
                marginTop: "auto"
            }
        }
    },
    contactUserName: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px"
    },
    contactDetailsContainer: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column"
        }
    },
    contactDetails: {
        fontWeight: 400,
        fontFamily: "Rubik",
        fontSize: "14px",
        lineHeight: "18px",
        display: "flex",
        flexDirection: "row",
        "&.material-icons": {
            lineHeight: "18px",
            fontSize: "14px",
            height: "18px",
            width: "18px",
            fontWeight: 400
        },
        "&.last": {
            marginLeft: "10px",
            [theme.breakpoints.down(950)]: {
                marginLeft: "0"
            }
        }
    }
}));

interface BoatswainContactProps extends PropsWithChildren<any> {
    boatswain: IBoatswain,
    style?: CSSProperties,
}

export default function BoatswainContact(props: BoatswainContactProps) {

    const {boatswain, style} = props;

    const [contact, setContact] = useState<IContact>()

    const {user, getToken} = useContext(AuthServiceContext);

    const classes = useStyles();

    useEffect(() => {
        ContactServiceApi.getContact(boatswain.identifier, getToken())
            .then((contact: IContact) => {
                setContact(contact);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            });
    }, [boatswain, getToken]);

    const handleIsCurrentUser = (): boolean => {
        return user.userId === (!!contact ? contact.identifier : "");
    }

    return (
        <div className={`${classes.contact}${handleIsCurrentUser() ? " currentUser" : ""}`}
             style={style}>
            {contact && (<>
                {boatswain.position}. Bootsmann: {contact.firstName + " " + contact.lastName}
                {handleIsCurrentUser() ? " (Du selbst)" : ""}
            </>)}
        </div>
    );
}
