import React, {CSSProperties, PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Icon, IconButton, Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    weekSelector: {
        width: "40px",
        height: "40px",
        padding: "12px",
        margin: "auto 0",
        [theme.breakpoints.down(950)]: {
            width: "35px",
            height: "35px",
            padding: "6px"
        },
        [theme.breakpoints.down(415)]: {
            width: "30px",
            height: "30px",
            padding: "0"
        }
    },
    weekSelectorIcon: {
        "&.material-icons": {
            fontSize: "16px"
        }
    }
}));

interface WeekSelectorProps extends PropsWithChildren<any> {
    style?: CSSProperties;
    iconStyle?: CSSProperties;
    type: "left" | "right";
    disabled?: boolean;

    onClick(): void;
}

export default function WeekSelector(props: WeekSelectorProps) {

    const {style, iconStyle, type, disabled, onClick} = props;

    const classes = useStyles();

    return (
        <IconButton className={classes.weekSelector}
                    style={style}
                    disabled={disabled}
                    onClick={onClick}>
            <Icon className={classes.weekSelectorIcon}
                  style={{...iconStyle,
                      marginLeft: `${type === "left" ? "6px" : "0"}`
                  }}>
                {type === "left" ? "arrow_back_ios" : type === "right" ? "arrow_forward_ios" : ""}
            </Icon>
        </IconButton>
    );
}