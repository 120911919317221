import React, {useContext, useState} from "react";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import {DropdownMenu, DropdownMenuItem} from "../../../components/menu/DropdownMenu";
import {DrawerMenu, DrawerMenuItem} from "../../../components/menu/DrawerMenu";
import {MenuTarget} from "./MenuTarget";
import * as ROUTES from "../../../constants/routes";
import '../HeaderMenu.css';

export const entries: MenuTarget[] = [
    {target: ROUTES.ASSOCIATION_WORKING, title: "Vereinsarbeit"},
    {target: ROUTES.NEWSLETTER, title: "Newsletter (Archiv)"},
    {target: ROUTES.MEMBERS, title: "Mitglieder und Ehrenamtliche"},
    {target: ROUTES.CLUB_RULES, title: "Vereinssatzung"},
    {target: ROUTES.TRAILER_USAGES, title: "Trailernutzung"},
    {target: ROUTES.COMBINATION_LOCKS, title: "Zahlenschlösser"},
    {target: ROUTES.INSURANCE_COVER, title: "Versicherungsschutz"}
];

export function SailingClubMenu() {
    const {user} = useContext(AuthServiceContext);
    const [open, setOpen] = useState(false);

    return (
        <>
            {user.authenticated && (
                <DropdownMenu title="Segelclub" state={open} className={"menuLink"} onStateChange={setOpen}>
                    {entries.map((entry, index) => (
                        <DropdownMenuItem key={index}
                                          target={entry.target}
                                          title={entry.title}
                                          className="subMenuLink"
                                          onStateChange={setOpen}/>
                    ))}
                </DropdownMenu>
            )}
        </>
    );
}

export function SailingClubDrawerMenu() {
    const {user} = useContext(AuthServiceContext);

    return (
        <>
            {user.authenticated && (
                <DrawerMenu title="Segelclub" divider={true}>
                    {entries.map((entry, index) => (
                        <DrawerMenuItem key={index}
                                        className="subMenuLink"
                                        target={entry.target}
                                        title={entry.title}/>
                    ))}
                </DrawerMenu>
            )}
        </>
    );
}
