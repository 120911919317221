import {Gender, Membership} from "./IMember";
import {handleDateToIsoString} from "../DateHandler";

export default class RegistrationData {
    private readonly _username: string;
    private readonly _membership: Membership;
    private readonly _mailAddress: string;
    private readonly _courseMember: boolean;
    private readonly _firstName: string;
    private readonly _lastName: string;
    private readonly _gender: Gender;
    private readonly _dateOfBirth: Date | null;
    private readonly _streetLine: string;
    private readonly _zipCode: string;
    private readonly _city: string;
    private readonly _phoneNumber: string;
    private readonly _mobileNumber: string;
    private readonly _accountHolder: string;
    private readonly _iban: string;
    private _bic: string | undefined;
    private readonly _bankName: string;


    constructor(username: string, membership: Membership, mailAddress: string, courseMember: boolean, firstName: string, lastName: string, gender: Gender, dateOfBirth: Date | null, streetLine: string, zipCode: string, city: string, phoneNumber: string, mobileNumber: string, accountHolder: string, iban: string, bankName: string) {
        this._username = username;
        this._membership = membership;
        this._mailAddress = mailAddress;
        this._courseMember = courseMember;
        this._firstName = firstName;
        this._lastName = lastName;
        this._gender = gender;
        this._dateOfBirth = dateOfBirth;
        this._streetLine = streetLine;
        this._zipCode = zipCode;
        this._city = city;
        this._phoneNumber = phoneNumber;
        this._mobileNumber = mobileNumber;
        this._accountHolder = accountHolder;
        this._iban = iban;
        this._bankName = bankName;
    }


    get username(): string {
        return this._username;
    }

    get membership(): Membership {
        return this._membership;
    }

    get mailAddress(): string {
        return this._mailAddress;
    }

    get courseMember(): boolean {
        return this._courseMember;
    }

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get gender(): Gender {
        return this._gender;
    }

    get dateOfBirth(): Date | null {
        return this._dateOfBirth;
    }

    get streetLine(): string {
        return this._streetLine;
    }

    get zipCode(): string {
        return this._zipCode;
    }

    get city(): string {
        return this._city;
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }

    get mobileNumber(): string {
        return this._mobileNumber;
    }

    get accountHolder(): string {
        return this._accountHolder;
    }

    get iban(): string {
        return this._iban;
    }

    get bic(): string | undefined {
        return this._bic;
    }

    get bankName(): string {
        return this._bankName;
    }

    set bic(value: string | undefined) {
        this._bic = value;
    }

    toJSON() {
        return {
            username: this.username,
            membership: this.membership,
            mailAddress: this.mailAddress,
            courseMember: this.courseMember,
            firstName: this.firstName,
            lastName: this.lastName,
            gender: this.gender,
            dateOfBirth: handleDateToIsoString(this.dateOfBirth),
            streetLine: this.streetLine,
            zipCode: this.zipCode,
            city: this.city,
            phoneNumber: this.phoneNumber,
            mobileNumber: this.mobileNumber,
            accountHolder: this.accountHolder,
            iban: this.iban,
            bic: this.bic,
            bankName: this.bankName
        };
    }
}
