import {IContact} from "./IContact";
import {UserGroup} from "../IUser";

export class ClubMember implements IContact {
    readonly identifier: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly mailAddress: string;
    readonly mobileNumber: string;
    readonly phoneNumber: string;
    readonly groups: UserGroup[];

    constructor(contact: IContact) {
        this.identifier = contact.identifier
        this.firstName = contact.firstName;
        this.lastName = contact.lastName;
        this.mailAddress = contact.mailAddress;
        this.mobileNumber = contact.mobileNumber;
        this.phoneNumber = contact.phoneNumber;
        this.groups = [];
    }

    public getName(): string {
        return this.firstName + " " + this.lastName;
    }

    public getPhone(): string {
        const phones: string[] = [];
        if (this.mobileNumber && this.mobileNumber.length > 0) {
            phones.push(this.mobileNumber);
        }
        if (this.phoneNumber && this.phoneNumber.length > 0) {
            phones.push(this.phoneNumber);
        }
        return phones.join(", ");
    }

    public addGroup(group: UserGroup): void {
        this.groups.push(group);
    }
}
