import React, {CSSProperties, PropsWithChildren} from "react";
import {BackgroundSelect} from "./StyledComponents";
import InputLabel from "@mui/material/InputLabel";
import {FormHelperText} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles(() => createStyles({
    nonValueLabel: {
        "& .MuiSelect-select": {
            color: "#808080"
        }
    }
}));

interface BackgroundDropdownProps extends PropsWithChildren<any> {
    id: string;
    label: string;
    value: any;
    defaultValue?: any;
    nonValue: any;
    errorMessage: string;
    validated: boolean;
    required: boolean;
    style?: CSSProperties;
    useValue?: boolean;

    onChange(newValue: any): void;
}

export default function BackgroundDropdown(props: BackgroundDropdownProps) {
    const {id, label, value, defaultValue, nonValue, errorMessage, validated, required, style, useValue, onChange, children} = props;

    const innerNonValue = "";

    const classes = useStyles();

    const handleOnChange = (event: SelectChangeEvent<any>): void => {
        let value = event.target.value;
        if (value === innerNonValue) {
            value = nonValue;
        }
        onChange(value);
    }

    return (
        <FormControl variant="outlined" style={style}>
            <InputLabel id={id}
                        required={required}
                        error={validated && value === nonValue}>{label}</InputLabel>
            <BackgroundSelect labelId={id}
                              label={label}
                              className={value === nonValue ? classes.nonValueLabel : ""}
                              error={validated && (value === nonValue)}
                              value={!!useValue && (value !== nonValue) ? value : undefined}
                              defaultValue={!useValue ? (!!defaultValue ? defaultValue : innerNonValue) : undefined}
                              onChange={handleOnChange}>
                <MenuItem value={innerNonValue} disabled>Keine Auswahl getroffen</MenuItem>
                {children}
            </BackgroundSelect>
            <FormHelperText error={validated && value === nonValue}>
                {validated && value === nonValue ? errorMessage : null}
            </FormHelperText>
        </FormControl>
    );

}
