import React, {PropsWithChildren, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import Message from "./Message";
import {MessagePriority, MessageType} from "../../model/news/IMessage";
import MessageListFilter, {MessageFilter} from "./MessageListFilter";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    messagesContainer: {
        margin: "auto",
        maxWidth: "1280px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 43px",
        [theme.breakpoints.down(1200)]: {
            width: "100%",
            padding: "0"
        },
        [theme.breakpoints.down(950)]: {
        }
    },
    noMessages: {
        textAlign: "center",
        color: "#023553",
    }
}));

export type MessageData = {
    number: number;
    title: string;
    content: string;
    date: string;
    author: string;
    priority: MessagePriority;
    type: MessageType;
}

interface MessageListProps extends PropsWithChildren<any> {
    messages: MessageData[];
    extendedFilter?: boolean;
    showType?: boolean;
}

export default function MessageList(props: MessageListProps) {
    const [filter, setFilter] = useState<MessageFilter>();
    const {messages, extendedFilter, showType} = props;

    const classes = useStyles();

    // message states
    const [authors, setAuthors] = useState<string[]>([]);
    const [expanded, setExpanded] = useState<number>();
    const [clamped] = useState<number[]>([]);

    useEffect(() => {
        const authors: string[] = [];
        messages.forEach((message: MessageData) => {
            if (!authors.includes(message.author)) {
                authors.push(message.author);
                setAuthors(authors);
            }
        });
    }, [messages]);

    const handleUpdateFilter = (filter: MessageFilter) => {
        setFilter(filter);
    }

    const handleFilterMessage = (message: MessageData) => {
        return (filter?.messageType === undefined || message.type === filter.messageType)
            && (filter?.messageAuthor === undefined || message.author === filter.messageAuthor)
            && (filter?.messagePriority === undefined || message.priority === filter.messagePriority)
            && (filter?.messageContent === undefined ||
                (message.title.toLowerCase().includes(filter.messageContent.toLowerCase())
                    || message.content.toLowerCase().includes(filter.messageContent.toLowerCase())));
    }

    const handleUpdateExpanded = (id: string, expanded: boolean) => {
        setExpanded(expanded ? Number.parseInt(id) : undefined);
    }

    const handleMakeAsClamped = (number: number) => {
        clamped.push(number);
    }

    const filteredMessages = messages.filter(message => handleFilterMessage(message));

    return (
        <div className={classes.messagesContainer}>
            <MessageListFilter authors={authors}
                               extendedFilter={extendedFilter}
                               onUpdateFilter={handleUpdateFilter}/>
            {filteredMessages.map((message: MessageData, index: number) => (
                <Message key={index}
                         message={message}
                         hero={message.priority === MessagePriority.HERO}
                         expanded={message.number === expanded}
                         clamped={clamped.includes(message.number)}
                         showType={showType}
                         onMakeAsClamped={handleMakeAsClamped}
                         onClickExpand={handleUpdateExpanded}/>
            ))}
            {filteredMessages.length === 0 && (<div className={classes.noMessages}>
                {`Zu dieser Suchanfrage sind keine ${showType ?
                    "Newsletter oder Nachrichten" : "Nachrichten"} vorhanden.`}</div>)}
        </div>
    );
}
