import React from "react";
import {Theme} from "@mui/material";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import EditableSite from "../../components/site/EditableSite";
import * as TYPES from "../../constants/siteTypes";
import {BeforeSectionExtension} from "../../components/site/EditableSection";
import SiteImage from "../../components/site/SiteImage";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    insuranceCoverContainer: {
        paddingTop: "20px"
    }
}));

export default function InsuranceCover() {

    const classes = useStyles();

    return (
      <>
          <PageHeader headerImage="header/insurance-cover.jpg"
                      headerImagePosition={{ mirrorX: true, positionY: "45%" }}>
              Versicherungsschutz im Verein
          </PageHeader>
          <PageContent className={classes.insuranceCoverContainer}>
              <EditableSite siteType={TYPES.INSURANCE_COVER}>
                  <BeforeSectionExtension position={1}>
                      <SiteImage image="site-icon/health_and_safety_blue.svg"/>
                  </BeforeSectionExtension>
              </EditableSite>
          </PageContent>
      </>
  );
}
