import React, {CSSProperties, PropsWithChildren} from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {createStyles, makeStyles} from "@mui/styles";
import {FilterFormControl} from "./StyledComponents";
import {Theme} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";

const useStyles = makeStyles((theme: Theme) => createStyles({
    filterDropdownInputLabel: {
        color: "#023553"
    },
    blueFilterDropdownInputLabel: {
        color: "#ffffff",
        "&.Mui-focused": {
            color: "#ffffff",
        }
    },
    filterDropdownInput: {
        width: "200px",
        backgroundColor: "#F6F7F9",
        borderRadius: "4px",
        [theme.breakpoints.down(950)]: {
            width: "100%"
        }
    },
    blueFilterDropdownInput: {
        width: "200px",
        backgroundColor: "#023553",
        color: "#ffffff",
        borderRadius: "4px",
        "& .MuiFilledInput-root .MuiFilledInput-input": {
            color: "#ffffff",
        },
        [theme.breakpoints.down(950)]: {
            width: "100%"
        }
    }
}));

interface FilterDropdownProps extends PropsWithChildren<any> {
    id: string;
    label: string;
    value?: any;
    defaultValue?: any;
    style?: CSSProperties;
    className?: string;
    labelStyle?: CSSProperties;
    labelClassName?: string;
    inputStyle?: CSSProperties;
    inputClassName?: string;

    onChange(event: SelectChangeEvent<any>, client: React.ReactNode): void;
}

export default function FilterDropdown(props: FilterDropdownProps) {
    const {
        id, label, defaultValue, style, className, labelStyle, labelClassName, onChange, children
    } = props;

    const classes = useStyles();

    return (
        <FilterFormControl variant="filled"
                           style={style}
                           className={`${classes.filterDropdownInput}${className ? ' ' + className : ''}`}>
            <InputLabel htmlFor={id}
                        style={labelStyle}
                        className={`${classes.filterDropdownInputLabel}${labelClassName ? ' ' + labelClassName : ''}`}>
                {label}
            </InputLabel>
            <Select id={id}
                    style={labelStyle}
                    defaultValue={defaultValue}
                    label={label}
                    inputProps={{
                        classes: {
                            focused: labelClassName,
                            input: labelClassName,
                            icon: labelClassName,
                        },
                    }}
                    onChange={onChange}>
                {children}
            </Select>
        </FilterFormControl>
    );

}

export function BlueFilterDropdown(props: FilterDropdownProps) {
    const {
        id, label, value, defaultValue, style, className, labelStyle, labelClassName, onChange, children
    } = props;

    const classes = useStyles();

    return (
        <FilterFormControl variant="filled"
                           style={style}
                           className={`${classes.blueFilterDropdownInput}${className ? ' ' + className : ''}`}>
            <InputLabel htmlFor={id}
                        style={labelStyle}
                        className={`${classes.blueFilterDropdownInputLabel}${labelClassName ? ' ' + labelClassName : ''}`}>
                {label}
            </InputLabel>
            <Select id={id}
                    style={labelStyle}
                    defaultValue={defaultValue}
                    value={value}
                    label={label}
                    inputProps={{
                        classes: {
                            focused: labelClassName,
                            input: classes.blueFilterDropdownInput,
                            icon: classes.blueFilterDropdownInputLabel,
                        },
                    }}
                    onChange={onChange}>
                {children}
            </Select>
        </FilterFormControl>
    );

}