import {handleResponse} from "./ServiceApi";
import IAssociationWorking from "../model/association_working/IAssociationWorking";
import IParticipant from "../model/association_working/IParticipant";

const {REACT_APP_ASSOCIATION_WORKING_SERVICE_URL} = window._env_;

const ASSOCIATION_WORKING_API =
    `${REACT_APP_ASSOCIATION_WORKING_SERVICE_URL}/associationworkingmanagement/api/association-workings`;

async function buildRequestOptions(method: string, authHeader?: string) {
    return {
        method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader ? (" Bearer " + authHeader) : ""
        }
    };
}

export const AssociationWorkingServiceApi = {
    getAssociationWorkings: async (token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(ASSOCIATION_WORKING_API, requestOptions).then(handleResponse);
    },
    addMemberToAssociationWorking: async (associationWorking: IAssociationWorking,
                                          participantIdentifier: IParticipant,
                                          token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(`${ASSOCIATION_WORKING_API}/${associationWorking.number}/participants`, {
            ...requestOptions,
            body: JSON.stringify(participantIdentifier)
        }).then(handleResponse);
    },
    removeMemberToAssociationWorking: async (associationWorking: IAssociationWorking,
                                             identifier: string,
                                             token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${ASSOCIATION_WORKING_API}/${associationWorking.number}/participants/${identifier}`,
            requestOptions)
            .then(handleResponse);
    }
}
