import ISection from "./ISection";

export enum AccessType {
    NONE = "NONE",
    MEMBER = "MEMBER"
}

export function accessTypeToText(accessType: AccessType) {
    switch (accessType) {
        case AccessType.MEMBER:
            return "Login vorausgesetzt";
        case AccessType.NONE:
            return "Keine Auswahl";
        default:
            return "";
    }
}

export default interface ISite {
    type: string;
    accessType: AccessType;
    sections: ISection[];
}
