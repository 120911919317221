import React, {useContext} from "react";
import {Navigate, Routes, Route} from "react-router"
import {AuthServiceContext} from "../provider/AuthServiceProvider";
import * as ROUTE_TARGET from "../constants/routes";
import Homepage from "./home/Home";
import NotFound from "./NotFound";
import Profile from "./profile/Profile";
import Membership from "./usco/Membership";
import ContactPersons from "./usco/ContactPersons";
import BoatsAndTerritories from "./usco/BoatsAndTerritories";
import Messages from "./usco/Messages";
import ChildSailingCourses from "./training/ChildSailingCourses";
import AdultSailingCourses from "./training/AdultSailingCourses";
import RadioCourses from "./training/RadioCourses";
import LeisurePrograms from "./training/LeisurePrograms";
import Booking from "./sailing/booking/Booking";
import Boats from "./sailing/boat/Boats";
import Boat from "./sailing/boat/Boat";
import Territories from "./sailing/Territories";
import AssociationWorkings from "./sailingclub/association_working/AssociationWorkings";
import Newsletter from "./sailingclub/Newsletter";
import Members from "./sailingclub/member/Members";
import Appointments from "./sailingclub/Appointments";
import ClubRules from "./sailingclub/ClubRules";
import TrailerUsages from "./sailingclub/TrailerUsages";
import CombinationLocks from "./sailingclub/CombinationLocks";
import InsuranceCover from "./sailingclub/InsuranceCover";
import Contacts from "./footer/Contacts";
import ServiceLinks from "./footer/ServiceLinks";
import BankDetails from "./footer/BankDetails";
import LegalNotice from "./footer/LegalNotice";
import DataProtection from "./footer/DataProtection";
import Register from "./usco/register/Register";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import deLocale from "date-fns/locale/de";
import RegisterVerifyMail from "./usco/register/RegisterVerifyMail";
import CourseRegister from "./training/CourseRegister";

export default function Content() {
    const {user} = useContext(AuthServiceContext);
    return (
        <div className="contentPlaceholder">
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                <Routes>
                    {/* USCO e.V. menu routes */}
                    <Route path={ROUTE_TARGET.MEMBERSHIP} element={<Membership/>}/>
                    <Route path={ROUTE_TARGET.CONTACT_PERSONS} element={<ContactPersons/>}/>
                    <Route path={ROUTE_TARGET.BOATS_AND_TERRITORIES} element={<BoatsAndTerritories/>}/>
                    <Route path={ROUTE_TARGET.MESSAGES} element={<Messages/>}/>
                    <Route path={ROUTE_TARGET.REGISTER} element={
                        !user.authenticated ? <Register/> : <Navigate replace to={ROUTE_TARGET.MEMBERSHIP}/>
                    }/>
                    <Route path={ROUTE_TARGET.REGISTER + "/verify/:verificationLink"} element={
                        !user.authenticated ? <RegisterVerifyMail/> : <Navigate replace to={ROUTE_TARGET.MEMBERSHIP}/>
                    }/>
                    {/* training menu routes */}
                    <Route path={ROUTE_TARGET.CHILD_SAILING_COURSES} element={<ChildSailingCourses/>}/>
                    <Route path={ROUTE_TARGET.ADULT_SAILING_COURSES} element={<AdultSailingCourses/>}/>
                    <Route path={ROUTE_TARGET.SAILING_COURSE_REGISTER} element={<CourseRegister/>}/>
                    <Route path={ROUTE_TARGET.RADIO_COURSES} element={<RadioCourses/>}/>
                    <Route path={ROUTE_TARGET.LEISURE_PROGRAMS} element={<LeisurePrograms/>}/>
                    {/* sailing menu routes */}
                    <Route path={ROUTE_TARGET.BOOKING} element={
                        user.authenticated ? <Booking/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    {/*<Route path={ROUTE_TARGET.BOATS} element={Boats}/>
                <Route path={ROUTE_TARGET.BOATS + "/:boatNumber"} element={(props: BoatDetailProps) => { return <Boat {...props}/>}/>*/}
                    <Route path={ROUTE_TARGET.BOATS} element={
                        user.authenticated ? <Boats/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.BOATS + "/:boatNumber"} element={
                        user.authenticated ? <Boat/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.TERRITORIES} element={
                        user.authenticated ? <Territories/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    {/* sailing club menu routes */}
                    <Route path={ROUTE_TARGET.ASSOCIATION_WORKING} element={
                        user.authenticated ? <AssociationWorkings/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.NEWSLETTER} element={
                        user.authenticated ? <Newsletter/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.MEMBERS} element={
                        user.authenticated ? <Members/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.APPOINTMENTS} element={
                        user.authenticated ? <Appointments/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.CLUB_RULES} element={
                        user.authenticated ? <ClubRules/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.TRAILER_USAGES} element={
                        user.authenticated ? <TrailerUsages/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.COMBINATION_LOCKS} element={
                        user.authenticated ? <CombinationLocks/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.INSURANCE_COVER} element={
                        user.authenticated ? <InsuranceCover/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    {/* login / user menu routes */}
                    <Route path={ROUTE_TARGET.PROFILE} element={
                        user.authenticated ? <Profile/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    {/* footer link routes */}
                    <Route path={ROUTE_TARGET.SERVICE_LINKS} element={<ServiceLinks/>}/>
                    <Route path={ROUTE_TARGET.CONTACTS} element={<Contacts/>}/>
                    <Route path={ROUTE_TARGET.BANK_DETAILS} element={<BankDetails/>}/>
                    <Route path={ROUTE_TARGET.LEGAL_NOTICE} element={<LegalNotice/>}/>
                    <Route path={ROUTE_TARGET.DATA_PROTECTION} element={<DataProtection/>}/>
                    {/* default routes */}
                    <Route path={ROUTE_TARGET.HOME} element={<Homepage/>}/>
                    {/* default error routes */}
                    <Route element={<NotFound/>}/>
                </Routes>
            </LocalizationProvider>
        </div>
    );
}
