import React, {useContext, useEffect, useState} from "react";
import Text from "../../components/Text";
import {BackgroundTextField} from "../../components/StyledComponents";
import IMember, {Membership, membershipToText} from "../../model/member/IMember";
import ContentButton from "../../components/ContentButton";
import ContentLoadingButton from "../../components/ContentLoadingButton";
import {MemberServiceApi} from "../../api/MemberServiceApi";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {ApiError} from "../../model/Errors";
import {ProfileSectionProps} from "./Profile";

export default function ProfileAccount(props: ProfileSectionProps) {
    const {onMemberChange, member, classes} = props;
    const {getToken} = useContext(AuthServiceContext);

    const [edit, setEdit] = useState<boolean>();
    const [validated, setValidated] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);

    const [uscoId, setUscoId] = useState<string>("");
    const [memberAccountUsername, setMemberAccountUsername] = useState<string>("");
    const [memberAccountMembership, setMemberAccountMembership] = useState<Membership>(Membership.NONE);
    const [memberAccountMailAddress, setMemberAccountMailAddress] = useState<string>("");

    // membership section
    const [username, setUsername] = useState<string>(memberAccountUsername);
    const [membership, setMembership] = useState<Membership>(memberAccountMembership);
    const [mailAddress, setMailAddress] = useState<string>(memberAccountMailAddress);

    useEffect(() => {
        if (!!member) {
            setUscoId(member.identifier);
            setMemberAccountUsername(member.username);
            setUsername(member.username);
            setMemberAccountMembership(member.membership);
            setMembership(member.membership);
            setMemberAccountMailAddress(member.mailAddress);
            setMailAddress(member.mailAddress);
        }
    }, [member]);

    const handleSave = () => {
        if (handleValidation()) {
            setValidated(false);
            if (handleUpdated() && !!member) {
                setPending(true);
                MemberServiceApi.updateMember(createMemberToUpdate(member), getToken()).then(response => {
                    onMemberChange(response);
                    setEdit(false);
                }).catch((error: ApiError) => {
                    if (error.status !== 404) {
                        console.error("unexpected error: " + error.message);
                    }
                }).finally(() => {
                    setPending(false);
                });
            } else {
                setEdit(false);
            }
        }
    }

    const createMemberToUpdate = (member: IMember): IMember => {
        return {
            identifier: uscoId,
            username: username,
            membership: membership,
            mailAddress: mailAddress,
            firstName: member.firstName,
            lastName: member.lastName,
            gender: member.gender,
            dateOfBirth: member.dateOfBirth,
            contact: member.contact,
            bankAccount: member.bankAccount,
            principleIdentifier: member.principleIdentifier
        }
    }

    const handleValidation = () => {
        setValidated(true);
        const valid = membership !== Membership.NONE && !!mailAddress
        if (valid) {
            console.info("account valid. Required fields are filled.");
        } else {
            console.info("account not valid. Required fields are not filled.");
        }
        return valid;
    }

    const handleUpdated = () => {
        const updated = memberAccountMembership !== membership
            || memberAccountUsername !== username
            || memberAccountMailAddress !== mailAddress;
        if (updated) {
            console.info("account updated.");
        } else {
            console.info("account not updated.");
        }
        return updated;
    }

    return (
        <div className={classes.profileSection}>
            <div className={classes.profileSectionHeader}>Konto</div>
            {!edit && (<div className={classes.profileSectionContent}>
                <Text label="USCO-ID" value={uscoId}/>
                <Text label="Nutzername" value={memberAccountUsername}/>
                <Text label="Art der Mitgliedschaft" value={membershipToText(memberAccountMembership)}/>
                <Text label="E-Mail-Adresse" value={memberAccountMailAddress}/>
            </div>)}
            {edit && (<div className={classes.profileSectionContent}>
                <Text label="USCO-ID" value={uscoId}/>
                <BackgroundTextField required
                                     error={validated && !username}
                                     helperText={validated && !username ? "Die Nutzername fehlt." : null}
                                     variant="outlined"
                                     label="Nutzername"
                                     value={memberAccountUsername}
                                     placeholder="Dein Nutzername"
                                     onChange={(event: any) => {
                                         setUsername(event.target.value)
                                     }}/>
                <Text label="Art der Mitgliedschaft" value={membershipToText(membership)}/>
                {/*<BackgroundDropdown id="profile-membership-label"
                                    label="Art der Mitgliedschaft"
                                    style={{marginBottom: "17px"}}
                                    value={membership}
                                    nonValue={Membership.NONE}
                                    errorMessage="Art der Mitgliedschaft nicht ausgewählt."
                                    validated={validated}
                                    required={true}
                                    onChange={(event: any) => {
                                        setMembership(event.target.value);
                                    }}>
                    <MenuItem value={Membership.NONE} disabled>{membershipToText(Membership.NONE)}</MenuItem>
                    <MenuItem value={Membership.SINGLE}>{membershipToText(Membership.SINGLE)}</MenuItem>
                    <MenuItem value={Membership.JUNIOR}>{membershipToText(Membership.JUNIOR)}</MenuItem>
                    <MenuItem value={Membership.STUDENT}>{membershipToText(Membership.STUDENT)}</MenuItem>
                    <MenuItem value={Membership.FAMILY}>{membershipToText(Membership.FAMILY)}</MenuItem>
                </BackgroundDropdown>*/}
                <BackgroundTextField required
                                     error={validated && !mailAddress}
                                     helperText={validated && !mailAddress ? "Die E-Mail-Adresse fehlt." : null}
                                     variant="outlined"
                                     label="E-Mail-Adresse"
                                     defaultValue={memberAccountMailAddress}
                                     placeholder="Deinen E-Mail-Adresse"
                                     type="email"
                                     onChange={(event: any) => {
                                         setMailAddress(event.target.value)
                                     }}/>
                {edit && (<div className={classes.profileSectionButtonContainer}>
                    <ContentButton variant="secondary"
                                   className={classes.profileSectionAbortButton}
                                   onClick={() => setEdit(false)}>Abbrechen</ContentButton>
                    <ContentLoadingButton className={classes.profileSectionSaveButton}
                                          pending={pending}
                                          onClick={handleSave}>Speichern</ContentLoadingButton>
                </div>)}
            </div>)}
            <div className={classes.profileSectionActions}>
                {/*{!edit && (<Button className={classes.profileSectionEditAction} onClick={() => setEdit(!edit)}>
                    <Icon style={{fontSize: "20px"}}>edit</Icon>
                </Button>)}*/}
            </div>
        </div>
    );

}
