import {handleResponse} from "./ServiceApi";
import IHandbook from "../model/boat/IHandbook";
import IChapter from "../model/boat/IChapter";
import IBooking from "../model/booking/IBooking";
import {IBoatswain, IOutOfOrder} from "../model/boat/IBoat";

const { REACT_APP_BOAT_SERVICE_URL } = window._env_;

const BOATS_API = `${REACT_APP_BOAT_SERVICE_URL}/boatmanagement/api/boats`;
const HANDBOOKS_API = `${REACT_APP_BOAT_SERVICE_URL}/boatmanagement/api/handbooks`;
const SLOTS_API = `${REACT_APP_BOAT_SERVICE_URL}/boatmanagement/api/slots`;

async function buildRequestOptions(method: string, authHeader?: string) {
    return {
        method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader ? (" Bearer " + authHeader) : ""
        }
    };
}

export const BoatServiceApi = {
    getBoats: async (token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${BOATS_API}`, requestOptions).then(handleResponse);
    },
    getBoat: async (number: string, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${BOATS_API}/${number}`, requestOptions).then(handleResponse);
    },
    updateBoat: async (number: string, outOfOrder: IOutOfOrder, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${BOATS_API}/${number}/states/default`, {
            ...requestOptions,
            body: JSON.stringify(outOfOrder)
        }).then(handleResponse);
    },
}

export const HandbookServiceApi = {
    createHandbook: async (handbook: IHandbook, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(HANDBOOKS_API, {
            ...requestOptions,
            body: JSON.stringify(handbook)
        }).then(handleResponse);
    },
    getHandbook: async (type: string, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${HANDBOOKS_API}/${type}`, requestOptions)
            .then(handleResponse);
    },
    createChapter: async (handbookType: string, chapter: IChapter, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(`${HANDBOOKS_API}/${handbookType}/chapters`, {
            ...requestOptions,
            body: JSON.stringify(chapter)
        }).then(handleResponse);
    },
    updateChapter: async (handbookType: string, chapter: IChapter, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${HANDBOOKS_API}/${handbookType}/chapters/${escape(chapter.type)}`, {
            ...requestOptions,
            body: JSON.stringify(chapter)
        }).then(handleResponse);
    },
    deleteChapter: async (handbookType: string, chapter: IChapter, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${HANDBOOKS_API}/${handbookType}/chapters/${escape(chapter.type)}`, requestOptions)
            .then(handleResponse);
    }
}

export const SlotServiceApi = {
    getFutureBookings: async (memberIdentifier: string, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${SLOTS_API}/?contact-identifier=${memberIdentifier}`, requestOptions).then(handleResponse);
    },
    getSlots: async (boatNumber: string, date: string, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${SLOTS_API}/${boatNumber}?date=${date}`, requestOptions).then(handleResponse);
    },
    createSlot: async (booking: IBooking, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(`${SLOTS_API}/${booking.boatNumber}`, {
            ...requestOptions,
            body: JSON.stringify(booking)
        }).then(handleResponse);
    },
    deleteSlot: async (booking: IBooking, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${SLOTS_API}/${booking.boatNumber}?start=${encodeURIComponent(booking.start)}&end=${encodeURIComponent(booking.end)}&contact-identifier=${booking.contactIdentifier}`, requestOptions)
            .then(handleResponse);
    },
    getSlotsAvailable: async (boatNumber: string, date: string, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${SLOTS_API}/${boatNumber}/availables?start-of-week=${date}`, requestOptions)
            .then(handleResponse);
    },
}

export const BoatswainSort = (a: IBoatswain, b: IBoatswain) => {
    if (a.position < b.position) {
        return -1;
    } else if (a.position > b.position) {
        return 1;
    }
    return 0;
}
