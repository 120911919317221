import React, {CSSProperties, PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {LoadingButton} from "@mui/lab";

const useStyles = makeStyles(() => createStyles({
    contentLoadingButton: {
        color: "#ffffff",
        backgroundColor: "#02A1A0",
        fontFamily: "Roboto Mono",
        fontSize: "14px",
        lineHeight: "28px",
        padding: "6px 8px",
        width: "100%",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#02A1A0",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.8)"
        },
        "&.Mui-disabled": {
            backgroundColor: "rgba(2,184,183,0.2)",
        }
    },
    contentLoadingButtonSecondary: {
        color: "#02A1A0",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#02A1A0",
        backgroundColor: "transparent",
        fontFamily: "Roboto Mono",
        fontSize: "14px",
        lineHeight: "28px",
        padding: "6px 8px",
        width: "100%",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.2)"
        }
    },
    contentButtonBlurContainer: {
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        margin: "auto",
        display: "flex"
    }
}));

interface ContentLoadingButtonProps extends PropsWithChildren<any> {
    onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void,

    pending: boolean,
    id?: string,
    variant?: "primary" | "secondary"
    style?: CSSProperties,
    className?: string,
    buttonStyle?: CSSProperties,
    buttonClassName?: string,
    disabled?: boolean
}

export default function ContentLoadingButton(props: ContentLoadingButtonProps) {
    const {onClick, pending, id, variant, style, className, buttonStyle, buttonClassName, disabled, children} = props;

    const classes = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick(event);
    }

    return (
        <div style={style}
             className={`${classes.contentButtonBlurContainer}${className ? " " + className : ""}`}>
            <LoadingButton id={id}
                           style={buttonStyle}
                           disabled={disabled}
                           className={`${variant === "secondary" ?
                               classes.contentLoadingButtonSecondary :
                               classes.contentLoadingButton}${buttonClassName ? " " + buttonClassName : ""}`}
                           onClick={handleClick}
                           loading={pending}>{children}</LoadingButton>
        </div>
    );
}