import {createStyles, makeStyles} from "@mui/styles";
import React, {CSSProperties, PropsWithChildren} from "react";
import {LinearProgress} from "@mui/material";

const useStyles = makeStyles((theme) => createStyles({
    pageContentContainer: {
        padding: "0"
    },
    pageContentLinearProgress: {
        marginTop: "-4px"
    }
}));

interface PageContentProps extends PropsWithChildren<any> {
    process?: boolean;
    className?: string;
    style?: CSSProperties;
}

export default function PageContent(props: PageContentProps) {
    const {process, className, style} = props;
    const classes = useStyles();

    return (
        <div className={`${classes.pageContentContainer}${className ? ' ' + className : ''}`} style={style}>
            {process && (<LinearProgress className={classes.pageContentLinearProgress}/>)}
            {props.children}
        </div>
    );
}
