import React, {useEffect, useState} from "react";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import MessageList, {MessageData} from "../../components/message/MessageList";
import IMessage from "../../model/news/IMessage";
import {MessageServiceApi, MessageSort} from "../../api/ContentServiceApi";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    messageContainer: {
        padding: "20px 0"
    }
}));

export default function Messages() {
    const [messages, setMessages] = useState<IMessage[]>([]);

    const classes = useStyles();

    useEffect(() => {
        MessageServiceApi.getMessages().then(response => {
            const messages: IMessage[] = response.sort(MessageSort);
            setMessages(messages);
        }).catch((error) => {
            console.error("unexpected error: " + error.message);
        });
    }, []);

    const messageData: MessageData[] = messages.map(message => (
        {
            number: Number.parseInt(message.number),
            title: message.title,
            content: message.content,
            date: message.date,
            author: message.author,
            priority: message.priority,
            type: message.type
        }
    ));

    return (
        <>
            <PageHeader headerImage="header/messages.jpg"
                        headerImagePosition={{ positionY: "25%"}}>
                Mitteilungen
            </PageHeader>
            <PageContent>
                <div className={classes.messageContainer}>
                    <MessageList messages={messageData}/>
                </div>
            </PageContent>
        </>
    );
}
