import React from 'react';
import './App.css';
import Header from "./page/Header";
import Footer from "./page/Footer";
import Content from "./page/Content";
import {BrowserRouter} from "react-router-dom";
import {StyledEngineProvider} from "@mui/material";

export default function App() {

    return (
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <Header/>
                <Content/>
                <Footer/>
            </StyledEngineProvider>
        </BrowserRouter>
    );
};
