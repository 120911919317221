import React, {CSSProperties, PropsWithChildren, ReactElement, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {styled} from "@mui/material/styles"
import ContentButton from "./ContentButton";

const DialogActionButton = styled(Button)(({theme}) => ({
    color: "#02A1A0",
}))

interface DialogButtonProps extends PropsWithChildren<any> {
    label: string | ReactElement,
    title: string,
    details?: string,
    disabled?: boolean,
    actionLabel: string,
    onActionClick(): void,
    id?: string,
    variant?: "primary" | "secondary"
    style?: CSSProperties,
    className?: string,
    buttonStyle?: CSSProperties,
    buttonClassName?: string,
    onValidateAction?(): boolean
}

function DialogButton(props: DialogButtonProps) {
    const {label, title, details, disabled, actionLabel, onActionClick, id, variant,
        style, className, buttonStyle, buttonClassName, onValidateAction, children} = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleAction = () => {
        onActionClick();
        handleClose();
    }

    return (
        <>
            <ContentButton id={id}
                           variant={variant}
                           style={style}
                           className={className}
                           buttonStyle={buttonStyle}
                           buttonClassName={buttonClassName}
                           disabled={disabled}
                           onClick={handleClickOpen}>{label}</ContentButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-button-title">
                <DialogTitle id="dialog-button-title">{title}</DialogTitle>
                <DialogContent style={{paddingBottom: `${children ? "0" : "20px"}`}}>
                    {details && (<DialogContentText style={{ color: "#000000"}}>{details}</DialogContentText>)}
                    {children}
                </DialogContent>
                <DialogActions>
                    <DialogActionButton onClick={handleClose}>Abbrechen</DialogActionButton>
                    <DialogActionButton disabled={onValidateAction ? !onValidateAction() : false}
                                        onClick={handleAction}>{actionLabel}</DialogActionButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default (DialogButton);
