import React, {useContext, useEffect, useState} from "react";
import IMember, {Gender, genderToText} from "../../model/member/IMember";
import {BackgroundTextField} from "../../components/StyledComponents";
import {DatePicker} from "@mui/lab";
import {DateValidationError} from "@mui/lab/internal/pickers/date-utils";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Text from "../../components/Text";
import {ParseableDate} from "@mui/lab/internal/pickers/constants/prop-types";
import ContentButton from "../../components/ContentButton";
import ContentLoadingButton from "../../components/ContentLoadingButton";
import {MemberServiceApi} from "../../api/MemberServiceApi";
import {ApiError} from "../../model/Errors";
import {ProfileSectionProps} from "./Profile";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import BackgroundDropdown from "../../components/BackgroundDropdown";
import MenuItem from "@mui/material/MenuItem";

export default function ProfilePersonalData(props: ProfileSectionProps) {
    const {onMemberChange, member, classes} = props;
    const {getToken} = useContext(AuthServiceContext);

    const [edit, setEdit] = useState<boolean>();
    const [validated, setValidated] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);

    const [memberPersonalDataFirstName, setMemberPersonalDataFirstName] = useState<string>("");
    const [memberPersonalDataLastName, setMemberPersonalDataLastName] = useState<string>("");
    const [memberPersonalDataGender, setMemberPersonalDataGender] = useState<Gender>(Gender.NONE);
    const [memberPersonalDataDateOfBirth, setMemberPersonalDataDateOfBirth] = useState<Date | null>(null);

    // personal data section
    const [firstName, setFirstName] = useState<string>(memberPersonalDataFirstName);
    const [lastName, setLastName] = useState<string>(memberPersonalDataLastName);
    const [gender, setGender] = useState<Gender>(memberPersonalDataGender);
    const [dateOfBirth, handleDateChange] = useState<Date | null>(memberPersonalDataDateOfBirth);

    useEffect(() => {
        if (!!member) {
            setMemberPersonalDataFirstName(member.firstName);
            setFirstName(member.firstName);
            setMemberPersonalDataLastName(member.lastName);
            setLastName(member.lastName);
            setMemberPersonalDataGender(member.gender);
            setGender(member.gender);
            const dateOfBirthTimestamp = Date.parse( member.dateOfBirth);
            const dateOfBirth = new Date(dateOfBirthTimestamp);
            setMemberPersonalDataDateOfBirth(dateOfBirth);
            handleDateChange(dateOfBirth);
        }
    }, [member]);

    const handleSave = () => {
        if (handleValidation()) {
            setValidated(false);
            if (handleUpdated() && !!member) {
                setPending(true);
                MemberServiceApi.updateMember(createMemberToUpdate(member), getToken()).then(response => {
                    onMemberChange(response);
                    setEdit(false);
                }).catch((error: ApiError) => {
                    if (error.status !== 404) {
                        console.error("unexpected error: " + error.message);
                    }
                }).finally(() => {
                    setPending(false);
                });
            } else {
                setEdit(false);
            }
        }
    }

    const createMemberToUpdate = (member: IMember): IMember => {
        return {
            identifier: member.identifier,
            username: member.username,
            membership: member.membership,
            mailAddress: member.mailAddress,
            firstName: firstName,
            lastName: lastName,
            gender: gender,
            dateOfBirth: handleDateToIsoString(dateOfBirth),
            contact: member.contact,
            bankAccount: member.bankAccount,
            principleIdentifier: member.principleIdentifier
        }
    }

    const handleValidation = () => {
        console.info(dateOfBirth?.toLocaleDateString('en-EN', {month: "2-digit", day: "2-digit", year: "numeric"}));
        setValidated(true);
        const valid = !!firstName && !!lastName && gender !== Gender.NONE && !!dateOfBirth
        if (valid) {
            console.info("personal data valid. Required fields are filled.");
        } else {
            console.info("personal data not valid. Required fields are not filled.");
        }
        console.info(firstName, lastName, dateOfBirth);
        return valid;
    }

    const handleUpdated = () => {
        const updated = memberPersonalDataFirstName !== firstName
            || memberPersonalDataLastName !== lastName
            || memberPersonalDataGender !== gender
            || memberPersonalDataDateOfBirth !== dateOfBirth;
        if (updated) {
            console.info("personal data updated.");
        } else {
            console.info("personal data not updated.");
        }
        return updated;
    }

    const handleMaxDate = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - (12 * 6));
        return date;
    }

    const handleDateToLocaleString = (date: Date | null) => {
        return !!date ?
            date.toLocaleDateString('de-DE', {day: "2-digit", month: "2-digit", year: "numeric"}) : "";
    }

    const handleDateToIsoString = (date: Date | null) => {
        return !!date ?
            date.toLocaleDateString('en-EN', {month: "2-digit", day: "2-digit", year: "numeric"}) : "";
    }

    return (
        <div className={classes.profileSection}>
            <div className={classes.profileSectionHeader}>Persönliche Daten</div>
            {!edit && (<div className={classes.profileSectionContent}>
                <Text label="Vorname" value={memberPersonalDataFirstName}/>
                <Text label="Nachname" value={memberPersonalDataLastName}/>
                <Text label="Geschlecht" value={genderToText(memberPersonalDataGender)}/>
                <Text label="Geburtsdatum" value={handleDateToLocaleString(memberPersonalDataDateOfBirth)}/>
            </div>)}
            {edit && (<div className={classes.profileSectionContent}>
                <BackgroundTextField required
                                     error={validated && !firstName}
                                     helperText={validated && !firstName ? "Der Vorname fehlt." : null}
                                     variant="outlined"
                                     label="Vorname"
                                     defaultValue={memberPersonalDataFirstName}
                                     placeholder="Deinen Vorname"
                                     onChange={(event: any) => {
                                         setFirstName(event.target.value)
                                     }}/>
                <BackgroundTextField required
                                     error={validated && !lastName}
                                     helperText={validated && !lastName ? "Der Nachname fehlt." : null}
                                     variant="outlined"
                                     label="Nachname"
                                     defaultValue={memberPersonalDataLastName}
                                     placeholder="Deinen Nachname"
                                     onChange={(event: any) => {
                                         setLastName(event.target.value)
                                     }}/>
                <BackgroundDropdown id="profile-gender-label"
                                    label="Geschlecht"
                                    style={{marginBottom: "17px"}}
                                    useValue={true}
                                    value={gender}
                                    defaultValue={gender}
                                    nonValue={Gender.NONE}
                                    errorMessage="Geschlecht nicht ausgewählt."
                                    validated={validated}
                                    required={true}
                                    onChange={(newValue: any) => {
                                        setGender(newValue);
                                    }}>
                    <MenuItem value={Gender.MALE}>
                        {genderToText(Gender.MALE)}</MenuItem>
                    <MenuItem value={Gender.FEMALE}>
                        {genderToText(Gender.FEMALE)}</MenuItem>
                    <MenuItem value={Gender.DEVICE}>
                        {genderToText(Gender.DEVICE)}</MenuItem>
                </BackgroundDropdown>
                <DatePicker
                    label="Geburtsdatum"
                    openTo="year"
                    views={["year", "day"]}
                    maxDate={handleMaxDate()}
                    value={dateOfBirth}
                    onChange={handleDateChange}
                    onError={(reason: DateValidationError, value: ParseableDate<Date>) => {
                        console.info(reason, value);
                    }}
                    renderInput={(params) =>
                        <BackgroundTextField {...params}
                                             required
                                             label="Geburtsdatum"
                                             placeholder="Geburtsdatum"
                                             error={validated && !dateOfBirth}
                                             helperText={validated && !dateOfBirth ? "Dein Geburtsdatum fehlt." : null}
                        />}
                    inputFormat="dd.MM.yyyy"
                    mask="__.__.____"
                />
                {edit && (<div className={classes.profileSectionButtonContainer}>
                    <ContentButton variant="secondary"
                                   className={classes.profileSectionAbortButton}
                                   onClick={() => setEdit(false)}>Abbrechen</ContentButton>
                    <ContentLoadingButton className={classes.profileSectionSaveButton}
                                          pending={pending}
                                          onClick={handleSave}>Speichern</ContentLoadingButton>
                </div>)}
            </div>)}
            <div className={classes.profileSectionActions}>
                {!edit && (<Button className={classes.profileSectionEditAction} onClick={() => setEdit(!edit)}>
                    <Icon style={{fontSize: "20px"}}>edit</Icon>
                </Button>)}
            </div>
        </div>
    );

}
