import React, {PropsWithChildren, useState} from "react";
import {ClassNameMap} from '@mui/styles/withStyles';
import IUser, {groupToText, roleToText, UserGroup, UserRole} from "../../model/IUser";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

interface ProfileRolesProps extends PropsWithChildren<any> {
    classes: ClassNameMap;
    user: IUser;
}

export default function ProfileRoles(props: ProfileRolesProps) {
    const {user, classes} = props;

    const [show, setShow] = useState<boolean>();

    return (
        <div className={classes.profileSection}>
            <div className={classes.profileSectionHeader}>Rollen</div>
            <div className={classes.profileSectionContent}>
                {user.groups.map((group: UserGroup, index: number) => (
                    <Typography key={"group_" + index}>{index +1}. {groupToText(group)}</Typography>
                ))}
                {show && (<div style={{marginTop: "10px"}}>
                    <Typography style={{textDecoration: "underline"}}>Enthaltene Rechte</Typography>
                    {user.roles.map((role: UserRole, index: number) => (
                        <Typography key={"role_" + index}>{index + 1}. {roleToText(role)}</Typography>
                    ))}
                    {user.roles.length === 0 && (
                        <div>
                            Keine Rechte
                        </div>
                    )}
                </div>)}
            </div>
            <div className={classes.profileSectionActions}>
                <Button className={classes.profileSectionEditAction} onClick={() => setShow(!show)}>
                    <Icon style={{fontSize: "20px"}}>{!show ? "info" : "clear"}</Icon>
                </Button>
            </div>
        </div>
    );

}