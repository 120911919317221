import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Container, LinearProgress} from "@mui/material";

const useStyles = makeStyles(() => createStyles({
    contentContainer: {
        display: "flex",
        flexDirection: "column"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "40px"
    },
}));

interface ContentContainerProps extends PropsWithChildren<any> {
    process?: boolean;
}

export default function ContentContainer(props: ContentContainerProps) {

    const {process} = props;

    const classes = useStyles();

    return (
        <div className={classes.contentContainer}>
            {process && (<LinearProgress />)}
            <Container style={{ marginTop: `${process ? "0" : "4px"}`}} className={classes.container}>
                {props.children}
            </Container>
        </div>
    )
}
