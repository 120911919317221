import IUser, {UserGroup} from "../model/IUser";
import {handleResponse} from "./ServiceApi";
import IMember, {IMemberBankAccount, IMemberContact} from "../model/member/IMember";
import RegistrationData from "../model/member/RegistrationData";

const { REACT_APP_MEMBER_SERVICE_URL } = window._env_;

const MEMBER_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/members`;
const FAMILY_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/families`;
const GROUP_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/groups`;
const CONTACT_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/contacts`;
const REGISTER_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/registrations`;
const USER_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/users`;

async function buildRequestOptions(method: string, authHeader?: string) {
    return {
        method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader ? (" Bearer " + authHeader) : ""
        }
    };
}

export const MemberServiceApi = {
    getMember: async (user: IUser, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${MEMBER_API}/${user.userId}`, requestOptions).then(handleResponse);
    },
    getFamilyOfMember: async (principleMemberIdentifier: string, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${FAMILY_API}/${principleMemberIdentifier}/subordinates`, requestOptions)
            .then(handleResponse);
    },
    updateMember: async (member: IMember, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${MEMBER_API}/${member.identifier}`, {
            ...requestOptions,
            body: JSON.stringify(member)
        }).then(handleResponse);
    },
    updateContact: async (member: IMember, contact: IMemberContact, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${MEMBER_API}/${member.identifier}/contacts/default`, {
            ...requestOptions,
            body: JSON.stringify(contact)
        }).then(handleResponse);
    },
    updateBankAccount: async (member: IMember, bankAccount: IMemberBankAccount, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${MEMBER_API}/${member.identifier}/bank-accounts/default`, {
            ...requestOptions,
            body: JSON.stringify(bankAccount)
        }).then(handleResponse);
    },
}

export const RegisterServiceApi = {
    sendRegister: async (registrationData: RegistrationData) => {
        const requestOptions = await buildRequestOptions("POST");
        return fetch(`${REGISTER_API}`, {
            ...requestOptions,
            body: JSON.stringify(registrationData)
        }).then(handleResponse);
    },
    checkUser: async (username: string, mailAddress: string) => {
        const requestOptions = await buildRequestOptions("GET");
        return fetch(`${USER_API}?username=${escape(username)}&mailAddress=${escape(mailAddress)}`,
            requestOptions).then(handleResponse);
    },
    sendVerify: async (verificationLink: string) => {
        const requestOptions = await buildRequestOptions("GET");
        return fetch(`${REGISTER_API}/${verificationLink}`, requestOptions).then(handleResponse);
    }
}

export const GroupServiceApi = {
    getMembersOfGroup: async (group: UserGroup, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${GROUP_API}/${group}/members`, requestOptions).then(handleResponse);
    }
}

export const ContactServiceApi = {
    getContacts: async (token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${CONTACT_API}/`, requestOptions).then(handleResponse);
    },
    getContact: async (memberIdentifier: string, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${CONTACT_API}/${memberIdentifier}`, requestOptions).then(handleResponse);
    }
}
