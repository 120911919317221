export enum Salutation {
    NONE = "NONE",
    MALE = "MALE",
    FEMALE = "FEMALE",
    DEVICE = "DEVICE"
}

export function salutationToText(salutation: Salutation) {
    switch (salutation) {
        case Salutation.MALE:
            return "Herr";
        case Salutation.FEMALE:
            return "Frau";
        case Salutation.DEVICE:
            return "Keine Anrede";
        case Salutation.NONE:
            return "Keine Auswahl getroffen";
        default:
            return "";
    }
}
