import React, {CSSProperties, PropsWithChildren} from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {createStyles, makeStyles} from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";

// root: {
//         "& .MuiFilledInput-root": {
//             backgroundColor: "transparent",
//             borderRadius: "4px",
//         },
//         "& .MuiFilledInput-root .MuiSelect-select:focus": {
//             backgroundColor: "transparent",
//             borderRadius: "4px",
//         },
//         "& .MuiFilledInput-root .MuiFilledInput-input": {
//             color: "#023553"
//         },
//         "& .MuiFilledInput-underline:before": {
//             content: "unset"
//         },
//         "& .MuiFilledInput-underline:after": {
//             content: "unset"
//         }
//     },
//

const useStyles = makeStyles(() => createStyles({
    container: {
        marginBottom: "20px"
    },
    labelControl: {
        width: "100%",
        "& .MuiFormControlLabel-label": {
            marginBottom: "0",
            width: "calc(100% - 42px)"
        },
        "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Rubik, sans-serif",
            lineHeight: "24px",
            color: "#000000",
            marginTop: "1px"
        },
        "& .MuiCheckbox-root.Mui-checked": {
            color: "#02A1A0"
        }
    },
    labelInput: {
        marginTop: "0",
        marginBottom: "auto"
    },
    labelText: {
        width: "100%"
    },
    labelErrorText: {
        color: "#d32f2f",
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: "1.66"
    }
}));

interface ContentCheckboxProps extends PropsWithChildren<any> {
    onChange(event: React.ChangeEvent<HTMLInputElement>): void,

    id?: string,
    editable?: boolean,
    checked: boolean,
    label: string | React.ReactNode,
    required?: boolean,
    multiline?: boolean,
    style?: CSSProperties,
    className?: string,
    error?: boolean,
    helperText?: React.ReactNode
}

export default function ContentCheckbox(props: ContentCheckboxProps) {
    const {onChange, id, checked, label, style, className, error, helperText} = props;
    const editable: boolean = props.editable !== undefined ? props.editable : true;
    const required: boolean = props.required !== undefined ? props.required : false;
    const labelTextStyle: CSSProperties = props.multiline ? { marginTop: "9px" } : {}

    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event);
    }

    return (
        <div id={id}
             style={style}
             className={`${!!className ? className + " " : ""}${classes.container}`}>
            <FormControlLabel
                className={classes.labelControl}
                label={<InputLabel id={id}
                                   style={labelTextStyle}
                                   error={error}
                                   required={required}>
                    <p style={{ whiteSpace: "break-spaces", marginBottom: "0" }}>{label}</p></InputLabel>}
                control={<Checkbox checked={checked}
                                   onChange={handleChange}
                                   disabled={!editable}
                                   className={classes.labelInput}
                                   color="primary"/>}/>
            {(error && helperText) && (<div className={classes.labelErrorText}>{helperText}</div>)}
        </div>
    );
}
