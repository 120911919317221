import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    footerHeadlineContainer: {
        margin: "auto",
        marginLeft: "80px",
        backgroundColor: "transparent",
        [theme.breakpoints.down(900)]: {
            marginLeft: "10px",
            transition: "margin 0.2s ease"
        },
        [theme.breakpoints.up(900)]: {
            transition: "margin 0.2s ease"
        },
        [theme.breakpoints.down(750)]: {
            margin: "20px auto 10px 20px"
        },
    },
    footerHeadline: {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "28px"
    }
}));

export default function FooterHeadline() {

    const classes = useStyles();

    return (
        <>
            <div className={classes.footerHeadlineContainer}>
                <div className={classes.footerHeadline}>USCO</div>
            </div>
        </>
    );
}