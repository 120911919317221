import React, {useContext} from "react";
import * as TYPES from "../../constants/siteTypes"
import {createStyles, makeStyles} from "@mui/styles";
import EditableSite from "../../components/site/EditableSite";
import PageHeader from "../../components/PageHeader";
import * as ROUTES from "../../constants/routes";
import {useNavigate} from "react-router";
import TeaserCarousel, {TeaserData} from "../../components/teaser/TeaserCarousel";
import PageContent from "../../components/PageContent";
import Typography from "@mui/material/Typography";
import ContentButton from "../../components/ContentButton";
import ImageWithTextContainer from "../../components/ImageWithTextContainer";
import {BeforeSectionExtension, BehindSectionExtension} from "../../components/site/EditableSection";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {Theme} from "@mui/material";
import SiteImage from "../../components/site/SiteImage";

const useStyles = makeStyles((theme: Theme) => createStyles({
    membershipContainer: {
        paddingTop: "20px"
    },
    membershipTeaserContainer: {
        margin: "auto",
        maxWidth: "1280px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "18px 43px 40px 43px",
        [theme.breakpoints.down(1280)]: {
            width: "100%",
            padding: "18px 0 5px 0"
        }
    },
    membershipTeaserHeadline: {
        margin: "20px auto 0",
        color: "#023553",
        textAlign: "center"
    },
    membershipRegisterButton: {
        margin: "20px 0 20px auto",
        width: "200px"
    }
}));

export default function Membership() {

    const navigate = useNavigate();
    const classes = useStyles();

    const {user} = useContext(AuthServiceContext);

    const teaserData: TeaserData[] = [
        {
            image: "teaser/home_teaser_children.jpeg",
            title: "Segeln für Kids",
            content: "Ob Landratte oder erfahrener Jungmatrose: Kids und Jugendliche mit Lust aufs Segeln können bei uns Erfahrungen sammeln und in See stechen.",
            actionTitle: "Zum Kindersegeln",
            action: () => {
                navigate(ROUTES.CHILD_SAILING_COURSES);
            }
        },
        {
            image: "teaser/home_teaser_adult.jpeg",
            title: "Unter Segeln und Motor",
            content: "Unsere günstigen Kurse bereiten fundiert auf die Prüfungen zum Sportbootführerschein Binnen oder See vor.",
            actionTitle: "Zu den Kursen",
            action: () => {
                navigate(ROUTES.ADULT_SAILING_COURSES);
            }
        },
        {
            image: "teaser/home_teaser_radio.jpeg",
            title: "Ausbildung fürs SRC und UBI",
            content: "In entspanntem Rahmen und kleinen Gruppen gibt es für Mitglieder das erforderliche Know-how zum Erwerb der Sprechfunkzeugnisse.",
            actionTitle: "Gleich anmelden",
            action: () => {
                navigate(ROUTES.RADIO_COURSES);
            }
        }
    ];

    return (
        <>
            <PageHeader headerImage={"header/membership.jpg"}>
                Mitgliedschaft im USCO
            </PageHeader>
            <PageContent className={classes.membershipContainer}>
                <EditableSite siteType={TYPES.MEMBERSHIP}>
                    <BeforeSectionExtension position={1}>
                        <SiteImage image="site-icon/sailboat_sailing_blue.svg"/>
                    </BeforeSectionExtension>
                    <BehindSectionExtension position={2}>
                        <ImageWithTextContainer
                            style={{marginTop: "50px"}}
                            image="breaker/key-2092170.jpg"
                            imagePosition={{positionY: "60%", mirrorX: true}}
                            headline="Komm' an Bord!"
                            content={!user.authenticated ?
                                "So einfach geht's zur Mitgliedschaft: Registriere dich mit wenigen Klicks und sende uns so deinen Mitgliedsantrag. Dieser beinhaltet auch den Online-Zugang zu unserem Mitgliederportal." :
                                "Von Ansegeln und Bootbuchen bis Vereinsarbeit und Zugangsdaten, in deinem persönlichen Online-Bereich findest du als USCO-Mitglied alle Services und Informationen."}
                            contentStyle={{marginTop: "-50px"}}>
                            {!user.authenticated && (<ContentButton className={classes.membershipRegisterButton}
                                                                    onClick={() => navigate(ROUTES.REGISTER)}>
                                Jetzt registrieren</ContentButton>)}
                            {user.authenticated && (<ContentButton className={classes.membershipRegisterButton}
                                                                   onClick={() => navigate(ROUTES.PROFILE)}>
                                Los Geht's</ContentButton>)}
                        </ImageWithTextContainer>
                    </BehindSectionExtension>
                </EditableSite>
            </PageContent>
            <div className={classes.membershipTeaserContainer}>
                <Typography className={classes.membershipTeaserHeadline}
                            variant={"h1"}
                            component={"h1"}>Unsere Kurse und Freizeitangebote</Typography>
                <TeaserCarousel data={teaserData}/>
            </div>
        </>
    );
}
