import {handleResponse} from "./ServiceApi";

export const ZWISCHENAHN = "ZWISCHENAHN";
export const BORNHORST = "BORNHORST";

const { REACT_APP_CONTENT_SERVICE_URL } = window._env_;

const WEATHER_API = `${REACT_APP_CONTENT_SERVICE_URL}/contentmanagement/api/weathers`;

async function buildRequestOptions(method: string) {
    return {
        method,
        headers: {}
    };
}

const WeatherServiceApi = {
    getWeather: async (location: string) => {
        //const serviceUrl = await buildServiceUrl(location);
        const requestOptions = await buildRequestOptions("GET");
        return fetch(`${WEATHER_API}/${location}`, requestOptions).then(handleResponse);
    }
}

export default WeatherServiceApi;
