import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    imageCrossFade: {
        [theme.breakpoints.down(950)]: {
            height: "400px"
        },
        "& > figure": {
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            color: "transparent",
            animationName: "$imageAnimation",
            animationDuration: "20s",
            animationFillMode: "linear",
            animationIterationCount: "infinite",
            backfaceVisibility: "hidden",
            opacity: "0",
            position: "absolute",
            zIndex: 0,
            [theme.breakpoints.down(950)]: {
                height: "400px"
            },
        },
        "& > figure:nth-child(1)": {
            backgroundImage: "url(../static/images/header/home/home_cross_fade_image1.png)"
        },
        "& > figure:nth-child(2)": {
            animationDelay: "6s",
            backgroundImage: "url('../static/images/header/home/home_cross_fade_image2.png')"
        },
        "& > figure:nth-child(3)": {
            animationDelay: "12s",
            backgroundImage: "url(../static/images/header/home/home_cross_fade_image3.jpeg)"
        }
    },
    "@keyframes imageAnimation": {
        "0%": {
            animationTimingFunction: "ease-in",
            opacity: "0"
        },
        "12.5%": {
            animationTimingFunction: "ease-out",
            opacity: "1"
        },
        "25%": {
            opacity: "1"
        },
        "37.5%": {
            opacity: "0"
        },
        "100%": {
            opacity: "0"
        }
    },
    figure: {
        margin: "0"
    }
}));

export default function ImageCrossFade() {
    const classes = useStyles();

    return (
        <div className={classes.imageCrossFade}>
            <figure className={classes.figure} />
            <figure className={classes.figure} />
            <figure className={classes.figure} />
        </div>
    );
}
