import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import {ApiError} from "../../../model/Errors";
import {createStyles, makeStyles} from "@mui/styles";
import {HandbookServiceApi} from "../../../api/BoatServiceApi";
import EditableHandbook from "../../../components/handbook/EditableHandbook";
import IHandbook from "../../../model/boat/IHandbook";

const useStyles = makeStyles(() => createStyles({
    noHandbook: {
        textAlign: "center",
        fontSize: "16px",
        lineHeight: "19px",
        margin: "20px auto"
    },
    button: {
        margin: "0 auto 20px auto",
        minWidth: "unset",
        padding: "5px 10px"
    },
}));

interface BoatHandbookProps extends PropsWithChildren<any> {
    boatName: string;

    onAllowedToEdit(): boolean;
}

export default function BoatHandbook(props: BoatHandbookProps) {
    const {boatName, onAllowedToEdit} = props;
    const {getToken} = useContext(AuthServiceContext);

    const [handbook, setHandbook] = useState<IHandbook | undefined>(undefined);
    const [defaultEditMode, setDefaultEditMode] = useState<boolean | undefined>(undefined);

    const classes = useStyles();

    useEffect(() => {
        if (boatName) {
            const handbookName = boatName.toLocaleLowerCase().replaceAll(" ", "_") + "_handbook"
            HandbookServiceApi.getHandbook(handbookName, getToken())
                .then(response => {
                    setHandbook(response);
                })
                .catch((error: ApiError) => {
                    if (error.status === 404) {
                        setHandbook(undefined);
                        setDefaultEditMode(undefined);
                    } else {
                        console.warn("unexpected error: " + error.message);
                    }
                });
        }
    }, [boatName, getToken]);

    const handleCreateHandbook = () => {
        const handbookName = boatName.toLocaleLowerCase().replaceAll(" ", "_") + "_handbook"
        const introChapterName = handbookName + "_intro";
        HandbookServiceApi.createHandbook({
            chapters: [{
                type: introChapterName,
                position: 1,
                content: '<h1 style="text-align: center;">Handbuch ' + boatName + "</h1>"
            }],
            type: handbookName
        }, getToken()).then(response => {
            setHandbook(response);
            setDefaultEditMode(true);
        }).catch((error) => {
            console.error("unexpected error: " + error.message);
        });
    }

    return (
        <>
            <EditableHandbook handbook={handbook}
                              enableEditMode={defaultEditMode}
                              editableHandbookStyle={{maxWidth: "unset", width: "100%"}}
                              onAllowedToEdit={onAllowedToEdit}/>
            {(!handbook && onAllowedToEdit()) && (
                <Button className={classes.button}
                        variant={"outlined"}
                        onClick={handleCreateHandbook}>
                    Handbuch anlegen</Button>
            )}
        </>
    );
};
