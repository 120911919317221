import React, {useContext} from "react";
import * as TYPES from "../../constants/siteTypes"
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import EditableSite from "../../components/site/EditableSite";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import Typography from "@mui/material/Typography";
import TeaserCarousel, {TeaserData} from "../../components/teaser/TeaserCarousel";
import * as ROUTES from "../../constants/routes";
import {useNavigate} from "react-router";
import ImageWithTextContainer from "../../components/ImageWithTextContainer";
import {BeforeSectionExtension, BehindSectionExtension} from "../../components/site/EditableSection";
import SiteImage from "../../components/site/SiteImage";
import Icon from "@mui/material/Icon";
import ContentButton from "../../components/ContentButton";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    contactPersonsContainer: {
        paddingTop: "20px"
    },
    contactPersonsTeaserContainer: {
        margin: "auto",
        maxWidth: "1280px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "18px 43px 40px 43px",
        [theme.breakpoints.down(1280)]: {
            width: "100%",
            padding: "18px 0 5px 0"
        }
    },
    contactPersonsTeaserHeadline: {
        margin: "20px auto 0",
        color: "#023553",
        textAlign: "center"
    },
    contactPersonsBreaker: {
        width: "100%",
        minHeight: "400px",
        background: "linear-gradient(0deg, rgba(37, 70, 89, 0.7)," +
            " rgba(37, 70, 89, 0.7)), url(/static/images/breaker/pexels-yaroslav-shuraev-8918091.jpg)",
        backgroundSize: "cover",
        backgroundPositionY: "40%",
        display: "flex"
    },
    contactPersonsBreakerContainer: {
        margin: "auto",
        maxWidth: "1000px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#ffffff",
        padding: "18px 43px 5px 43px",
    },
    row: {
        marginBottom: "20px"
    }
}));

export default function ContactPersons() {

    const navigate = useNavigate();
    const classes = useStyles();

    const {user} = useContext(AuthServiceContext);

    const teaserData: TeaserData[] = [
        {
            image: "teaser/pexels-cottonbro-5851827.jpg",
            title: "Anheuern im Segelclub - so geht's",
            content: "Erfahrene Segler und Interessierte können als Clubmitglied aktiv Segeln und Boote nutzen oder ihr Handwerk erlernen – in bester Gesellschaft.",
            actionTitle: "Zur Mitgliedschaft",
            action: () => {
                navigate(ROUTES.MEMBERSHIP);
            }
        },
        {
            image: "teaser/home_teaser_adult.jpeg",
            title: "Die Lizenz, um in See zu stechen",
            content: "Unsere günstigen Kurse bereiten fundiert auf die Prüfungen zum Sportbootführerschein Binnen oder See vor.",
            actionTitle: "Zu den Kursen",
            action: () => {
                navigate(ROUTES.ADULT_SAILING_COURSES);
            }
        },
        {
            image: "teaser/buoy-5211255.jpg",
            title: "Ablegen vor der Haustür",
            content: "In Oldenburg und Bad Zwischenahn segeln Mitglieder mit der Bootsflotte des Clubs unabhängig von der Tiede.",
            actionTitle: "Zu Flotte und Reviere",
            action: () => {
                navigate(ROUTES.BOATS_AND_TERRITORIES);
            }
        }
    ];

    const handleClick = () => {
        if (!user.authenticated) {
            navigate(ROUTES.REGISTER);
        } else {
            navigate(ROUTES.SAILING_COURSE_REGISTER);
        }
    }

    return (
        <>
            <PageHeader headerImage="header/contact-persons.jpg"
                        headerImagePosition={{positionY: "10%"}}>
                Ansprechpartner
            </PageHeader>
            <PageContent className={classes.contactPersonsContainer}>
                <EditableSite siteType={TYPES.CONTACT_PERSONS}>
                    <BeforeSectionExtension position={1}>
                        <SiteImage image="site-icon/groups_blue.svg"/>
                    </BeforeSectionExtension>
                    <BehindSectionExtension position={3}>
                        <ImageWithTextContainer
                            style={{marginBottom: "50px"}}
                            image="breaker/pexels-yaroslav-shuraev-8917660.jpg"
                            headline="Die Crew zählt!"
                            content="Und deshalb unterstützen wir unsere Betreuer und Trainer bei ihrer
                            Ausbildung und Qualifizierung. Zum Beispiel zum Erwerb der Trainer-C-Lizenz."
                            contentStyle={{marginBottom: "-50px"}}
                        />
                    </BehindSectionExtension>
                    <BehindSectionExtension position={4}>
                        <div className={classes.contactPersonsBreaker}>
                            <div className={classes.contactPersonsBreakerContainer}>
                                <SiteImage style={{ marginBottom: "10px" }}
                                           image="site-icon/waves_white.svg" width="34px" height="29px"/>
                                <Typography className={classes.row}
                                            variant={"h1"}
                                            component={"h1"}>
                                    Jetzt anheuern als Clubmitglied</Typography>
                                <Typography className={classes.row}
                                            style={{textAlign: "center"}}
                                            variant={"body1"}
                                            component={"p"}>
                                    Kreuzen gegen den Wind, Knoten was das Zeug hält, Kommandos für glatte Abläufe
                                    als Kapitän auf einer Jolle:<br/>All das kannst du bei uns im Club Lernen oder Leben.
                                    Melde dich mit wenigen Klicks als Mitglied in unserem Verein an. Und bei Interesse
                                    auch gleich zu einem unserer günstigen Segelkurse.</Typography>
                                <ContentButton style={{ width: "172px", marginBottom: "10px"}}
                                               onClick={handleClick}>
                                    Los geht's<Icon style={{ fontSize: "14px", marginLeft: "5px"}}>arrow_forward</Icon>
                                </ContentButton>
                            </div>
                        </div>
                    </BehindSectionExtension>
                </EditableSite>
            </PageContent>
            <div className={classes.contactPersonsTeaserContainer}>
                <Typography className={classes.contactPersonsTeaserHeadline}
                            variant={"h1"}
                            component={"h1"}>Unsere Kurse und Freizeitangebote</Typography>
                <TeaserCarousel data={teaserData}/>
            </div>
        </>
    );
}
