import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {ImagePosition} from "./ImagePosition";
import {LinearProgress, Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    pageHeaderImageContainer: {
        width: "100%",
        height: "200px",
        position: "relative",
        [theme.breakpoints.down(950)]: {
            height: "288px"
        }
    },
    pageHeaderImage: {
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        color: "transparent",
        backfaceVisibility: "hidden",
        opacity: "1",
        zIndex: 0,
        [theme.breakpoints.down(950)]: {
            height: "200px"
        },
    },
    pageHeadlineContainer: {
        display: "flex",
        minHeight: "88px",
        backgroundColor: "#023553",
        top: "55px",
        left: "80px",
        position: "absolute",
        color: "#ffffff",
        [theme.breakpoints.down(950)]: {
            top: "unset",
            left: "unset",
            maxWidth: "unset",
            width: "100%",
            padding: "0",
        },
        [theme.breakpoints.down(500)]: {
            padding: "0"
        }
    },
    pageHeadline: {
        margin: "auto 32px",
        fontSize: "34px",
        lineHeight: "40px",
        [theme.breakpoints.down(950)]: {
            marginLeft: "15px",
            marginRight: "auto"
        },
        [theme.breakpoints.down(500)]: {
            fontSize: "24px",
            lineHeight: "30px",
        },
    },
    pageHeaderLinearProgress: {
        marginTop: "-4px"
    }
}));

interface PageHeaderProps extends PropsWithChildren<any> {
    process?: boolean;
    headerImage?: string;
    headerImagePosition?: ImagePosition;
}

export default function PageHeader(props: PageHeaderProps) {
    const {process, headerImage, headerImagePosition} = props;
    const classes = useStyles();

    const background = {
        backgroundImage: "url('/static/images/" + (headerImage ? headerImage : "header/default.png") + "')",
        backgroundPositionX: `${headerImagePosition ? headerImagePosition.positionX : "50%"}`,
        backgroundPositionY: `${headerImagePosition ? headerImagePosition.positionY : "50%"}`,
        transform: `${headerImagePosition ? "scale(" + (headerImagePosition.mirrorX ? "-1" : "1") + ","
            + (headerImagePosition.mirrorY ? "-1" : "1") + ")" : null}`,
    }

    return (
        <div className={classes.pageHeaderImageContainer}>
            <div className={classes.pageHeaderImage} style={background}/>
            <div className={classes.pageHeadlineContainer}>
                <div className={classes.pageHeadline}>
                    {props.children}
                </div>
            </div>
            {process && (<LinearProgress className={classes.pageHeaderLinearProgress}/>)}
        </div>
    );
}
