import React, {CSSProperties, PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    weatherWindContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "auto",
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "12px"
    }
}));

const mapToBft = (speed: number | undefined): number | undefined => {
    if (speed) {
        if (speed < 0.3) {
            return 0;
        } else if (speed >= 0.3 && speed <= 1.5) {
            return 1;
        } else if (speed >= 0.3 && speed <= 3.3) {
            return 2;
        } else if (speed >= 3.4 && speed <= 5.4) {
            return 3;
        } else if (speed >= 5.5 && speed <= 7.9) {
            return 4;
        } else if (speed >= 8.0 && speed <= 10.7) {
            return 5;
        } else if (speed >= 10.8 && speed <= 13.8) {
            return 6;
        } else if (speed >= 13.9 && speed <= 17.1) {
            return 7;
        } else if (speed >= 17.2 && speed <= 20.7) {
            return 8;
        } else if (speed >= 20.8 && speed <= 24.4) {
            return 9;
        } else if (speed >= 24.5 && speed <= 28.4) {
            return 10;
        } else if (speed >= 28.5 && speed <= 32.6) {
            return 11;
        } else if (speed > 32.6) {
            return 12;
        }

    }
    return undefined;
}

interface WeatherWindProps extends PropsWithChildren<any> {
    bft: boolean;
    windSpeed: number | undefined;
    windDeg: number | undefined;
    windGust?: number;
    style?: CSSProperties;
}

export default function WeatherWind(props: WeatherWindProps) {
    const {bft, windSpeed, windDeg, windGust, style} = props;
    const classes = useStyles();

    return (
        <div style={style} className={classes.weatherWindContainer}>
            <div>Wind: {bft ? mapToBft(windSpeed) + " Bft" : windSpeed + " m/s"}</div>
            {windGust && (<div>In Böen: {bft ? mapToBft(windGust) + " Bft" : windGust + " m/s"}</div>)}
            <div>Windrichtung: {windDeg}°</div>
        </div>
    );
}
