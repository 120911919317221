import {Avatar, Icon, Theme} from "@mui/material";
import React, {CSSProperties, PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import Contact from "./Contact";
import {CurrentUser} from "./CurrentUser";

const useStyles = makeStyles((theme: Theme) => createStyles({
    memberContactContainer: {
        display: "flex",
        flexDirection: "row",
        justifyItems: "center",
    },
    memberContactAvatar: {
        margin: "auto 10px auto 0",
        [theme.breakpoints.down(950)]: {
            marginTop: "0",
            marginLeft: "0"
        }
    },
    contact: {
        margin: "auto 0 auto 15px",
        [theme.breakpoints.down(950)]: {
            marginTop: "0"
        },
        "&.currentUser": {
            [theme.breakpoints.down(950)]: {
                marginTop: "auto"
            }
        }
    },
    contactUserName: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px"
    },
    contactDetailsContainer: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column"
        }
    },
    contactDetails: {
        fontWeight: 400,
        fontFamily: "Rubik",
        fontSize: "14px",
        lineHeight: "18px",
        "& .material-icons": {
            lineHeight: "18px",
            fontSize: "14px",
            fontWeight: 400
        },
        "&.last": {
            marginLeft: "10px",
            [theme.breakpoints.down(950)]: {
                marginLeft: "0"
            }
        }
    }
}));

interface MemberContactProps extends PropsWithChildren<any> {
    contactIdentifier: string,
    currentUser: CurrentUser,
    style?: CSSProperties,
    className?: string,
}

export default function MemberContact(props: MemberContactProps) {

    const {contactIdentifier, currentUser, style, className} = props;

    const classes = useStyles();

    const handleIsCurrentUser = (): boolean => {
        return currentUser.getIdentifier() === contactIdentifier;
    }

    return (
        <div className={`${classes.memberContactContainer}${className ? ' ' + className : ''}`} style={style}>
            <Avatar className={classes.memberContactAvatar}
                    sx={{bgcolor: `${handleIsCurrentUser() || currentUser.isFamilyMember(contactIdentifier) 
                            ? "#02A1A0" : "#023553"}`}}>
                <Icon>person</Icon>
            </Avatar>
            <Contact contactIdentifier={contactIdentifier}
                     currentUser={currentUser}/>
        </div>
    );
}
