import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import ContentButton from "../ContentButton";

const useStyles = makeStyles(() => createStyles({
    addSiteContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "1280px",
        boxSizing: "border-box",
        padding: "5px 28px",
    },
    addSiteButtonContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "125px",
        margin: "auto",
        boxSizing: "border-box",
    }
}));

interface AddSiteProps extends PropsWithChildren<any> {
    onAddSite(): void,
}

export default function AddSite(props: AddSiteProps) {
    const {onAddSite} = props;

    const classes = useStyles();

    const handleClick = () => {
        onAddSite();
    }

    return (
        <div className={`editableSiteAddSectionContainer ${classes.addSiteContainer}`}>
            <div className={classes.addSiteButtonContainer}>
                <ContentButton onClick={handleClick}>Neue Seite</ContentButton>
            </div>
        </div>
    );
}
