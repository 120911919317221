import React, {CSSProperties, PropsWithChildren} from "react";
import Button from "@mui/material/Button";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    contentButton: {
        color: "#ffffff",
        backgroundColor: "#02A1A0",
        fontFamily: "Roboto Mono",
        fontSize: "14px",
        lineHeight: "28px",
        padding: "6px 8px",
        width: "100%",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#02A1A0",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.8)"
        },
        "&.Mui-disabled": {
            borderColor: "rgba(0,0,0,0.26)",
            backgroundColor: "rgba(0,0,0,0.05)"
        }
    },
    contentButtonSecondary: {
        color: "#02A1A0",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#02A1A0",
        backgroundColor: "transparent",
        fontFamily: "Roboto Mono",
        fontSize: "14px",
        lineHeight: "28px",
        padding: "6px 8px",
        width: "100%",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.2)"
        },
        "&.Mui-disabled": {
            borderColor: "rgba(0,0,0,0.26)",
            backgroundColor: "rgba(0,0,0,0.05)"
        }
    },
    contentButtonBlurContainer: {
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        margin: "auto",
        display: "flex"
    }
}));

interface ContentButtonProps extends PropsWithChildren<any> {
    onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void,

    id?: string,
    variant?: "primary" | "secondary"
    style?: CSSProperties,
    className?: string,
    buttonStyle?: CSSProperties,
    buttonClassName?: string,
    disabled?: boolean
}

export default function ContentButton(props: ContentButtonProps) {
    const {onClick, id, variant, style, className, buttonStyle, buttonClassName, disabled, children} = props;

    const classes = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick(event);
    }

    return (
        <div style={style}
             className={`${classes.contentButtonBlurContainer}${className ? " " + className : ""}`}>
            <Button id={id}
                    style={buttonStyle}
                    disabled={disabled}
                    className={`${variant === "secondary" ?
                        classes.contentButtonSecondary :
                        classes.contentButton}${buttonClassName ? " " + buttonClassName : ""}`}
                    onClick={handleClick}>{children}</Button>
        </div>
    );
}
