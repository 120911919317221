import React from "react";
import {Theme} from "@mui/material";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import EditableSite from "../../components/site/EditableSite";
import * as TYPES from "../../constants/siteTypes";
import {BeforeSectionExtension} from "../../components/site/EditableSection";
import SiteImage from "../../components/site/SiteImage";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    combinationLocksContainer: {
        paddingTop: "20px"
    }
}));


export default function CombinationLocks() {

    const classes = useStyles();

    return (
        <>
            <PageHeader headerImage="header/combination-locks-alternative.jpg"
                        headerImagePosition={{ mirrorX: true }}>
                Schlösser und Zutritt zu den Revieren
            </PageHeader>
            <PageContent className={classes.combinationLocksContainer}>
                <EditableSite siteType={TYPES.COMBINATION_LOCKS}>
                    <BeforeSectionExtension position={1}>
                        <SiteImage image="site-icon/lock_blue.svg"/>
                    </BeforeSectionExtension>
                </EditableSite>
            </PageContent>
        </>
    );
}
