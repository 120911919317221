import React, {useState} from "react";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import {DrawerMenu, DrawerMenuItem} from "../../components/menu/DrawerMenu";
import {UscoDrawerMenu, UscoMenu} from "./menu/Usco";
import {TrainingDrawerMenu, TrainingMenu} from "./menu/Training";
import {SailingDrawerMenu, SailingMenu} from "./menu/Sailing";
import {SailingClubDrawerMenu, SailingClubMenu} from "./menu/SailingClub";
import {LoginDrawerMenu, LoginMenu} from "./menu/Login";
import {useNavigate} from "react-router";
import * as ROUTES from "../../constants/routes";
import './HeaderMenu.css';
import {Button, Divider, Drawer, Icon, Theme, useMediaQuery} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    headerMenuContainer: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        margin: "auto 0 auto auto",
        [theme.breakpoints.down(950)]: {
            display: "none"
        }
    },
    headerDrawerMenuContainer: {
        display: "none",
        alignItems: "center",
        height: "100%",
        margin: "auto 0 auto auto",
        backgroundColor: "#02A1A0",
        color: "#ffffff",
        [theme.breakpoints.down(950)]: {
            display: "flex",
        }
    },
    spacingRight: {
        marginRight: "10px"
    },
    drawerMenuLink: {
        height: "100%",
        backgroundColor: "#02B8B7",
        borderRadius: "0",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.8)"
        }
    },
    drawerHeader: {
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    drawerContentContainer: {
        overflow: "auto",
        height: "calc(100% - 50px)"
    },
    drawerMenuCloseButton: {
        borderRadius: "25px",
        padding: "5px",
        minWidth: "0",
        margin: "auto auto auto 16px",
        backgroundColor: "#02B8B7",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.8)"
        }
    }
}));

export default function HeaderMenu() {
    const [open, setOpen] = useState(false);

    const theme: Theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down(950));
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <>
            <nav className={!matchesMobile ? classes.headerMenuContainer : classes.headerDrawerMenuContainer}>
                {!matchesMobile && (<>
                    <Button variant={"text"}
                            color={"primary"}
                            className={`menuLink ${classes.spacingRight}`}
                            onClick={() => {
                                navigate(ROUTES.HOME);
                            }}>HOME</Button>
                    <UscoMenu/>
                    <TrainingMenu/>
                    <SailingMenu/>
                    <SailingClubMenu/>
                    <LoginMenu/>
                </>)}
                {matchesMobile && (<>
                    <Button className={classes.drawerMenuLink}
                            onClick={() => setOpen(true)}><Icon>menu</Icon></Button>
                    <Drawer variant="temporary"
                            style={{flexShrink: 0}}
                            anchor={"right"}
                            open={open}
                            onClose={() => setOpen(false)}>
                        <div className={classes.drawerHeader}>
                            <Button className={classes.drawerMenuCloseButton}
                                    onClick={() => setOpen(false)}>
                                <Icon>navigate_next</Icon></Button>
                        </div>
                        <Divider/>
                        <div className={classes.drawerContentContainer}
                             onClick={() => setOpen(false)}
                             onKeyDown={() => setOpen(false)}>
                            <DrawerMenu divider={true}>
                                <DrawerMenuItem target={ROUTES.HOME} title={"Home"} className="subMenuLink"/>
                            </DrawerMenu>
                            <UscoDrawerMenu/>
                            <TrainingDrawerMenu/>
                            <SailingDrawerMenu/>
                            <SailingClubDrawerMenu/>
                            <LoginDrawerMenu/>
                        </div>
                    </Drawer>
                </>)}
            </nav>
        </>
    );
}
