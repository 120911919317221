import React, {PropsWithChildren, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import FilterDropdown from "../../../components/FilterDropdown";
import FilterSearchInput from "../../../components/FilterSearchInput";
import {Theme} from "@mui/material";
import {groupToText, UserGroup} from "../../../model/IUser";

const useStyles = makeStyles((theme: Theme) => createStyles({
    membersFilterContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        margin: "auto auto 40px",
        padding: "20px 0",
        width: "calc(850px)",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            margin: "20px auto",
            padding: "20px",
            width: "calc(415px - 40px)",
        },
        [theme.breakpoints.down(415)]: {
            margin: "0 0 20px 0",
            width: "calc(100% - 40px)"
        }
    },
    membersFilterHeadline: {
        color: "#023553",
        fontWeight: 500,
        lineHeight: "56px",
        marginRight: "12px",
        [theme.breakpoints.down(950)]: {
            marginRight: "auto"
        }
    },
    membersFilterDropdownInput: {
        width: "250px",
        marginRight: "12px",
        [theme.breakpoints.down(950)]: {
            width: "100%",
            marginRight: "0",
            marginBottom: "10px"
        }
    },
    membersFilterSearchInput: {
        width: "calc(100% - 262px - 68px - 42px)",
        [theme.breakpoints.down(950)]: {
            width: "100%"
        }
    }
}));

export type MemberFilter = {
    memberGroup?: UserGroup;
    searchQuery?: string;
};

interface MembersFilterProps extends PropsWithChildren<any> {
    groups: UserGroup[];

    onUpdateFilter(filter: MemberFilter): void;
}

export default function MembersFilter(props: MembersFilterProps) {
    const {groups, onUpdateFilter} = props;

    const [memberGroup, setMemberGroup] = useState<UserGroup>();
    const [filter, setFilter] = useState<MemberFilter>({});
    // filter
    const [searchQuery, setSearchedQuery] = useState<string>();

    const classes = useStyles();

    useEffect(() => {
        setFilter({
            memberGroup: memberGroup,
            searchQuery: searchQuery
        })
    }, [
        memberGroup,
        searchQuery
    ]);

    useEffect(() => {
        onUpdateFilter(filter);
    }, [onUpdateFilter, filter]);

    return (
        <div className={classes.membersFilterContainer}>
            <div className={classes.membersFilterHeadline}>FILTER:</div>
            <FilterDropdown id={"search-type-filter"}
                            className={classes.membersFilterDropdownInput}
                            label={"Rolle im Verein"}
                            value={memberGroup}
                            defaultValue={""}
                            onChange={(event: any) => {
                                setMemberGroup(event.target.value === "" ?
                                    undefined : event.target.value);
                            }}>
                <MenuItem value={""}>Keinen Typ</MenuItem>
                {groups.map((group: UserGroup, index: number) => (
                    <MenuItem key={"group-option-" + index} value={group}>{groupToText(group)}</MenuItem>
                ))}
            </FilterDropdown>
            <FilterSearchInput id={"member-search-filter"}
                               className={classes.membersFilterSearchInput}
                               label={"Suchen in Name oder E-Mail-Adresse"}
                               defaultValue={""}
                               onChange={(event: any) => {
                                   setSearchedQuery(event.target.value === "" ?
                                       undefined : event.target.value);
                               }}/>
        </div>
    );

}
