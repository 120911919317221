import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import {createStyles, makeStyles} from "@mui/styles";
import ProfilePersonalData from "./ProfilePersonalData";
import ProfileBankAccount from "./ProfileBankAccount";
import ProfileContact from "./ProfileContact";
import ProfileAccount from "./ProfileAccount";
import ProfileRoles from "./ProfileRoles";
import {ApiError} from "../../model/Errors";
import {MemberServiceApi} from "../../api/MemberServiceApi";
import IMember from "../../model/member/IMember";
import {ClassNameMap} from "@mui/styles/withStyles";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    profileContainer: {
        margin: "20px auto auto auto",
        width: "100%",
        maxWidth: "900px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(900)]: {
            marginTop: "0"
        }
    },
    profileSection: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "darkgrey",
        display: "flex",
        flexDirection: "row",
        position: "relative",
        [theme.breakpoints.down(900)]: {
            flexDirection: "column",
            borderTopWidth: "0"
        }
    },
    profileSectionHeader: {
        width: "205px",
        padding: "10px",
        marginBottom: "auto",
        textTransform: "uppercase",
        [theme.breakpoints.down(900)]: {
            width: "calc(100% - 30px)",
            paddingLeft: "20px"
        }
    },
    profileSectionContent: {
        width: "calc(100% - 225px - 40px - 40px)",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(900)]: {
            width: "calc(100% - 40px)",
            paddingBottom: "0"
        }
    },
    profileSectionActions: {
        width: "40px",
        marginBottom: "auto",
        [theme.breakpoints.down(900)]: {
            position: "absolute",
            right: "15px"
        }
    },
    profileSectionEditAction: {
        width: "40px",
        padding: "10px",
        minWidth: "40px"
    },
    profileSectionButtonContainer: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(900)]: {
            marginBottom: "20px"
        }
    },
    profileSectionAbortButton: {
        marginLeft: "auto",
        marginRight: "0"
    },
    profileSectionSaveButton: {
        marginLeft: "10px",
        marginRight: "0"
    },
    profileSectionPicker: {
        marginBottom: "0"
    }
}));

export interface ProfileSectionProps extends PropsWithChildren<any> {
    onMemberChange(member: IMember): void;

    classes: ClassNameMap;
    member: IMember | undefined;
}

export default function Profile() {
    const [loading, setLoading] = useState<boolean>(false);
    const [member, setMember] = useState<IMember>();
    const {user, getToken} = useContext(AuthServiceContext);

    const classes = useStyles();

    useEffect(() => {
        if (user.authenticated && !member) {
            setLoading(true);
            MemberServiceApi.getMember(user, getToken())
                .then(response => {
                    setMember(response);
                })
                .catch((error: ApiError) => {
                    if (error.status !== 404) {
                        console.error("unexpected error: " + error.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [member, user, getToken]);

    const handleDisplayName = () => {
        let displayName: string = !!member ? member.username : "";
        if (!!member) {
            if (member.firstName && member.lastName) {
                displayName = member.firstName + " " + member.lastName;
            } else if (member.firstName) {
                displayName = member.firstName;
            } else if (member.lastName) {
                displayName = member.lastName;
            }
        }
        return displayName.toUpperCase();
    }

    const handleMemberChange = (member: IMember) => {
        setMember(member);
    }

    return (
        <>
            <PageHeader>
                Profil von {handleDisplayName()}
            </PageHeader>
            <PageContent process={loading}>
                <div className={classes.profileContainer}>
                    <ProfileAccount classes={classes} member={member} onMemberChange={handleMemberChange}/>
                    <ProfilePersonalData classes={classes} member={member} onMemberChange={handleMemberChange}/>
                    <ProfileContact classes={classes} member={member} onMemberChange={handleMemberChange}/>
                    <ProfileBankAccount classes={classes} member={member} onMemberChange={handleMemberChange}/>
                    <ProfileRoles classes={classes} user={user}/>
                </div>
            </PageContent>
        </>
    );
}
