import React, {useState} from "react";
import {DropdownMenu, DropdownMenuItem} from "../../../components/menu/DropdownMenu";
import {DrawerMenu, DrawerMenuItem} from "../../../components/menu/DrawerMenu";
import {MenuTarget} from "./MenuTarget";
import * as ROUTES from "../../../constants/routes";
import '../HeaderMenu.css';

export const entries: MenuTarget[] = [
    { target: ROUTES.MEMBERSHIP, title: "Mitgliedschaft"},
    { target: ROUTES.CONTACT_PERSONS, title: "Ansprechpartner"},
    { target: ROUTES.BOATS_AND_TERRITORIES, title: "Flotte und Reviere"},
    { target: ROUTES.MESSAGES, title: "Mitteilungen"},
];

export function UscoMenu() {
    const [open, setOpen] = useState(false);

    return (
        <DropdownMenu title="USCO" state={open} className={"menuLink"} onStateChange={setOpen}>
            {entries.map((entry, index) => (
                <DropdownMenuItem key={index}
                                  target={entry.target}
                                  title={entry.title}
                                  className="subMenuLink"
                                  onStateChange={setOpen}/>
            ))}
        </DropdownMenu>
    );
}

export function UscoDrawerMenu() {

    return (
        <DrawerMenu title="USCO" divider={true}>
            {entries.map((entry, index) => (
                <DrawerMenuItem key={index}
                                className="subMenuLink"
                                target={entry.target}
                                title={entry.title} />
            ))}
        </DrawerMenu>
    );
}