import React, {useContext} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {useNavigate} from "react-router";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import * as ROUTES from "../../constants/routes";
import ContentButton from "../../components/ContentButton";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import SiteImage from "../../components/site/SiteImage";

const useStyles = makeStyles(() => createStyles({
    homeLogin: {
        width: "100%",
        minHeight: "400px",
        background: "linear-gradient(0deg, rgba(37, 70, 89, 0.7), " +
            "rgba(37, 70, 89, 0.7)), url(/static/images/breaker/home_login_background.png)",
        backgroundSize: "cover",
        backgroundPositionY: "70%",
        display: "flex"
    },
    homeLoginContainer: {
        margin: "auto",
        maxWidth: "1000px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#ffffff",
        padding: "18px 43px 5px 43px",
    },
    homeLoginButton: {
        width: "172px",
        marginBottom: "10px"
    },
    homeLoginButtonIcon: {
        fontSize: "14px",
        marginLeft: "5px"
    },
    row: {
        marginBottom: "20px"
    }
}));

export default function HomeLogin() {
    const classes = useStyles();
    const navigate = useNavigate();

    const {user, login} = useContext(AuthServiceContext);

    const handleClick = () => {
        if (!user.authenticated) {
            login();
        } else {
            navigate(ROUTES.PROFILE);
        }
    }

    return (
        <div className={classes.homeLogin}>
            <div className={classes.homeLoginContainer}>
                <SiteImage style={{ marginBottom: "10px" }}
                           image="site-icon/waves_white.svg" width="34px" height="29px"/>
                <Typography className={classes.row}
                            variant={"h1"}
                            component={"h1"}>
                    {!user.authenticated ? "Mitgliederportal" : "Mitgliederbereich"}</Typography>
                <Typography className={classes.row}
                            style={{ textAlign: "center" }}
                            variant={"body1"}
                            component={"p"}>
                    Von Ansegeln und Bootbuchen bis Vereinsarbeit und Zugangsdaten: In deinem
                    persönlichen Online-Bereich findest du als USCO-Mitglied alle Services und
                    Informationen, um aktiv am Vereinsleben teilzunehmen. Im Prinzip das digitale
                    Vereinsheim.</Typography>
                <ContentButton className={classes.homeLoginButton}
                               onClick={handleClick}>
                    {!user.authenticated ? (<>
                        Jetzt anmelden<Icon className={classes.homeLoginButtonIcon}>login</Icon>
                    </>) : (<>
                        Los geht's<Icon className={classes.homeLoginButtonIcon}>arrow_forward</Icon>
                    </>)}
                </ContentButton>
                {!user.authenticated && (
                    <Typography className={classes.row}
                                style={{ fontWeight: 500 }}
                                variant={"body1"}
                                component={"p"}>
                        Noch nicht an Bord? Registriere dich&nbsp;
                        <Link href="" onClick={() => navigate(ROUTES.REGISTER)}
                              className="register-link">hier</Link>
                        &nbsp;und&nbsp;
                        <Link href="" onClick={() => navigate(ROUTES.REGISTER)}
                              className="register-link">werde Vereinsmitglied</Link>.</Typography>
                )}
            </div>
        </div>
    );
}
