import {useNavigate} from "react-router";
import React, {PropsWithChildren, ReactElement, useEffect, useRef} from "react";
import {Button, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import Grow from "@mui/material/Grow";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    spacingRight: {
        marginRight: "10px",
    }
}));

interface DropdownMenuProps extends PropsWithChildren<any> {
    title: string | ReactElement;
    state: boolean;
    className?: string

    onStateChange(state: boolean): void;
}

export function DropdownMenu(props: DropdownMenuProps) {
    const {title, state, className, onStateChange} = props;
    const classes = useStyles();

    const prevOpen = useRef<boolean>(state);
    const anchorRef = useRef<HTMLButtonElement>(null);

    // return focus to the button when we transitioned from !state -> state
    useEffect(() => {
        if (prevOpen.current && !state && anchorRef.current) {
            anchorRef.current.focus();
        }
        prevOpen.current = state;
    }, [state]);

    const handleToggle = () => {
        onStateChange(!prevOpen.current);
    };

    const handleClose = () => {
        onStateChange(false);
    }

    function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
        if (event.key === "Tab") {
            event.preventDefault();
            onStateChange(false);
        }
    }

    return (
        <>
            <Button className={`${classes.spacingRight}${className ? " " + className : ""}`}
                    component={"button"}
                    variant="text"
                    color={"primary"}
                    ref={anchorRef}
                    aria-controls={state ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}>{title}</Button>
            <Popper open={state}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    style={{zIndex: 10}}>
                {({TransitionProps, placement}) => (
                    <Grow {...TransitionProps}
                          style={{transformOrigin: placement === "bottom-start" ? "left top" : "left bottom"}}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={state} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {props.children}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );

}

interface DropdownMenuItemProps extends PropsWithChildren<any> {
    target: string | any;
    title: string;
    className?: string

    onStateChange(state: boolean): void;
}

export function DropdownMenuItem(props: DropdownMenuItemProps) {
    const {target, title, className, onStateChange} = props;
    const navigate = useNavigate();

    const handleClick = (target: string | any) => {
        onStateChange(false);
        if (typeof target === 'function') {
            target();
        } else if (typeof target === 'string') {
            navigate(target);
        }
    }

    return (
        <MenuItem className={className}
                  onClick={() => handleClick(target)}>{title}</MenuItem>
    );

}
