import React from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => createStyles({
    registeredContainer: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        margin: "auto",
        width: "calc(100% - 40px)",
        maxWidth: "700px"
    },
}));

export default function RegisterSummery() {
    const classes = useStyles();

    return (
        <div className={classes.registeredContainer}>
            <h1>Fast geschafft.</h1>
            <p>Wir haben deine Registrierung und Anmeldung für die Vereinsmitgliedschaft erhalten.
                Für dich gibt’s jetzt nur noch ein To-do: Bitte schau in dein E-Mail-Postfach.
                Wir haben dir soeben eine E-Mail an die von dir angegebene Adresse gesendet.
                Darin ist ein Bestätigungslink enthalten. Bitte klicke darauf und bestätige
                damit die Richtigkeit deiner E-Mail-Adresse.</p>
            <p>Alles weitere erledigen wir:</p>
            <ul>
                <li>Wir ziehen zeitnah den Mitgliedsbeitrag für das laufende Jahr von deiner angegebenen Bankverbindung
                    ein.
                </li>
                <li>Sobald wir den Beitrag erhalten haben, bist du offiziell an Bord und deine Vereinsmitgliedschaft im
                    USCO beginnt.
                </li>
                <li>Wenn es soweit ist, informieren wir dich automatisch per E-Mail: Dann wirst du für den
                    Mitgliederbereich auf unserer Website freigeschaltet und musst dir initial dein Passwort einrichten.
                    Und schon bist du drin.
                </li>
            </ul>
        </div>
    );
}
