import React, {PropsWithChildren} from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {BackgroundTextField} from "../../../components/StyledComponents";

const useStyles = makeStyles(() => createStyles({
    registerSection: {
        marginTop: "40px",
        marginBottom: "20px",
        color: "#023553"
    },
}));

export interface RegisterBankAccountProps extends PropsWithChildren<any> {
    validated: boolean;
    accountHolder: string;
    iban: string;
    ibanValid: boolean;
    bicValid: boolean;
    bankName: string;

    onChangeAccountHolder(accountHolder: string): void;

    onChangeIban(iban: string): void;

    onChangeBic(bic: string): void;

    onChangeBankName(bankName: string): void;
}

export default function RegisterBankAccount(props: RegisterBankAccountProps) {
    const {
        validated, accountHolder, iban, ibanValid, bicValid, bankName,
        onChangeAccountHolder, onChangeIban, onChangeBic, onChangeBankName
    } = props;
    const classes = useStyles();

    return (
        <>
            <div className={classes.registerSection}>Bankverbindung</div>
            <BackgroundTextField required
                                 error={validated && !accountHolder}
                                 helperText={validated && !accountHolder ? "Der Kontoinhaber fehlt." : null}
                                 label="Kontoinhaber"
                                 variant="outlined"
                                 placeholder="Wer ist der Kontoinhaber"
                                 onChange={(event: any) => {
                                     onChangeAccountHolder(event.target.value);
                                 }}/>
            <BackgroundTextField required
                                 error={validated && (!iban || !ibanValid)}
                                 helperText={validated && !iban ? "Die IBAN fehlt." : (validated && !ibanValid  ? "Die IBAN ist nicht gültig." : null)}
                                 label="IBAN"
                                 variant="outlined"
                                 placeholder="Deine IBAN Nummer"
                                 onChange={(event: any) => {
                                     onChangeIban(event.target.value);
                                 }}/>
            <BackgroundTextField error={validated && !bicValid}
                                 helperText={validated && !bicValid ? "Die BIC ist nicht gültig" : null}
                                 label="BIC"
                                 variant="outlined"
                                 placeholder="Deine BIC Nummer"
                                 onChange={(event: any) => {
                                     onChangeBic(event.target.value);
                                 }}/>
            <BackgroundTextField required
                                 error={validated && !bankName}
                                 helperText={validated && !bankName ? "Das Bankinstitut fehlt." : null}
                                 label="Bank"
                                 variant="outlined"
                                 placeholder="Dein Bankinstitut"
                                 onChange={(event: any) => {
                                     onChangeBankName(event.target.value);
                                 }}/>
        </>
    );
}
