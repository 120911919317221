import {createStyles, makeStyles} from "@mui/styles";
import React, {PropsWithChildren} from "react";
import ContentButton from "../ContentButton";
import Icon from "@mui/material/Icon";
import {ImagePosition} from "../ImagePosition";

const useStyles = makeStyles(() => createStyles({
    teaserContainer: {
        width: "330px",
        minWidth: "330px",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 20px",
    },
    teaserImageContainer: {
        width: "100%",
        height: "260px",
        display: "flex",
        position: "relative"
    },
    teaserImage: {
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        position: "relative"
    },
    teaserDate: {
        width: "44px",
        height: "14px",
        padding: "10px",
        position: "absolute",
        backgroundColor: "#254659",
        color: "#ffffff",
        bottom: "0",
        fontSize: "12px",
        lineHeight: "14px"
    },
    teaserContentContainer: {
        height: "260px",
        display: "flex",
        flexDirection: "column"
    },
    teaserContent: {
        height: "calc(100% - 59px - 80px)",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "center",
        color: "#363E4C",
        margin: "0 20px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": 6,
        "-webkit-box-orient": "vertical"
    },
    teaserTitle: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "19px",
        textAlign: "center",
        color: "#363E4C",
        margin: "20px",
    }
}));

interface TeaserProps extends PropsWithChildren<any> {
    image?: string;
    imagePosition?: ImagePosition;
    date?: string;
    title: string;
    content: string;
    buttonTitle: string;

    onClick(): void;
}

export default function Teaser(props: TeaserProps) {
    const {image, imagePosition, date, title, content, buttonTitle, onClick} = props;

    const classes = useStyles();

    const imageStyle = {
        backgroundImage: `url(../static/images/${image})`,
        backgroundPositionX: `${imagePosition ? imagePosition.positionX : null}`,
        backgroundPositionY: `${imagePosition ? imagePosition.positionY : null}`,
        transform: `${imagePosition ? "scale(" + (imagePosition.mirrorX ? "-1" : "1") + ","
            + (imagePosition.mirrorY ? "-1" : "1") + ")" : null}`,
    }

    const handleOnClick = () => {
        onClick();
    }

    return (
        <div className={classes.teaserContainer}>
            {image && (<div className={classes.teaserImageContainer}>
                <div style={imageStyle} className={classes.teaserImage}/>
                {date && (<div className={classes.teaserDate}>{date}</div>)}
            </div>)}
            <div className={classes.teaserContentContainer}>
                <div className={classes.teaserTitle}>{title}</div>
                <div className={classes.teaserContent}>{content}</div>
                <ContentButton style={{width: "230px", margin: "20px auto"}}
                               onClick={handleOnClick}>{buttonTitle}<Icon
                    style={{fontSize: "14px", marginLeft: "5px"}}>arrow_forward</Icon></ContentButton>
            </div>
        </div>
    );
}