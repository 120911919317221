import React, {useContext, useEffect, useState} from "react";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import MessageList, {MessageData} from "../../components/message/MessageList";
import IMessage from "../../model/news/IMessage";
import {MessageServiceApi, MessageSort} from "../../api/ContentServiceApi";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    newsletterContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "1280px",
        margin: "20px auto",
    },
    newsletterViewContainer: {},
    noNewsletters: {
        textAlign: "center",
        color: "#023553",
        marginTop: "40px"
    }
}));

export default function Newsletter() {
    // loading
    const [loading, setLoading] = useState<boolean>(false);
    // model
    const [messages, setMessages] = useState<IMessage[]>([]);
    const {getToken} = useContext(AuthServiceContext);

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        MessageServiceApi.getActiveNewsletters(getToken())
            .then(response => {
            const messages = response.sort(MessageSort);
            setMessages(messages);
        })
            .catch((error) => console.error("unexpected error: " + error.message))
            .finally(() => setLoading(false));
    }, [getToken]);

    const handleNewslettersEmpty = (): boolean => {
        return messages.length === 0
    }

    const messageData: MessageData[] = messages.map(message => (
        {
            number: Number.parseInt(message.number),
            title: message.title,
            content: message.content,
            date: message.date,
            author: message.author,
            priority: message.priority,
            type: message.type
        }
    ));

    return (
        <>
            <PageHeader headerImage="header/newsletters.jpg"
                        process={loading}>
                Newsletter
            </PageHeader>
            <PageContent className={classes.newsletterContainer}>
                {handleNewslettersEmpty() && (<div className={classes.noNewsletters}>
                    Es sind keine Newsletter vorhanden.
                </div>)}
                {!handleNewslettersEmpty() && (
                    <MessageList extendedFilter={true} showType={true} messages={messageData}/>
                )}
            </PageContent>
        </>
    );
}
