import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import News from "./News";

const useStyles = makeStyles(() => createStyles({
    newsCarousel: {
        marginTop: "20px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        overflowX: "scroll",
        "-ms-overflow-style": "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    newsCarouselContainer: {
        margin: "auto",
        display: "flex",
        flexDirection: "row"
    }
}));

export type NewsData = {
    date?: string;
    title: string;
    content: string;
    hero: boolean;
    actionTitle: string;
    action(): void;
};

interface NewsCarouselProps extends PropsWithChildren<any> {
    data: NewsData[];
}

export default function NewsCarousel(props: NewsCarouselProps) {
    const {data} = props;
    const classes = useStyles();

    return (
        <div className={classes.newsCarousel}>
            <div className={classes.newsCarouselContainer}>
                {data.map((news, index) => (
                    <News key={index}
                          date={news.date}
                          title={news.title}
                          content={news.content}
                          hero={news.hero}
                          buttonTitle={news.actionTitle}
                          onClick={news.action}/>
                ))}
            </div>
        </div>
    );
}