import React, {PropsWithChildren, useState} from "react";
import 'suneditor/dist/css/suneditor.min.css';
import '../Editor.css';
import {TextField, Theme} from "@mui/material";
import DialogButton from "../DialogButton";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    addChapterContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        boxSizing: "border-box",
        padding: "5px 28px",
    },
    addChapterButtonContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        boxSizing: "border-box",
        border: "1px dashed lightgrey",
    }
}));

interface AddChapterProps extends PropsWithChildren<any> {
    onAddChapter(chapterName: string): void,
}

export default function AddChapter(props: AddChapterProps) {
    const {onAddChapter} = props;
    const [chapterName, setChapterName] = useState<string>('');

    const classes = useStyles();

    const handleChange = (event: any) => {
        setChapterName(event.target.value);
    };

    const handleClickSave = () => {
        onAddChapter(chapterName);
        setChapterName('');
    }

    return (
        <div className={`editableHandbookAddChapterContainer ${classes.addChapterContainer}`}>
            <div className={classes.addChapterButtonContainer}>
                <DialogButton label="Neues Kapitel"
                              title="Kapitel hinzufügen"
                              actionLabel="Hinzufügen"
                              onActionClick={handleClickSave}
                              details="Vergeben Sie einen Namen für das neue Kapitel."
                              style={{color: "rgba(0,0,0,0.6)", padding: "5px"}}>
                    <TextField required
                               autoFocus
                               id="chapter-name"
                               label="Kapitelname"
                               variant="standard"
                               value={chapterName}
                               onChange={handleChange}
                               fullWidth/>
                </DialogButton>
            </div>
        </div>
    );
}
