import React, {CSSProperties, PropsWithChildren, useContext, useEffect, useState} from "react";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {createStyles, makeStyles} from "@mui/styles";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import IHandbook from "../../model/boat/IHandbook";
import IChapter from "../../model/boat/IChapter";
import EditableChapter from "./EditableChapter";
import AddChapter from "./AddChapter";
import {HandbookServiceApi} from "../../api/BoatServiceApi";

const useStyles = makeStyles(() => createStyles({
    editableHandbookContainer: {
        position: "relative",
        margin: "5px auto 0 auto",
        maxWidth: "1280px"
    },
    editableHandbookButtonContainer: {
        height: "28px",
        width: "100%",
        display: "inline-flex",
        flexDirection: "column",
        position: "absolute",
        boxSizing: "border-box"
    },
    noHandbook: {
        textAlign: "center",
        fontSize: "16px",
        lineHeight: "19px",
        margin: "20px"
    },
    button: {
        marginLeft: "auto",
        minWidth: "unset",
        padding: "2px"
    },
}));

interface EditableHandbookProps extends PropsWithChildren<any> {
    handbook?: IHandbook;
    enableEditMode?: boolean;
    editableHandbookStyle?: CSSProperties,
    buttonClassName?: string

    onAllowedToEdit(): boolean;
}

export default function EditableHandbook(props: EditableHandbookProps) {
    const {handbook, enableEditMode, editableHandbookStyle, onAllowedToEdit} = props;
    const {user, getToken} = useContext(AuthServiceContext);
    const {authenticated} = user;

    const [editMode, setEditMode] = useState(false);
    const [chapters, setChapters] = useState<IChapter[]>([]);

    const classes = useStyles();

    useEffect(() => {
        if (handbook) {
            // sort to make sure ordered by position
            const chapters = handbook.chapters.sort((a: IChapter, b: IChapter) => {
                return a.position - b.position;
            });
            setChapters(chapters);
        }
    }, [handbook]);

    useEffect(() => {
        if (!authenticated) {
            setEditMode(false);
        }
    }, [authenticated]);

    useEffect(() => {
        if (enableEditMode) {
            setEditMode(enableEditMode);
        }
    }, [enableEditMode]);

    const updateChapter = (chapter: IChapter) => {
        if (handbook) {
            HandbookServiceApi.updateChapter(handbook.type, chapter, getToken()).catch(() => {
                console.info(`error during update chapter: ${chapter.type} of site: ${handbook.type}`);
            });
        }
    }

    const handleSaveChapter = (chapter: IChapter) => {
        updateChapter(chapter);
    }

    const handleRemoveChapter = (chapter: IChapter) => {
        if (handbook) {
            const filteredChapters = chapters.filter(function (cha) {
                return cha.type !== chapter.type
            });
            const indexDeletedItem = chapters.findIndex(sec => sec.type === chapter.type);
            const updatingChapters = JSON.parse(JSON.stringify(filteredChapters)).splice(indexDeletedItem);
            const newChapters: IChapter[] = [...filteredChapters.splice(0, indexDeletedItem)];
            updatingChapters.forEach((cha: IChapter, index: number) => {
                cha.position = indexDeletedItem + index + 1;
                newChapters.push(cha);
                updateChapter(cha);
            });
            HandbookServiceApi.deleteChapter(handbook.type, chapter, getToken()).then(() => {
                setChapters(newChapters);
            }).catch((error) => {
                console.info(`error during delete chapter: ${chapter.type} of handbook: ${handbook.type}`,
                    error);
            });
        }
    }

    const handleAddChapter = (chapterName: string) => {
        if (handbook) {
            const fullChapterName = handbook.type + "_" + chapterName;
            const position = chapters.length + 1;
            const chapter = {type: fullChapterName, position: position, content: "<p/>"}
            HandbookServiceApi.createChapter(handbook.type, chapter, getToken()).then(() => {
                const updatedChapters = [...chapters, chapter];
                setChapters(updatedChapters);
            }).catch((error) => {
                console.info(`error during create chapter: ${chapter.type} of handbook: ${handbook.type}`,
                    error);
            });
        }
    }

    const handleToggleEditMode = () => {
        setEditMode(!editMode);
    }

    return (
        <>
            {handbook ? (
                <div className={`editableHandbookContainer ${classes.editableHandbookContainer}`}
                     style={editableHandbookStyle}>
                    <div className={`editableHandbookButtonContainer ${classes.editableHandbookButtonContainer}`}>
                        {onAllowedToEdit() && (<Button className={classes.button}
                                                       onClick={handleToggleEditMode}>
                            <Icon>{!editMode ? "edit" : "clear"}</Icon></Button>)}
                    </div>
                    <div className="editableHandbookChapterContainer">
                        {chapters.map(chapter => (
                            <EditableChapter key={chapter.type}
                                             chapter={chapter}
                                             editMode={editMode}
                                             onSaveChapter={handleSaveChapter}
                                             onRemoveChapter={handleRemoveChapter}/>
                        ))}
                    </div>
                    {editMode && (<AddChapter onAddChapter={handleAddChapter}/>)}
                </div>
            ) : (
                <div className={classes.noHandbook}>Kein Handbuch vorhanden</div>
            )}
        </>
    );
}
