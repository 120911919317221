import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import Teaser from "./Teaser";
import {ImagePosition} from "../ImagePosition";

const useStyles = makeStyles(() => createStyles({
    teaserCarousel: {
        marginTop: "20px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        overflowX: "scroll",
        "-ms-overflow-style": "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    teaserCarouselContainer: {
        margin: "auto",
        display: "flex",
        flexDirection: "row"
    }
}));

export type TeaserData = {
    image?: string;
    imagePosition?: ImagePosition;
    date?: string;
    title: string;
    content: string;
    actionTitle: string;
    action(): void;
};

interface TeaserCarouselProps extends PropsWithChildren<any> {
    data: TeaserData[];
}

export default function TeaserCarousel(props: TeaserCarouselProps) {
    const {data} = props;
    const classes = useStyles();

    return (
        <div className={classes.teaserCarousel}>
            <div className={classes.teaserCarouselContainer}>
                {data.map((teaser, index) => (
                    <Teaser key={index}
                            image={teaser.image}
                            imagePosition={teaser.imagePosition}
                            date={teaser.date}
                            title={teaser.title}
                            content={teaser.content}
                            buttonTitle={teaser.actionTitle}
                            onClick={teaser.action}/>
                ))}
            </div>
        </div>
    );
}