import React from "react";

export default interface ILocation {
    latitude: string;
    longitude: string;
    name: string;
}

export function toLink(location: ILocation) {

    const createLink = () => {
        return <a style={{marginLeft: "5px"}}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"https://www.openstreetmap.org/#map=18/" + location.latitude + "/" + location.longitude}>
            (zeigen)
        </a>;
    }

    return (
        !location.latitude || !location.longitude ? "" : createLink()
    )

}
